<template>
  <v-row justify="center" align="center">
    <v-col cols="12" align="center">
      <v-progress-circular indeterminate size=""/>
    </v-col>
    <v-col cols="12" align="center">
      {{$t('common_msg11')}}
    </v-col>
  </v-row>
</template>

<script>
export default {

  data: () => ({
    EVENTCODE:"",
    OPTIONCODE:"",
    VISITORCODE:"",
    PG:"toss",
    IMP_UID:"",
    MERCHANT_UID:"",
    IMP_SUCCESS:"false",
    ERROR_MSG:"",
    lan_code:0, //한국어
  }),

  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    window.scrollTo(0,0);
    this.EVENTCODE      = this.$route.query.eventcode;
    this.OPTIONCODE     = this.$route.query.optioncode;
    this.VISITORCODE    = this.$route.query.visitorcode;
    this.PG             = this.$route.query.pg;


    this.IMP_UID        = this.$route.query.imp_uid;
    this.MERCHANT_UID   = this.$route.query.merchant_uid;


    if(this.$route.query.imp_success != null && this.$route.query.imp_success != undefined){
      this.IMP_SUCCESS = this.$route.query.imp_success;
    }else{
      this.IMP_SUCCESS = "false";
    }

    this.ERROR_MSG = this.$route.query.error_msg;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
        this.$i18n.locale = 'ko';
    }else{
        this.$i18n.locale = 'en';
    }

    this.resultpayment();

  },

  beforeDestroy() {
    
  },

  

  methods: {
    
    resultpayment(){

      if(this.IMP_SUCCESS == "true"){
        this.sendSuccess();

      }else{
        //let infomsg = this.lan_code == 0 ? '결제에 실패하였습니다.' : 'Payment failed.';
        this.$alert(this.ERROR_MSG).then(()=>{
          this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
        });        
      }

    },

    //사용안함
    getpayment(){

        //1.중복결제 체크
        this.$http.post(this.$host+'/ime/RegcheckPaymentVisitor',{
            EVENT_IDX:this.EVENTCODE
          , OPTION_IDX:this.OPTIONCODE
          , VISITOR_IDX:this.VISITORCODE
          , ADMIN_IDX:1
        })
        .then((result)=>{
          
          if(result.data.resultCode == 0){

            //결제정보가 없다면 결제 가능
            if(result.data.resultData == null || result.data.resultData.length < 1){

              //행사정보 체크
              if(result.data.eventData == null || result.data.eventData.length < 1 ){
                this.$alert(this.$t('resultpayment_label1')).then(() => {
                  this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
                });
                return;
              }

              //결제 결과 전송
              this.sendSuccess(result.data.eventData[0]);

            }else{
              //이미 결제 정보가 있는 경우 - 웹훅을 믿자.
              this.$alert(this.$t('resultpayment_label5')).then(()=>{
                this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
              });
            }
          }else{
            this.$alert(this.$t('resultpayment_label3')).then(()=>{
              this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
        });                   
      
    },    

    //전송
    sendSuccess(){
      this.$http.post(this.$host+'/ime/RegInsertPayment',{
          EVENT_IDX:this.EVENTCODE
        , OPTION_IDX:this.OPTIONCODE
        , VISITOR_IDX:this.VISITORCODE
        , ADMIN_IDX:1
        , IAMCODE: this.IMP_UID
        , MIDCODE: this.MERCHANT_UID
        , COST_TYPE: this.PG == "toss" ? 0 : 1
        , PG: this.PG
        , PAYMETHOD: "card"
        , MSG: escape(this.$t('resultpayment_label4'))
      })
      .then((result)=>{
        console.log("result : " + JSON.stringify(result));
        if(result.data.resultCode == 0){
          let token = result.data.resultToken;
          this.$alert(this.$t('resultpayment_label5')).then(()=>{
            this.$router.replace({path:"ShowTicket", query:{token:token}});
          });
        }else{
          this.$alert(this.$t('resultpayment_label6')).then(()=>{
            this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
          });
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert(this.$t('common_msg1'));
        }else{
            this.$alert(error.message);
        }
      });            

    },    





  },

}
</script>

<style scoped>


.bottombtn_pc{
  width:100%; height:50px;
  font-size:20px; font-weight: bold;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}

.bottombtn{
  width:90%; height:40px;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}

.bottombtn:hover{
  background-color: #2d2f77 !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #E5E5E5;
}

.tabletitle{
  width:30%;
  font-size:20px;
  color:black;
  border-left-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-top-width: 0px;
}
.tabletitle_mb{
  width:30%;
  font-size:14px;
  color:black;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
}

.tabledesc{
  width:70%;
  font-size:20px;
  color:black;
  border-right-width: 2px;
  border-left-width: 0px;
  border-bottom-width: 2px;
  border-top-width: 0px;
}
.tabledesc_md{
  width:70%;
  font-size:14px;
  color:black;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-top-width: 0px;
}


table{
  border-radius: 20px;
  border-collapse: separate; 
  border-spacing:0;
  border: 0px solid #C9C1C1;
}

td {
  border: 1px solid #C9C1C1;
}


.text_normal_pc{
  font-size:18px !important;
  color:#817b7b !important;
}

.text_normal_mb{
  font-size:14px;
  color:#817b7b;
}


</style>