<template>
<svg :width="width" :height="width" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.39266 0.158199C7.18603 -0.0527331 6.80876 -0.0527331 6.60212 0.158199L0.153478 6.73248C0.0548747 6.83332 0 6.96693 0 7.10477V14.9008C0 15.5075 0.502454 16 1.1215 16H4.75442C5.37347 16 5.87593 15.5075 5.87593 14.9008V11.6243C5.87593 11.0176 6.38095 10.5251 7 10.5251C7.61905 10.5251 8.12407 11.0176 8.12407 11.6243V14.9008C8.12407 15.5075 8.62653 16 9.24558 16H12.8785C13.4975 16 14 15.5075 14 14.9008V7.10477C14 6.96527 13.9451 6.83166 13.8465 6.73248L7.39788 0.158199H7.39266ZM12.8972 14.9015C12.8972 14.9142 12.8861 14.9251 12.8732 14.9251H9.24032C9.22746 14.9251 9.21631 14.9142 9.21631 14.9015V11.6251C9.21631 10.425 8.21999 9.45027 6.99477 9.45027C5.77038 9.45027 4.77322 10.4268 4.77322 11.6251V14.9015C4.77322 14.9142 4.76208 14.9251 4.74922 14.9251H1.1163C1.10344 14.9251 1.09229 14.9142 1.09229 14.9015V7.32238L6.99219 1.30701L12.8921 7.32238V14.9015H12.8972Z" 
    :fill="color"/>
</svg>
</template>
    
<script>
    export default {
        props:{
            width: { type:Number, default: 14 },
            color: { type:String, default: 'white' },
        },

    }
</script>