<template>
  <!-- 참여기업 등록 페이지 -->
  <v-container fluid class="pa-0">

    <v-row justify="center" class="gray_text ma-0 pa-0">
      <v-col cols="11" md="6" 
        :style="$vuetify.breakpoint.smAndDown ? 'padding: 0px 0px 0px 0px': 'padding: 20px 26px 50px 26px'">

      <v-row align="center" justify="center" class="mt-8 mt-md-5 mb-5">
        <label class="event_title">{{$t('pejoin_label1')}}</label>
      </v-row>

      <div style="border: 1px solid #DADADA;" 
        :style="$vuetify.breakpoint.smAndDown ? 'padding: 30px 10px 30px 10px': 'padding: 40px 26px 40px 26px'"
        class="gray_text">

        <v-row dense  align="start" justify="center">
          <v-col cols="12">
            <label>{{$t('pejoin_label2')}}</label>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="ACCNT"
              dense single-line clearable  
              maxlength="20" counter
              :placeholder="$t('pejoin_label3')"
              outlined height="43"
              color="red"
              :hint="$t('pejoin_label4')"
              :rules="[value => !this.pattern.test(value) || $t('common_msg34'), value => !value || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value) || $t('common_msg27')]"
              />
          </v-col>
          <v-col cols="4" align="center">
            <v-btn style="width:94%" 
              class="search_btn_freesize" elevation="0" outlined large @click="checkId">{{$t('pejoin_label5')}}</v-btn>
          </v-col>
        </v-row>

        <v-row dense  justify="center">
          <v-col cols="12">
            <label>{{$t('pejoin_label6')}}</label><label style="color:red">*</label>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="PW1"
              dense single-line clearable
              maxlength="30" counter 
              :placeholder="$t('pejoin_label7')"
              :hint="$t('pejoin_label8')"
              outlined height="43"
              color="red"
              type="password"
              />
          </v-col>
        </v-row>

        <v-row dense justify="center">
          <v-col cols="12">
            <label>{{$t('pejoin_label9')}}</label><label style="color:red">*</label>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="PW2"
              maxlength="30" counter
              dense single-line clearable
              :placeholder="$t('pejoin_label10')"
              :hint="$t('pejoin_label8')"
              outlined height="43"
              color="red"
              type="password"
              />
          </v-col>
        </v-row>

        <v-row dense  align="center" justify="center">
          <v-col cols="12">
            <label>{{$t('pejoin_label11')}}</label><label style="color:red">*</label>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="PLACENAME"
              dense single-line clearable
              :placeholder="$t('pejoin_label12')"
              outlined height="43"
              color="red"
              hide-details
              />
          </v-col>
        </v-row>
        
        <v-row dense align="center" justify="center" class="mt-5">
          <v-col cols="12">
            <label>{{$t('pejoin_label13')}}</label><label style="color:red">*</label>
          </v-col>
          <v-col cols="12">
              <v-textarea 
                  counter="100" v-model="PLACEDESC" 
                  :label="$t('pejoin_label13')" value="" outlined :rules="[ v => (v.length <= 100) || $t('pejoin_label14') ]"></v-textarea>
          </v-col>
        </v-row>

      </div>
      <button class="bottombtn_pc1" @click="sendJoin">{{$t('pejoin_label15')}}</button>
      <div style="height:150px"/>
  
    </v-col>
  </v-row>

  </v-container>
</template>


<script>
export default {
    
    data: () => ({
      EVENTCODE:"",
      ADMIN_IDX:"",
      COM_IDX:"",

      CHECKACCNT:"",
      ACCNT:"",
      PW1:"",
      PW2:"",
      PLACENAME:"",
      PLACEDESC:"",
      
      pattern : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      lan_code:0,
    }),

    methods: {

      checkId(){
        const accntValfirst = /^[a-z]/g;
        if(!accntValfirst.test(this.ACCNT)){
          this.$alert(this.$t('pejoin_label16'));
          return;
        }
        const accntvalidation = /^[a-z]+[a-z0-9]{3,29}$/g;
        if(!accntvalidation.test(this.ACCNT)){
          this.$alert(this.$t('pejoin_label17'));
          return;
        }
        this.$http.post(this.$host+'/ime/CheckPartAccnt',{
              ACCNT:escape(this.ACCNT)
            , EVENT_IDX:this.EVENTCODE
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
              if(result.data.cnt == 0){
                //통과
                this.$alert(this.$t('pejoin_label18'));
                this.CHECKACCNT = this.ACCNT;
              }else{
                //실패
                this.$alert(this.$t('pejoin_label19'));
                this.CHECKACCNT = "";
              }
          }else{
            this.$alert(this.$t('pejoin_label20'));
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.$alert(this.$t('pejoin_label20'));
        });
      },

      //회원가입 신청하기
      async sendJoin(){

        //첫글짜는 무조건 알파벳 
        const accntValfirst = /^[a-z]/g;
        if(!accntValfirst.test(this.ACCNT)){
          this.$alert(this.$t('pejoin_label16'));
          return;
        }
        const accntvalidation = /^[a-z]+[a-z0-9]{3,19}$/g;
        if(!accntvalidation.test(this.ACCNT)){
          this.$alert(this.$t('pejoin_label17'));
          return;
        }

        if(this.CHECKACCNT.length < 1 || this.ACCNT != this.CHECKACCNT){
          this.$alert(this.$t('pejoin_label21'));
          return;
        }

        const passVal = /^[a-zA-Z0-9!@#$%^&*()]{3,19}/;
        if(this.PW1  == undefined || this.PW1.length < 1 || this.PW1.length > 20){
          this.$alert(this.$t('pejoin_label22'));
          return;
        } 
        if(!passVal.test(this.PW1)){
          this.$alert(this.$t('pejoin_label23'));
          return;
        } 
        if(this.PW2  == undefined || this.PW2.length < 1 || this.PW2.length > 30){
          this.$alert(this.$t('pejoin_label24'));
          return;
        } 
        if(!passVal.test(this.PW2)){
          this.$alert(this.$t('pejoin_label25'));
          return;
        } 
        if(this.PW1 != this.PW2){
          this.$alert(this.$t('pejoin_label26'));
          return;
        }

        if(this.PLACENAME  == undefined || this.PLACENAME.length < 1){
          this.$alert(this.$t('pejoin_label27'));
          return;
        } 
        if(this.PLACEDESC  == undefined || this.PLACEDESC.length < 1){
          this.$alert(this.$t('pejoin_label28'));
          return;
        } 

        //신규
        this.$http.post(this.$host+'/ime/EventPlaceInsert',{
              PLACENAME: escape(this.PLACENAME)
            , PLACEDESC: this.PLACEDESC == null || this.PLACEDESC == undefined ? '' : escape(this.PLACEDESC)
            , ACCNT: escape(this.ACCNT)
            , PW: escape(this.PW1)
            , ADMIN_IDX: this.ADMIN_IDX
            , COM_IDX: this.COM_IDX
            , EVENT_IDX: this.EVENTCODE
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
              this.$alert(this.$t('pejoin_label29')).then(()=>{
                this.$router.push({path:"/PartEventInfo", query:{eventcode:this.EVENTCODE, lan_code:this.lan_code}});
              });
          }else{
            this.$alert(this.$t('pejoin_label30'));
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.$alert(this.$t('pejoin_label30'));
        });

      },


    },

    mounted(){
      this.EVENTCODE    = this.$route.query.eventcode;
      this.ADMIN_IDX    = this.$route.query.admin_idx;
      this.COM_IDX      = this.$route.query.com_idx;

      if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
        this.lan_code = Number(this.$route.query.lan_code);
      }else{
        this.lan_code = 0;
      }
      if(this.lan_code == 0){
        this.$i18n.locale = 'ko';
      }else{
        this.$i18n.locale = 'en';
      }

    },


    created(){

    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

  .join_round{
    background-color: white !important;
    border-radius: 20px !important; 
    border-color:#4e6ef7 !important; 
    border-style:solid !important; 
    border-width:1px 1px 1px 1px !important;
  }

  .search_btn_freesize {
    color: #2f2f2f !important;
    background-color: #e5e5e5 !important;
    border: thin solid #d1d1d1 !important;
    font-size: 15px !important;
    height: 43px !important;
    text-transform: none !important;
    white-space: pre-line !important;
  }


  .event_title{
    font-size: 16px;
    font-family: 'NotoSansKR-Medium' !important;
    color:#515151
  }

  /* outlinned border 설정 */
  .v-text-field--outlined >>> fieldset {
    border-radius: 0px;
    border-color: #DADADA;
  }

  .gray_text{
    font-size: 14px;
    font-family: 'NotoSansKR-Regular' !important;
    color:#515151
  }

  .bottombtn_pc1{
    width:100%; height:58px;
    font-size:16px; 
    font-family: 'NotoSansKR-Regular' !important;
    text-align: center !important;
    color: #fff !important;
    border-radius: 0px !important;
    background-color: #627BFF !important;
  }
</style>