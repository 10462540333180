<template>
  <v-container fluid class="pa-0">
  
    <h2>QR테스트</h2>
    <div id="loadingMessage"></div>
    
    <!-- QR 카메라 영역 -->
    <div style="width:100%;height:500px;background:red">
      <canvas style="width:100%;height:100%;" id="canvas" hidden></canvas>
    </div>
  
    <div>QR코드 데이터</div>
    <div id="outputData"></div>
    
  
  </v-container>
  </template>
  
  <script>
  import jsQR from "jsqr";
  export default {
  
    data: () => ({
      video: null,
      stream: null,
      canvasElement: null,
      canvas: null,
      loadingMessage: null,
      outputData: null,
      qrresult: null,
    }),
  
    mounted(){  
      //path를 통해서 들어올 경우 ex) /경로/값
      window.scrollTo(0,0);
      this.openCamera();
    },
  
    beforeDestroy() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }
    },
  
    methods: {
  
      async openCamera() {
        
        this.video         = document.createElement("video");
        this.canvasElement = document.getElementById("canvas");
        this.canvas         = this.canvasElement.getContext("2d");
        this.loadingMessage = document.getElementById("loadingMessage");
        this.outputData     = document.getElementById("outputData");
  
        try{
          //카메라 정보 가져오기 - https에서만 정보를 줌.
          this.stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
          this.video.srcObject = this.stream;
          this.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
          this.video.play();
          requestAnimationFrame(this.tick);
        }catch(error){
          this.loadingMessage.innerText = error;
          console.log("er : " + error);
        }
      },
  
      tick(){
  
        this.loadingMessage.innerText = "카메라 로딩....";

        if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {

          this.loadingMessage.innerText = "QR코드를 스캔하세요";
          this.canvasElement.hidden = false;
          this.canvasElement.height = this.video.videoHeight;
          this.canvasElement.width = this.video.videoWidth;
          this.canvas.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height);
          var imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
          var code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: "dontInvert",
          });

          if (code) {
            //QR코드가 인식되면
            this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
            this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
            this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
            this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
            
            this.outputData.innerText = code.data;
            if(this.code.data != null && this.code.data != undefined && this.code.data.length > 0 && this.qrresult != code.data){
              this.capturedata(code.data);
            }

          } else {
            this.outputData.innerText = "none";
          }
        }
        requestAnimationFrame(this.tick);
      },
      
      //카메라 화면에 QR감지 영역에 사각형 박스 그리기
      drawLine(begin, end, color) {
        this.canvas.beginPath();
        this.canvas.moveTo(begin.x, begin.y);
        this.canvas.lineTo(end.x, end.y);
        this.canvas.lineWidth = 4;
        this.canvas.strokeStyle = color;
        this.canvas.stroke();
      },
  
      capturedata(data){
        this.qrresult = data;
        this.$router.push({path:"QRResult", query:{qrdata:data}});
      }

    },
  
  }
  </script>
  
  <style scoped>
  
  
  </style>
  