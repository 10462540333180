<template>
<v-container fluid class="pa-0">

  <h2>{{ qrdata }}</h2>

</v-container>
</template>

<script>

export default {

  data: () => ({
    qrdata:null
  }),

  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    window.scrollTo(0,0);
    this.qrdata    = this.$route.query.qrdata;
  },

  beforeDestroy() {
   
  },

  methods: {



  },

}
</script>

<style scoped>


</style>
