<template>
  <!-- 등록정보 확인 페이지 -->
  <v-container fluid>
    
    <v-row style="background:yellow">
      <v-dialog style="background:green;" persistent scrollable overlay-color="#00000040" v-model="dialog" fullscreen >
        <v-card style="background:white;overflow:hidden">
          
          <div class="mt-5 mb-5" style="text-align:center">{{$t('runcheckinvit_label1')}}</div>
          
          <v-row dense no-gutters justify="center" style="overflow-y: auto;">  
            <v-col cols="12" xs="12" sm="12" md="8" align="center">
              <v-data-table
                :single-select="true"
                show-select
                :headers="headers"
                :items="itemList"
                v-model="selected"
                :no-data-text="$t('runcheckinvit_label2')"
                hide-default-footer
                :items-per-page="9999"
                item-key="IDX"
                mobile-breakpoint="0"
                
                >          
                <template v-slot:item.STATE="{ item }">
                  <template v-if="item.STATE == 1">
                    <template v-if="item.COST_TYPE == 0">
                      <label>{{item.OPTION_NAME}}<br>{{$t('runcheckinvit_label3')}}</label>
                    </template>
                    <template v-else-if="item.COST_TYPE == 1">
                      <label>{{item.OPTION_NAME}}<br>{{$t('runcheckinvit_label4')}}</label>
                    </template>
                  </template>
                  <template v-else-if="item.STATE == 2">
                    <label>{{item.OPTION_NAME}}<br>{{$t('runcheckinvit_label3')}}</label>
                  </template>
                  <template v-else-if="item.STATE == 3">
                    <label>{{item.OPTION_NAME}}<br>{{$t('runcheckinvit_label5')}}</label>
                  </template>
                  <template v-else-if="item.STATE == 4">
                    <label>{{item.OPTION_NAME}}<br>{{$t('runcheckinvit_label6')}}</label>
                  </template>
                  <template v-else-if="item.STATE == 5">
                    <template v-if="item.COST_TYPE == 0">
                      <label>{{item.OPTION_NAME}}<br>{{$t('runcheckinvit_label7')}}</label>
                    </template>
                    <template v-else-if="item.COST_TYPE == 1">
                      <label>{{item.OPTION_NAME}}<br>{{$t('runcheckinvit_label8')}}</label>
                    </template>
                  </template>
                  <template v-else-if="item.STATE == 6">
                    <label>{{item.OPTION_NAME}}<br>{{$t('runcheckinvit_label9')}}</label>
                  </template>
                </template>
              </v-data-table>
            </v-col>
          </v-row>


          <v-card-actions>
            <v-row justify="center" class="mb-10 pa-0 ma-0" dense no-gutters>
              <v-col cols="6" md="4" align="center">
                <v-btn class="bottombtn_pc2" @click="dialog = false">{{$t('common_msg21')}}</v-btn>
              </v-col>
              <v-col cols="6" md="4" align="center">
                <v-btn class="bottombtn_pc1" @click="save">{{$t('common_msg8')}}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row :style="$vuetify.breakpoint.smAndDown ? 'height:50px' : 'height:100px'"></v-row>

    <v-row class="d-flex justify-center">
      <div :style="$vuetify.breakpoint.smAndDown ? `width:94%` : 'width:700px'">
  
    <template v-if="info.NAME != null && info.NAME.length > 0">
      <v-row class="pt-1 pb-1 mt-md-1" style="background:#ffffff;" align="center" justify="center">
          <v-col cols="12" align="center">
              <label class="event_title">{{info.NAME}}</label>
          </v-col>
      </v-row>
    </template>

    <v-row :class="$vuetify.breakpoint.smAndDown ? 'mt-5 mb-1': 'mt-1 mb-3'" dense>
      <v-col cols="12" align="start">
        <label class="blue_text">{{ $t('regjoin_label12') }}</label>
      </v-col>
    </v-row>

    <v-row class="mt-1" dense>
      <v-col cols="12">

        <div style="border: 1px solid #DADADA;padding: 10px 26px 50px 26px" class="gray_text">

          <v-row  dense no-gutters align="center" class="ma-0 pa-0 mt-8">
            <v-col cols="12"><span style="color:#627BFF">*</span>{{$t('runcheckinvit_label10')}}</v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="ma-0 pa-0 mt-2">
            <v-col cols="12">
              <v-text-field  v-model="NAME" outlined hide-details />
            </v-col>
          </v-row>
      
          <v-row dense no-gutters align="center" class="ma-0 pa-0 mt-8">
            <v-col cols="12"><span style="color:#627BFF">*</span>{{$t('runcheckinvit_label11')}}</v-col>
          </v-row>
          <v-row dense align="center" justify="center" class="ma-0 pa-0 mt-2">
            <v-col cols="12">
              <v-text-field  v-model="TEL" outlined hide-details />
            </v-col>
          </v-row>
        </div>

        <button class="bottombtn_pc1" @click="checkinvit">{{$t('runcheckinvit_label12')}}</button>

      </v-col>
    </v-row>

      </div>
    </v-row>

    <v-row style="background:#ffffff;height:200px" align="center" justify="center">
    </v-row>   

</v-container>
</template>

<script>

export default {

  data: () => ({
        EVENTCODE:"",
        SELECT_OPTION:"",
        
        dialog:false,
        optionlist:[],
        info:{
            NAME:"",
            USESTATE:0,
        },
        NAME:"",
        TEL:"",
        
        visitor:{},

        //테이블 정의
        headers: [
        ],
        itemList:[],
        selected:[],
        
        lan_code:0, //한국어
        
        phone: null,
        numberrules:null,
  }),

  created(){


  },

  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    window.scrollTo(0,0);
    this.EVENTCODE  = this.$route.query.eventcode;
    this.SELECT_OPTION = this.$route.query.optioncode != null && this.$route.query.optioncode.length > 0 ? Number(this.$route.query.optioncode) : "";
    //console.log("SELECT OPTION : " + this.SELECT_OPTION);
    this.NAME = this.$route.query.name != null && this.$route.query.name.length > 0 ? this.$route.query.name : "";
    this.TEL = this.$route.query.tel != null && this.$route.query.tel.length > 0 ? this.$route.query.tel : "";

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
        this.$i18n.locale = 'ko';
    }else{
        this.$i18n.locale = 'en';
    }

    this.headers = [
      {text: this.$t('runcheckinvit_label13'),  value: 'INFO1', width:100, sortable: false, align:'center'},
      {text: this.$t('runcheckinvit_label14'), value: 'STATE', sortable: false, align:'center'},
      {text: this.$t('runcheckinvit_label15'), value: 'RDATE', sortable: false, align:'center'},
    ];    
    this.phone = value => !value || !/[^0-9]/.test(value) || this.$t('common_msg2');
    this.numberrules = [v => Number.isInteger(Number(v)) || this.$t('common_msg26') ];

    this.loadpage();
    
  },

  methods: {

    save(){
      //console.log("select : " + JSON.stringify(this.selected));
      this.$http.post(this.$host+'/ime/CheckVisitor',{
            EVENT_IDX:this.selected[0].EVENT_IDX
          , OPTION_IDX:this.selected[0].OPTION_IDX
          , ADMIN_IDX:1
          , VISITOR_IDX:this.selected[0].IDX
          , LAN_CODE:this.$i18n.locale
      })
      .then((result)=>{
        this.dialog = false;
          if(result.data.resultCode == 0){
            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultData.length == 1){
              //단건이면 바로 정보확인 페이지로 이동
              this.visitor = result.data.resultData[0];
              this.$router.replace({path:"RunEventInfo", query:{eventcode:this.EVENTCODE, visitorcode:this.visitor.IDX}});
            }
          }else{
            this.$alert(result.data.resultMsg);
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.dialog = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });            


    },

    checkinvit(){

      if(this.NAME.length < 1){
        this.$alert(this.$t('runcheckinvit_label16'));
        return;
      }
      
      const numberVal = /[^0-9]/g;
      if(this.TEL.length < 1){
        this.$alert(this.$t('runcheckinvit_label17'));
        return;
      }
      if(this.TEL.length > 20){
        let infomsg = this.$t('join_label14');
        this.$alert(infomsg);
        return;
      }
      if(numberVal.test(this.TEL)){
        let infomsg = this.$t('join_label15');
        this.$alert(infomsg);
        return;
      }

      this.$http.post(this.$host+'/ime/RunCheckInvitNameTel',{
            EVENT_IDX:this.EVENTCODE
          , ADMIN_IDX:1
          , NAME:escape(this.NAME)
          , TEL:escape(this.TEL)
          , LAN_CODE:this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            
            //console.log("visitor length : " + result.data.resultData.length);

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            if(result.data.resultData.length == 1){
              //단건이면 바로 정보확인 페이지로 이동
              this.visitor = result.data.resultData[0];
              this.$router.replace({path:"RunEventInfo", query:{eventcode:this.EVENTCODE, visitorcode:this.visitor.IDX}});
            }else{
              //여러건이면 선택
              this.itemList = result.data.resultData;
              this.dialog = true;

            }

          }else{
            this.$alert(result.data.resultMsg);
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });            


      //this.$router.replace({name:"agree", params:{code:this.INVITCODE, name:this.info.NAME}});

    },

    loadpage(){

        //초청장 정보 가져오기
        this.$http.post(this.$host+'/ime/EventDetail',{
              EVENT_IDX:this.EVENTCODE
            , ADMIN_IDX:1
            , LAN_CODE: this.$i18n.locale
        })
        .then((result)=>{
            if(result.data.resultCode == 0){
                this.info = result.data.resultData[0];
                if(this.info.EVENT_DATE_VAL == 0){
                  //행사기간 지남.
                  this.$alert(this.$t('runcheckinvit_label18'));
                  return;
                }
                result.data.optionData.forEach(option => {
                  this.optionlist.push({name:option.NAME, value:option.IDX});
                });
            }else{
              this.$alert(this.$t('runcheckinvit_label19'));
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert(this.$t('common_msg1'));
            }else{
                this.$alert(error.message);
            }
        });      

    },

  },

}
</script>

<style scoped>

.gray_border{
  border: 1px solid #DADADA;
}

.gray_text{
  font-size: 14px;
  font-family: 'NotoSansKR-Regular' !important;
  color:#515151
}

.event_title{
  font-size: 16px;
  font-family: 'NotoSansKR-Medium' !important;
  color:#515151
}

.blue_text{
  font-size: 14px;
  font-family: 'NotoSansKR-Medium' !important;
  color:#627BFF
}


.bottombtn_pc1{
  width:100%; height:58px;
  font-size:16px; 
  font-family: 'NotoSansKR-Regular' !important;
  text-align: center !important;
  color: #fff !important;
  border-radius: 0px !important;
  background-color: #627BFF !important;
}

.bottombtn_pc2{
  width:100%; height:58px;
  font-size:16px; 
  font-family: 'NotoSansKR-Regular' !important;
  text-align: center !important;
  color: #525252 !important;
  border-radius: 0px !important;
  background-color: #E8E8E8 !important;
}

</style>