<template>
  <v-container fluid>

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="purple"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">{{$t('common_msg11')}}</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>


    <v-row align="center" justify="center" class="mt-8 mt-md-15 mb-2">
      <v-col cols="12" align="center">
          <label class="event_title">{{$t('regreview_label1')}}</label>
      </v-col>
    </v-row>

    <v-row justify="center" class="gray_text ma-0 pa-0">
      <v-col cols="12" md="6" class="gray_border" 
        :style="$vuetify.breakpoint.smAndDown ? 'padding: 10px 15px 30px 15px': 'padding: 20px 26px 50px 26px'">

        <!-- 행사별 설문정보 -->
        <template v-for="formatitem in TITLELIST">

          <template v-if="formatitem.TYPE == 0">  <!-- 주관식 -->
            <v-row :key="formatitem.IDX+'TITLE'" dense no-gutters class="ma-0 pa-0 mt-8" align="center">
              <v-col cols="12">{{formatitem.TITLE}}</v-col>
            </v-row>
            <v-row dense :key="formatitem.IDX+'INPUT'" align="center" justify="center" class="ma-0 pa-0 mt-2">
              <v-col cols="12">
                <v-text-field :id="formatitem.IDX+'INPUT'" outlined v-model="formatitem.INPUT_TEXT" />
              </v-col>
            </v-row>
          </template>
      
          <template v-else-if="formatitem.TYPE == 1"> <!-- 단일선택 -->
            <v-row :key="formatitem.IDX+'TITLE'" dense no-gutters class="ma-0 pa-0 mt-8" align="center">
              <v-col cols="12"><span style="color:red">*</span>&nbsp;{{formatitem.TITLE}}</v-col>
            </v-row>
            <v-row :key="formatitem.IDX+'INPUT'" dense no-gutters class="ma-0 pa-0" align="center" justify="center"> 
              <v-col cols="12" class="ma-0 pa-0 mt-2">
                <v-select hide-details v-model="formatitem.SELECT_SINGLE_CHOICE" :id="formatitem.IDX+'INPUT'"
                    :items="getChildDropDownWidgets(formatitem.INSPECTION_IDX)" outlined :menu-props="{ origin: 'bottom', offsetY: true }"
                    item-text="NAME" item-value="INSPECTION_OPTION_IDX" 
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px;">
                          {{ data.item.NAME }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>                
                  </v-select>
              </v-col>
            </v-row>              
          </template>
      
          <template v-else-if="formatitem.TYPE == 2"> <!-- 멀티선택 -->
            <v-row :key="formatitem.IDX+'TITLE'" dense no-gutters class="ma-0 pa-0 mt-3" align="center">
              <v-col cols="12">{{formatitem.TITLE}}</v-col>
            </v-row>
            <v-row :key="formatitem.IDX+'INPUT'" dense no-gutters class="ma-0 pa-0 mt-2" align="center" justify="center"> 
              <v-col cols="12">
                <template v-for="fitem in getChildCheckWidgets(formatitem.INSPECTION_IDX)">
                  <v-row dense no-gutters class="ma-0 pa-0" align="center" :key="fitem.NAME">
                    <v-col cols="12" >
                      <label><input type="checkbox" v-model="fitem.CHECK" :value="fitem.INSPECTION_OPTION_IDX">&nbsp;{{fitem.NAME}}</label>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>              
          </template>

          <template v-else-if="formatitem.TYPE == 9"> <!-- 단순설명 -->
            <v-row :key="formatitem.IDX+'TITLE'" dense no-gutters class="ma-0 pa-0 mt-8" align="center">
              <v-col cols="12" style="color:#800000;font-size:16px">{{formatitem.TITLE}}</v-col>
            </v-row>
          </template>

        
      </template>

      </v-col>
    </v-row>
    <v-row justify="center" class="ma-0 pa-0" dense no-gutters>
      <v-col cols="12" md="6">
        <button class="bottombtn_pc1" @click="runjoin">{{$t('regreview_label2')}}</button>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>

export default {

  data: () => ({
        loading:false,
        EVENTCODE:"",
        VISITORCODE:"",
        LOCALCODE:"",
        testvalue:"",
        temp_idx: -1,
        
        info:{
            NAME:"",
            USESTATE:0,
        },

        LIST:[],        //행사 설문 접수양식 전체값
        TITLELIST:[],   //행사 설문 접수양식 제목
        
        lan_code:0, //한국어

        send_flag :false,
        mobile: null,
        numberrules:null,
        engrule:null,
        yearrules:null,

  }),

  mounted(){  
    window.scrollTo(0,0);
    //path를 통해서 들어올 경우 ex) /경로/값
    //console.log("get code " + JSON.stringify(this.$route.query));
    this.EVENTCODE   = this.$route.query.eventcode;
    this.VISITORCODE = this.$route.query.visitorcode;
    this.LOCALCODE  = this.$route.query.localcode; //내국인 외국인 선택 값

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
        this.$i18n.locale = 'ko';
    }else{
        this.$i18n.locale = 'en';
    }

    //내국인 외국인 설정이 우선
    if(this.LOCALCODE == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    let msg1 = this.$t('common_msg2');
    this.mobile =  value => !value || !/[^0-9]/.test(value) || msg1;
    let msg2 = this.$t('common_msg26');
    this.numberrules = [v => Number.isInteger(Number(v)) || msg2];
    let msg3 = this.$t('common_msg27');
    this.engrule = [v => !v || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(v) || msg3 ];
    let msg4 = this.$t('common_msg26');
    let msg5 = this.$t('common_msg28');
    this.yearrules = [v => Number.isInteger(Number(v)) || msg4, v => v.length == 4 || msg5];

    this.getSelectInspection();
  },

  methods: {



    //해당 포맷 옵션 아이디 리스트 가져오기
    getChildDropDownWidgets(format_idx){
      var temp_list = [];
      if(this.LIST.length < 1) return temp_list;
      this.LIST.forEach(element=>{
        if(element.INSPECTION_IDX == format_idx){
          temp_list.push(element);
        }
      })
      return temp_list;
    },

    getChildCheckWidgets(format_idx){
      var temp_list = [];
      this.LIST.forEach(element=>{
        if(element.INSPECTION_IDX == format_idx){
          temp_list.push(element);
        }
      })
      return temp_list;
    },


    getSelectInspection(){
      //행사별 신청양식 가져오기
      this.$http.post(this.$host+'/ime/SelectInspection',{
          EVENT_IDX:this.EVENTCODE
        , ADMIN_IDX:1
      })
      .then((result)=>{
          this.temp_idx  = -1;
          if(result.data.resultCode == 0){
            this.LIST = result.data.resultData;
            this.LIST.forEach(item=>{

              //내국인 외국인에 따른 질문 영문화 처리
              if(this.LOCALCODE == 1){
                item.TITLE = item.TITLE_ENG == null || item.TITLE_ENG == undefined || item.TITLE_ENG.length == 0 ? item.TITLE : item.TITLE_ENG;
                item.NAME  = item.NAME_ENG == null || item.NAME_ENG == undefined || item.NAME_ENG.length == 0 ? item.NAME : item.NAME_ENG;
              }

              if(item.TYPE == 2){
                item.CHECK = false;
              }
              if(item.IDX != this.temp_idx){
                if(item.TYPE == 1){
                  item.USE_CHOICE = true;       //단일, 다중 선택시 이게 있는 객체가 선택된 값을 저장하고 있는 객체다.
                }
                this.TITLELIST.push(item);
                this.temp_idx = item.IDX;
              }
            });
          }else{
            let infomsg = this.$t('join_label11');
            this.$alert(infomsg);
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      
    },


    runjoin(){

      //신청하기
      if(this.send_flag){
        let infomsg = this.$t('join_label12');
        this.$alert(infomsg);
      }
        
      //신청정보 입력 체크
      var checkflag = true;
      for(var j=0;j<this.TITLELIST.length;j++){
        
        var element2 = this.TITLELIST[j];
        var format_temp = document.getElementById(element2.IDX+'INPUT');

        if(element2.TYPE == 0){
          //주관식 값 만들어주기
          element2.INPUT_TEXT = format_temp.value == undefined || format_temp.value == null || format_temp.len < 1 ? "" : format_temp.value;

        }else if(element2.TYPE == 1){
          //단일선택만 필수 입력이라서 체크한다.
          if(element2.SELECT_SINGLE_CHOICE == undefined || element2.SELECT_SINGLE_CHOICE == null){
            let infomsg = element2.TITLE + this.$t('regedit_label13');
            alert(infomsg);
            this.$vuetify.goTo(format_temp, {duration:200, offset:50, easing:"easeInOutCubic"});
            format_temp.focus();
            checkflag = false;
            break;
          }
        }
      }

      if(checkflag){
        this.sendRegInfo();
      }

    },

    sendRegInfo(){

      const PARAMLIST = [];
      //서버로 전송할 데이터만 추출
      this.LIST.forEach(item=>{
        if(item.TYPE == 0){
          PARAMLIST.push(item);
        }else if(item.TYPE == 1){
          if(item.SELECT_SINGLE_CHOICE != null && item.SELECT_SINGLE_CHOICE != undefined){
            PARAMLIST.push(item);
          }
        }else if(item.TYPE == 2){
          if(item.CHECK){
            PARAMLIST.push(item);
          }
        }
      });

      this.loading = true;

      //비회원 설문
      this.$http.post(this.$host+'/web/InsertInspection',{
          EVENT_IDX:this.EVENTCODE
        , VISITOR_IDX:this.VISITORCODE
        , ADMIN_IDX:1
        , LAN_CODE:this.$i18n.locale
        , PARAMS:escape(JSON.stringify(PARAMLIST))
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{
        this.loading = false;
        if(result.data.resultCode == 0){
          this.send_flag = true;
            //유료 행사 옵션
            this.$alert(this.$t('regreview_label3')).then(()=>{
              this.$router.replace({path:"RunEventInfo", query:{eventcode:this.EVENTCODE, visitorcode:this.VISITORCODE}});
            })
        }else if(result.data.resultCode == 2){
          this.$alert(this.$t('regreview_label4')).then(()=>{
            this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
          });          

        }else{
          let infomsg = this.$t('join_label11');
          this.$alert(infomsg);
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert(this.$t('common_msg1'));
        }else{
            this.$alert(error.message);
        }
      });      
      

    },
    

  },

}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


.gray_border{
  border: 1px solid #DADADA;
}

.bottombtn_pc1{
  width:100%; height:58px;
  font-size:16px; 
  font-family: 'NotoSansKR-Regular' !important;
  text-align: center !important;
  color: #fff !important;
  border-radius: 0px !important;
  background-color: #627BFF !important;
}

.btncursor{
  cursor: pointer;
}

.event_title{
  font-size: 16px;
  font-family: 'NotoSansKR-Medium' !important;
  color:#515151
}

.blue_text{
  font-size: 14px;
  font-family: 'NotoSansKR-Medium' !important;
  color:#627BFF
}

.gray_text{
  font-size: 14px;
  font-family: 'NotoSansKR-Regular' !important;
  color:#515151
}

.info_text{
  font-size: 12px;
  font-family: 'NotoSansKR-Regular' !important;
  color:#C5C5C5
}


/* outlinned border 설정 */
.v-text-field--outlined >>> fieldset {
  border-radius: 0px;
  border-color: #DADADA;
}


</style>