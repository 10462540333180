<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <rect opacity="0.8" width="21" height="21" fill="url(#pattern0_395_87)"/>
        <defs>
        <pattern id="pattern0_395_87" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image0_395_87" transform="scale(0.0104167)"/>
        </pattern>
        <image id="image0_395_87" width="96" height="96" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADz0lEQVR4nO2dO2gUURSGP90oESUiiIpoExAbK4MQQbBJYaGNYCGIhYWNQioxkkYrA9pYBhHUzkenYBEbwUYUO7GIIDY+QoioIaImOTJ4FpaQjTs7z1/PB9Mt/9zvnrtz725xBoIgCIIgCIIgCIJAlJ3AmaoH8b/RDwwDT4FFwBCkB2igQTLOA8BV4I1P+NJLgm3ASeAu8BmYBSaAi8AQsJb60Otjuga8bzPptS/AOpcYA150IDFbcUE2AceA28CXDsZbywIkz8fTvsq/ppSwCgqy08f7APiRYayVsb7lq/o244RbSQVZbhPNepVKU2Ai46qxkgqyGhjwz70uaCyFshk4DtwEPlQ44faX6xvwCDgPDAJHgBvAVA7ZP73YpRQgOXbtBy4Bz4CFkibPanYlY7oHnPANmjIKcAeYyVFixjNPAdtX+FyPr9oRX8VVFeQTcB047EfRpRRegKwCC37kHPPn8pouBRr+3B5u+d1Q1KS/9UPEkC+EbuYnN7oRmPJJOu2rvAiBRs4FeeWb8kBO85MbnQx+vmWVH/BTRtkCjZQFmfdj57Cf/bulsgK8A8aBo0BfAflZae4h7fKbm2hWKitA5P8hCvCPLyB5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxcw8Xx5ARPPlxewAvJXe2OqK+oF6F8hf7CDfm1ljr/XG/dd90Z+7XJrXYAqekdbhvHXond01hv0t/Ri/p5SwnIoSNrxb/GO7rXpHZ32Bn3eR27c+8pZCZ1xR1Z4ZHUy/j3AKPA8p97Rk2UWYBWwF7gAPAF+FTzplrIg7T7b3EQnc7jvohdv1IuZK+1umvSOvgV8rHDCrYOCFJWdLLTHwNmMbS+7LoDltHJeApeBg8A+4BzwsItNr4wr2YPuL+kdXTh5S0y3dNXdkaIR60xFkz7tp6DkNLSBCii6d3SnlFmQNL2jC6cbgU8tqzx5UU8RNGrSO7pw0vSObgokJ6OyaXTZOzp5ucMuasxKX9NxfzZupH40linInP/ASr6ZWxGhOeFzfsZOhHajR0+bFzDUnmQzOuT/4wRBEARBEARBEARBEJCd32PSzhZiJtW2AAAAAElFTkSuQmCC"/>
        </defs>
        </svg>
        
</template>
    
<script>
    export default {
        
    }
</script>