<template>
  <v-container fluid style="background:white">

    <v-row>
      <v-dialog persistent scrollable  v-model="dialog1"
        width="700" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        >
        <v-card >
          
          <template v-if="lan_code == 0">
            <v-card-text style="overflow-x:hidden">
                <br>
                <h2 style="text-align: center;">개인정보 처리방침</h2>
                <br><br>
                <h3 style="margin: 10;color:#333333">[현행 2023년 5월 30일자 시행]</h3><br>
                <p style="margin: 10;">
                    ‘행사 입장권 예매 및 발급을 위한 통합서비스 페어패스’를 운영하는 <strong>주식회사 블루오리진(이하 '회사')</strong>은 개인정보보호법 제30조에 의거 이용자의 개인정보보호와 권익을 보호하고 관련된 고충 및 애로사항을 신속하게 처리하기 위하여 다음과 같이 개인정보처리방침을 제정하여 공개합니다.
                    <br><br>
                    회사는 관계법령에서 규정하고 있는 책임과 의무를 준수하고 실천하기 위해 최선의 노력을 하고 있습니다.
                    <br>
                </p>
                <br>
                <h3 style="margin: 10;color:#447ec4">목차</h3><br>
                <p style="margin: 10;">
                    제1조 개인정보의 수집 및 이용에 관한 안내<br><br>
                    제2조 개인정보자동수집 장치의 설치와 운영거부에 관한 사항<br><br>
                    제3조 개인정보의 보유·이용기간 및 파기<br><br>
                    제4조 개인정보 처리 위탁<br><br>
                    제5조 개인정보의 제3자 제공<br><br>
                    제6조 개인정보의 국외이전<br><br>
                    제7조 개인정보의 안전성 확보조치<br><br>
                    제8조 이용자 및 법정대리인의 권리와 그 행사 방법<br><br>
                    제9조 개인정보보호 책임자 및 이용자 권익침해에 대한 구제방법<br><br>
                    제10조 개인정보처리방침 변경에 관한 사항<br><br>
                    <br>
                </p>
                <br>

                <h3 style="margin: 10;color:#447ec4">제1조 개인정보의 수집 및 이용에 관한 안내</h3><br>
                <p style="margin: 10;">
                    <strong>회사는</strong>아래와 같이 행사 입장권 예매 및 발급을 위한 행사개설용 웹관리자 솔루션 및 이용자 어플리케이션에서 제공하는 서비스에 따라 개인정보의 수집목적, 항목, 보유 및 이용기간을 달리하여 서비스 제공을 위해 아래와 같은 방법으로 회원의 개인정보를 수집합니다. <br><br>
                    <v-col cols="12">
                    - 회사가 운영하는 웹사이트와 어플리케이션 서비스 이용과정에서 이용자가 직접 개인정보를 입력하는 방식<br>
                    - 서비스 이용 과정에서 이용자와 고객센터간 상담과정 시 정보제공 동의에 의해 수집되는 방식<br>
                    - 서비스를 이용하는 과정에 쿠키, 접속로그 등 자동으로 생성 및 수집되는 방식<br>
                    </v-col>
                </p>
                <br>
                <h4 style="margin: 10;color:#447ec4">•	회원가입 및 서비스 이용 (페어패스 어플리케이션)</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>수집목적</th>
                            <th>필수항목</th>
                            <th>선택항목</th>
                            <th>보유이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>회원가입 및 이용자 식별</td>
                            <td>이름, 이메일주소</td>
                            <td style="text-align:left">성별, 출생년도, 소속, 전화번호, 지역</td>
                            <td>회원탈퇴 시 까지</td>
                        </tr>
                        <tr>
                            <td>행사 참가자 식별 및 행사 운영</td>
                            <td>이름, 이메일주소</td>
                            <td style="text-align:left">성별, 출생년도, 전화번호 그 외 각종 기타항목 (행사별 사전등록 청서에 의거한 개별 필수/선택 요청항목)</td>
                            <td>회원탈퇴 시 까지</td>
                        </tr>
                        <tr>
                            <td>서비스 안내 및 상담</td>
                            <td>이름, 이메일주소</td>
                            <td style="text-align:left">전화번호, 상담내용</td>
                            <td>회원탈퇴 시 까지</td>
                        </tr>
                        <tr>
                            <td>고객관리(CRM)</td>
                            <td>이름, 이메일주소</td>
                            <td style="text-align:left">성별, 출생년도, 전화번호</td>
                            <td>회원탈퇴 시 까지</td>
                        </tr>
                    </tbody>
                </table>
                <br>

                <h4 style="margin: 10;color:#447ec4">•	비회원</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>수집목적</th>
                            <th>필수항목</th>
                            <th>선택항목</th>
                            <th>보유이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>행사 참가자 식별 및 행사 운영</td>
                            <td>이름, 전화번호</td>
                            <td style="text-align:left">성별, 출생년도, 전화번호 그 외 각종 기타항목 (행사별 사전등록 청서에 의거한 개별 필수/선택 요청항목)</td>
                            <td>수집일로부터 6개월까지</td>
                        </tr>
                        <tr>
                            <td>서비스 안내 및 상담</td>
                            <td>이름, 전화번호</td>
                            <td style="text-align:left">이메일주소, 상담내용</td>
                            <td>수집일로부터 6개월까지</td>
                        </tr>
                        <tr>
                            <td>서비스 안내 및 상담</td>
                            <td>이름, 전화번호</td>
                            <td style="text-align:left">성별, 출생년도, 이메일주소</td>
                            <td>수집일로부터 6개월까지</td>
                        </tr>
                        <tr>
                            <td>고객관리(CRM)</td>
                            <td>이름, 전화번호, 주소</td>
                            <td style="text-align:left">이메일주소</td>
                            <td>수집일로부터 6개월까지</td>
                        </tr>
                    </tbody>
                </table>
                <br>            

                <h4 style="margin: 10;color:#447ec4">•	마케팅</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>수집목적</th>
                            <th>필수항목</th>
                            <th>선택항목</th>
                            <th>보유이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>서비스의 이용, 할인, 신규서비스 업데이트 안내, 소비자 설문조사</td>
                            <td>이름, 이메일주소, 전화번호</td>
                            <td style="text-align:left">성별, 출생년도, 소속, 전화번호, 지역</td>
                            <td>수집일로부터 동의철회까지</td>
                        </tr>
                        <tr>
                            <td>행사관련 뉴스레터, 서비스 홍보 및 안내 뉴스레터(정기/비정기)</td>
                            <td>이메일주소</td>
                            <td style="text-align:left">이름, 전화번호, 소속</td>
                            <td>수집일로부터 동의철회까지</td>
                        </tr>
                        <tr>
                            <td>행사 참가자 식별 및 행사 운영</td>
                            <td>이름, 이메일주소</td>
                            <td style="text-align:left">성별, 출생년도, 전화번호 그 외 각종 기타항목 (행사별 사전등록 청서에 의거한 개별 필수/선택 요청항목)</td>
                            <td>수집일로부터 6개월까지</td>
                        </tr>
                    </tbody>
                </table>
                <br>     

                <h4 style="margin: 10;color:#447ec4">•	서비스 운영 및 관리</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>수집목적</th>
                            <th>필수항목</th>
                            <th>선택항목</th>
                            <th>보유이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>서비스 상담 및 안내</td>
                            <td style="text-align:left">이름, 전화번호, 이메일주소, 소속</td>
                            <td></td>
                            <td>수집일로부터 6개월까지</td>
                        </tr>
                        <tr>
                            <td>정산 및 세금계산서 발행 (행사 주최자)</td>
                            <td style="text-align:left">회사명, 대표자명, 사업자등록번호, 사업자등록증사본, 입금계좌정보(은행명, 예금주) 담당자 정보(이름, 연락처, 이메일주소)</td>
                            <td></td>
                            <td>기업회원 탈퇴시까지</td>
                        </tr>
                        <tr>
                            <td>결제(회원)</td>
                            <td style="text-align:left">이름, 휴대전화번호, 카드사 및 카드번호, 결제승인번호, 결제기록</td>
                            <td>참가 행사명</td>
                            <td>회원 탈퇴시까지</td>
                        </tr>
                        <tr>
                            <td>결제(비회원)</td>
                            <td style="text-align:left">이름, 휴대전화번호, 카드사 및 카드번호, 결제승인번호, 결제기록</td>
                            <td>참가 행사명</td>
                            <td>수집일로부터 6개월까지</td>
                        </tr>
                        <tr>
                            <td>이벤트/행사 경품 수령</td>
                            <td style="text-align:left">이름, 전화번호, 주소</td>
                            <td>참가 행사명</td>
                            <td>수집일로부터 6개월까지</td>
                        </tr>
                    </tbody>
                </table>
                <br>     

                <h4 style="margin: 10;color:#447ec4">•	주민등록번호</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>수집목적</th>
                            <th>필수항목</th>
                            <th>선택항목</th>
                            <th>보유이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>행사 참가자 식별 및 행사 운영</td>
                            <td>이름, 전화번호, 주민등록번호, 주소</td>
                            <td></td>
                            <td>수집일로부터 6개월까지</td>
                        </tr>
                    </tbody>
                </table>
                <br><br>  

                <p style="margin: 10;">
                    <span style="color:red">“회사”가 수집한 개인정보를 제한적으로 이용하는 것은 아래와 같이 원활한 서비스를 제공하기 위함이며, 이외의 용도로는 이용되지 않습니다. 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. 또한 이용자가 정보제공을 원치 않을 경우 개인정보 수집 및 제공에 동의하지 않을 수 있으며, 동의 후에도 언제든지 철회할 수 있습니다.</span><br>
                    <v-col cols="12">
                    • 회원가입 또는 각 행사별 참가등록 페이지를 통해 수집한 회원의 정보는 고객상담, 이용자의 행사 등록접수, 결재, 입장권 발급, 행사장 출입관리, 푸쉬알림, 후기작성, 설문조사 및 이벤트 참여 등 행사 별 주최측과 회사가 필요하다고 판단한 정보에 대하여 원활한 행사 운영을 위한 범위 내에서만 개인정보를 이용합니다.<br>
                    • 회원의 본인 식별 및 본인의사 확인 및 문의사항 또는 불만사항 처리 등의 회원관리를 위하여 개인정보를 이용합니다.<br>
                    • 회사는 회원에게 보다 다양한 서비스를 제공하고 각종 행사정보(신규 전시회 또는 이벤트) 안내를 위하여 회원으로부터 수집한 정보를 이용하여 회사가 제공하는 각종 알림 서비스를 이메일, SMS/LMS 문자메세지, 카카오 알림톡 서비스, PUSH알림 등의 방법으로 광고 또는 마케팅 활동을 수행할 수 있습니다. 이 경우 수신을 원치 않으시면 고객센터에 유선상으로 통보하시거나 고지되는 거부방법을 통하여 해당 서비스를 거절하실 수 있습니다.<br>
                    • 또한 법령 및 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위 및 비인가 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 고지사항 전달, 분쟁 조정을 위한 기록보존 등 이용자 보호 및 서비스 품질향상과 안정적인 운영을 위하여 개인정보를 이용합니다.<br>
                    • 회사가 처리하는 회원정보의 목적과 항목이 변경될 경우에는 관련법령에 따라 사전에 동의를 요청합니다 <br>
                    • 회사는 주민등록번호 처리를 원칙적으로 금지하며, 업무 수행 중 법률, 대통령령, 국회규칙, 대법원규칙, 헌법재판소규칙, 중앙선거관리위원회 규칙 및 감사원규칙에서 구체적으로 주민등록번호의 처리를 요구할 경우에만 처리하고 있습니다.<br>
                    • 회사는 아동의 개인정보를 보호하기 위하여 만 14세 미만 아동의 개인정보를 수집하지 않습니다.<br>
                    </v-col>
                </p>

                <h3 style="margin: 10;color:#447ec4">제2조 개인정보자동수집 장치의 설치와 운영거부에 관한 사항</h3><br>
                <p style="margin: 10;">
                    회사는 서비스 이용과정에서 이용자로부터 다음과 같은 정보들이 자동으로 생성/수집되고 다음의 목적으로 이용될 수 있습니다<br>
                </p>
                <h4 style="margin: 10;color:#447ec4">•	개인정보 자동수집정보 사용목적</h4>
                <v-col cols="12">
                    - 관련법규의 준수: 회사는 관련법규의 준수를 위해 이용자의 접속기록(로그인)을 보관할 의무가 있습니다.<br><br>
                    - 서비스 품질향상 및 상품 개발: 방문일시, 서비스 이용기록, 접속IP정보, 쿠키, 브라우져 정보, 광고식별자, 이동통신단말기 정보 항목을 수집하며 수집일부터 12개월까지 보유 이용되며 기간 경과 후 즉시 삭제됩니다<br>
                </v-col>
                <br>
                <h4 style="margin: 10;color:#447ec4">•	개인정보 자동수집안내 및 거부방법</h4>
                <v-col cols="12">
                    - 아래의 분석도구를 활용하여 이용자의 주요행동(행태정보)를 수집 및 분석합니다.  수집된 정보로 개인을 알아 볼 가능성은 낮습니다.
                    <table>
                        <tbody>
                            <tr>
                                <td>수탁사명</td>
                                <td style="text-align:left">서비스 이용통계(분석도구: 구글 애널리틱스, 네이버 애널리틱스, 카카오)</td>
                            </tr>
                        </tbody>
                    </table><br>
                    - 쿠키의 설치운영 및 거부 방법 : 아래 방법을 통해 쿠키 저장을 거부 할 수 있습니다.
                    <table>
                        <tbody>
                            <tr>
                                <td>Internet Explorer</td>
                                <td style="text-align:left">웹브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 메뉴의 옵션 설정</td>
                            </tr>
                            <tr>
                                <td>Microsoft Edge</td>
                                <td style="text-align:left">웹브라우저 상단 메뉴 > 설정 > 고급 설정 보기 > 쿠키 메뉴의 옵션 설정</td>
                            </tr>
                            <tr>
                                <td>Chrome 웹브라우저</td>
                                <td style="text-align:left">웹브라우저 상단 메뉴 > 설정 > 고급 > 콘텐츠 설정 > 쿠키 메뉴의 옵션 설정</td>
                            </tr>
                            <tr>
                                <td>Chrome 모바일</td>
                                <td style="text-align:left">크롬 App > 오른쪽상단 더보기 > 방문 기록 인터넷 사용 기록 삭제 > 기간선택 > 쿠키 및 사이트 데이터'와 '캐시된 이미지 또는 파일' 옆의 체크박스를 선택 > 인터넷 사용기록 삭제</td>
                            </tr>
                            <tr>
                                <td>Safari 모바일</td>
                                <td style="text-align:left">Safari App > 방문기록 및 웹사이트 데이터 지우기 > 확인</td>
                            </tr>
                            <tr>
                                <td>Naver 모바일</td>
                                <td style="text-align:left">Naver App > 설정 > 캐시삭제 + 인터넷 사용 기록 > 쿠키삭제</td>
                            </tr>

                        </tbody>
                    </table>
                </v-col>
                <br>

                <h3 style="margin: 10;color:#447ec4">제3조 개인정보의 보유·이용기간 및 파기</h3><br>
                <p style="margin: 10;">
                    회사가 수집한 개인정보는 이용자로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내 또는 관련법령에 따른 개인정보 보유·이용기간 내 처리하고 해당 목적이 달성 및 보유이용기간이 경과할 시에는 지체없이 해당 개인정보를 복구 또는 재생할 수 없는 방법으로 파기합니다.<br>
                </p>
                <h4 style="margin: 10;color:#447ec4">•	이용자 정보</h4>
                <v-col cols="12">
                    - 이용자가 서비스 회원을 탈퇴할 경우 회사는 즉시 개인정보를 삭제합니다. 단, 회원을 탈퇴하더라도 관계 법령에서 개인정보를 보존해야 할 필요가 있는 경우, 해당 법률의 규정에 따릅니다.<br><br>
                    - 이용자가 개인정보 수집 이용에 관한 동의를 철회하는 경우, 회사는 수집한 이용자의 개인정보를 지체없이 파기합니다.<br><br>
                    - 행사참여시에는 제3자 제공에 따른 개인정보 보유기간을 행사종료 후 6개월간 보관합니다.<br><br>
                    - 1년간 서비스를 사용하지 않은 이용자 계정의 상태를 휴면으로 전환하기 30일 전 다음의 안내사항은 통보합니다. 그럼에도 불구하고 이용자가 서비스에 접속하지 않는 경우 회사는 이용자의 개인정보는 운영중인 회원DB와 분리 보관하여 안전하게 보관합니다.<br><br>
                    (안내사항) 기간 만료 30일 전까지 개인정보가 파기되거나 분리되어 저장·관리되는 사실<br>
                    기간 만료일 및 해당 개인정보의 항목<br>
                    (안내방법) 전자우편·서면·전화 또는 이와 유사한 방법 중 어느 하나의 방법<br>
                </v-col>
                <br>
                <h4 style="margin: 10;color:#447ec4">•	관련법령에 따른 의무 보유기간</h4>
                <table>
                    <thead>
                        <tr>
                            <th>보유·이용목적</th>
                            <th>근거법령</th>
                            <th>보유·이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>법원의 영장을 받아 수사기관 요청 시</td>
                            <td>통신비밀 보호법</td>
                            <td>3개월</td>
                        </tr>
                        <tr>
                            <td>표시•광고에 관한 기록</td>
                            <td>전자상거래 등에서 소비자보호에 관한 법률</td>
                            <td>6개월</td>
                        </tr>
                        <tr>
                            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                            <td>전자상거래 등에서 소비자보호에 관한 법률</td>
                            <td>5년</td>
                        </tr>
                        <tr>
                            <td>계약 또는 청약철회 등에 관한 기록</td>
                            <td>전자상거래 등에서 소비자보호에 관한 법률</td>
                            <td>5년</td>
                        </tr>
                        <tr>
                            <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                            <td>전자상거래 등에서 소비자보호에 관한 법률</td>
                            <td>3년</td>
                        </tr>
                        <tr>
                            <td>거래내역 및 증빙서류와 관련된 정보</td>
                            <td>국세기본법, 법인세법</td>
                            <td>5년</td>
                        </tr>
                    </tbody>
                </table>
                <br><br>

                <h3 style="margin: 10;color:#447ec4">제4조 개인정보 처리 위탁</h3><br>
                <p style="margin: 10;">
                    회사는 원활한 서비스 제공 및 개인정보 업무처리를 위하여 이용자의 일부 개인정보처리업무를 위탁하고 있으며 위탁 계약 체결 시 관련법령에 따라 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 위탁업무의 내용이나 수탁자가 추가, 변경될 경우에는 지체 없이 관련 법령에 따른 사전 동의 안내 또는 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다<br>
                </p>
                <h4 style="margin: 10;color:#447ec4">•	개인정보처리 위탁업무 및 수탁사 안내</h4>
                <table>
                    <thead>
                        <tr>
                            <th>수탁업체</th>
                            <th>위탁업무의 내용</th>
                            <th>연락처</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Amazon Web Service</td>
                            <td>클라우드 서비스를 이용한 시스템 운영 및 데이터 보관</td>
                            <td>aws-korea-privacy@amazon.com</td>
                        </tr>
                        <tr>
                            <td>나이스평가정보㈜</td>
                            <td>본인인증</td>
                            <td>niceid@nice.co.kr</td>
                        </tr>
                        <tr>
                            <td>(주)포트원</td>
                            <td>전자결재 대행</td>
                            <td>cs@portone.io</td>
                        </tr>
                        <tr>
                            <td>토스 페이먼츠</td>
                            <td>전자결재 대행</td>
                            <td>1544-7772</td>
                        </tr>
                        <tr>
                            <td>네이버 N클라우드</td>
                            <td>LMS 발송 서비스(초청장 발송 시) 대량 이메일(뉴스레터) 발송 서비스</td>
                            <td>1544-5876</td>
                        </tr>
                        <tr>
                            <td>채널톡</td>
                            <td>고객 상담 시스템 운영</td>
                            <td>https://channel.io/ko</td>
                        </tr>
                        <tr>
                            <td>NHN</td>
                            <td>알림톡 발송 운영</td>
                            <td>1588-7967</td>
                        </tr>
                        <tr>
                            <td>파이어베이스</td>
                            <td>푸쉬알림 서비스 운영</td>
                            <td>https://firebase.google.com/support</td>
                        </tr>
                        <tr>
                            <td>카카오톡</td>
                            <td>앱 회원가입 및 이용자 식별</td>
                            <td>1577-3754</td>
                        </tr>
                        <tr>
                            <td>네이버</td>
                            <td>앱 회원가입 및 이용자 식별</td>
                            <td>1588-3820</td>
                        </tr>
                        <tr>
                            <td>구글</td>
                            <td>앱 회원가입 및 이용자 식별</td>
                            <td>googlekrsupport@google.com</td>
                        </tr>
                        <tr>
                            <td>애플</td>
                            <td>앱 회원가입 및 이용자 식별</td>
                            <td>getsupport.apple.com</td>
                        </tr>


                    </tbody>
                </table>
                <br><br>

                <h3 style="margin: 10;color:#447ec4">제5조 개인정보의 제3자 제공</h3><br>
                <p style="margin: 10;">
                    회사는 이용자의 개인정보를 ‘제1조 개인정보 수집이용 및 이용에 관한 안내’에서 명시한 범위 내에서만 처리하며, 이용자의 별도 사전동의가 있을 경우 또는 관련법령의 특별한 요구가 발생하는 경우에만 개인정보를 제3자에게 제공합니다.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	이용자가 사전에 동의한 경우 개인정보 제3자 제공안내(행사 참여)</h4>
                <table>
                    <thead>
                        <tr>
                            <th>제공 받는자</th>
                            <th>제공목적</th>
                            <th>제공 항목</th>
                            <th>보유이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>행사<br>주최자(기관)</td>
                            <td>행사참여 및 행사관련 각종 안내</td>
                            <td style="text-align:left">이름, 이메일, 전화번호 및 그 외 각종 기타항목 (행사별 사전등록신청서에 의거한 개별 필수/선택 요청항목)</td>
                            <td>행사 종료 후 6개월</td>
                        </tr>
                        <tr>
                            <td>행사 참가 기업(입장 시 발급된 큐알코드 수집을 본인이 허용한 기업)</td>
                            <td>회사 및 제품안내</td>
                            <td style="text-align:left">이름, 이메일, 전화번호, 성별 등</td>
                            <td>행사 종료 후 6개월</td>
                        </tr>
                        <tr>
                            <td>한국전시산업진흥회(AKEI)<br><span style="color:red">(전시회 행사에 한함)</span></td>
                            <td>산업통상자원부가 운영하는 국제전시회 인증제도 일환</td>
                            <td style="text-align:left">이름, 성별, 출생년도</td>
                            <td>행사 종료 후 6개월</td>
                        </tr>
                    </tbody>
                </table>
                <p style="margin: 10;">
                    ※ 각 개설되는 행사마다 제공되는 개인정보 수집항목이 상이하여 자세한 내용은 행사 별 신청서에서 확인하실 수 있습니다.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	관련법령에 근거한 사전동의 없은 제3자 제공안내</h4>
                <p style="margin: 10;">
                    <v-col cols="12">
                        - 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br>
                        - 관계법령에 의하여 국가기관으로부터 요구받은 경우<br>
                        - 범죄에 대한 수사상의 목적이 있거나, 정보통신 윤리위원회의 요청이 있는 경우<br>
                        - 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우<br>
                    </v-col>
                </p>
                <br>

                <h3 style="margin: 10;color:#447ec4">제6조 개인정보의 국외이전</h3><br>
                <p style="margin: 10;">
                    •	회사는 개인정보를 국외의 다른 사업자에게 제공하지 않습니다. 다만, 추후 정보통신서비스의 제공에 관한 계약 이행 및 이용자 편의 증진 등을 위하여 개인정보 처리 업무를 국외에 전송할 수 있으며, 이 경우 사전 동의 안내 또는 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
                </p>

                <h3 style="margin: 10;color:#447ec4">제7조 개인정보의 안전성 확보조치</h3><br>
                <p style="margin: 10;">
                    회사는 이용자의 개인정보를 안전하게 관리하여 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 최선을 다하고 있으며 필요한 기술적, 관리적 및 물리적 조치를 하고 있습니다.
                </p>
                <v-col cols="12">
                <h4 style="margin: 10;color:#447ec4">•	개인정보 취급 직원의 최소화 및 교육</h4>
                <p style="margin: 10;">
                    개인정보를 취급하는 직원을 최소화하고, 주기적인 개인정보 보호 교육을 실시하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	내부 관리계획의 수립 및 시행</h4>
                <p style="margin: 10;">
                    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	접속 기록의 보관 및 위변조 방지</h4>
                <p style="margin: 10;">
                    개인정보 침해사고 발생 시 대응이 용이하도록 개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	개인정보의 암호화</h4>
                <p style="margin: 10;">
                    이용자의 개인정보는 암호화되어 저장 및 관리되고 있습니다.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	해킹 등에 대비한 기술적 대책</h4>
                <p style="margin: 10;">
                    회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 합니다. 또한 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	개인정보에 대한 접근통제 및 제한</h4>
                <p style="margin: 10;">
                    개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위한 조치를 하고 있습니다.
                </p>
                </v-col>

                <h3 style="margin: 10;color:#447ec4">제8조 이용자 및 법정대리인의 권리와 그 행사 방법</h3><br>
                <p style="margin: 10;">
                    회사는 이용자(또는 법정대리인)의 개인정보 권리를 보호하기 위해 아래와 같이 행사 방법을 마련하고 있습니다.
                </p>
                <v-col>
                    <h4 style="margin: 10;color:#447ec4">•	이용자의 권리 및 행사방법</h4>
                    <p style="margin: 10;">
                        - 열람 또는 수정: (앱) 마이페이지 > 내정보  (웹 참가신청페이지) 등록정보확인하기 > 이름, 휴대폰번호 입력하여 등록정보 확인 및 수정<br>
                        - 회원탈퇴 또는 삭제요청: (앱) 마이페이지 > 내정보 > 회원탈퇴<br><br>
                        - 그 밖에 서면, 전자우편 등을 통하여 개인정보의 처리 정지 및 삭제를 요구할 수 있습니다.<br><br>
                        - 회사는 개인정보의 오류 등에 대한 정정 또는 삭제를 요청한 경우에는 정정 또는 삭제를 완료하기 전까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br><br>
                        - 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 당해 개인정보의 삭제를 요구할 수 없습니다.<br><br>
                        - 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인인지를 확인합니다.<br><br>
                    </p>
                    <h4 style="margin: 10;color:#447ec4">•	법정대리인의 권리 및 행사방법</h4>
                    <p style="margin: 10;">
                        - 이용자의 법정대리인이나 위임을 받은 자 등 대리인이 이용자의 권리(열람, 정정, 처리정지, 삭제)를 행사하는 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출해야 합니다.<br>
                        - 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 정당한 대리인인지를 확인합니다.<br>
                    </p>
                </v-col>
                
                <h3 style="margin: 10;color:#447ec4">제9조 개인정보보호 책임자 및 이용자 권익침해에 대한 구제방법</h3><br>
                <p style="margin: 10;">
                    회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>개인정보 보호 책임자</th>
                            <th>직책</th>
                            <th>연락처</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>전서윤</td>
                            <td>CEO</td>
                            <td>02-2038-0060<br>privacy@fairpass.world</td>
                        </tr>
                    </tbody>
                </table>
                <h4 style="margin: 10;color:#447ec4">•	개인정보보호 책임자의 역할</h4>
                <p style="margin: 10;">
                    이용자는 서비스를 이용하면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보보호책임자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	권익침해관련 도움받을 수 있는 기관</h4>
                <p style="margin: 10;">
                    이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해규제 및 상담을 받으실 수 있습니다.<br>
                    권익침해 관련 자세한 도움이 필요하시면 아래 기관에 문의하여 주시기 바랍니다.<br>
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>내용</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>개인정보 침해신고센터<br>(한국인터넷진흥원 운영)</td>
                            <td style="text-align:left">
                                - 소관업무: 개인정보 침해사실 신고, 상담 신청<br>
                                - 홈페이지: privacy.kisa.or.kr<br>
                                - 전화: (국번없이) 118<br>
                                - 주소: (58324) 전남 나주시 진흥길 9(빛가람동 301-2 3층 개인정보침해신고센터
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>개인정보 분쟁조정위원회</td>
                            <td style="text-align:left">
                                - 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)<br>
                                - 홈페이지: www.kopico.go.kr<br>
                                - 전화: (국번없이) 1833-6972<br>
                                - 주소: (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>대검찰청 사이버범죄수사단</td>
                            <td style="text-align:left">- 전화 : 02-3480-3573<br>- 홈페이지 : www.spo.go.kr</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>경찰청 사이버안전국</td>
                            <td style="text-align:left">- 전화 : 182<br>- 홈페이지 : cyberbureau.police.go.kr</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <br>
                <h3 style="margin: 10;color:#447ec4">제10조 개인정보처리방침 변경에 관한 사항</h3><br>
                <p style="margin: 10;">
                    개인정보처리방침은 시행일로부터 적용되며, 관련법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 지체없이 홈페이지를 통하여 고지할 것입니다. 아래 셀렉트 박스를 클릭하시면 이전 버전의 개인정보처리방침을 볼 수 있습니다.
                </p>

                <p style="margin: 10;">
                    처리방침 시행일 2023-05-30
                </p>

                <br><br>     
                <p style=""><input type="checkbox" v-model="checkbox2">&nbsp;&nbsp;위 내용에 동의합니다.</p>

            </v-card-text>
          </template>
          <template v-else>
            <v-card-text>
                <br>
                <h2 style="text-align: center;">Privacy Policy</h2>
                <br><br>
                <h3 style="margin: 10;color:#333333">[Effective date: 2023-05-30]</h3><br>
                <p style="margin: 10;">
                    'Blueorigin Co., Ltd.', operated by 'FAIRPASS for Integrated service for ticket reservation and issuance of event tickets ' (hereinafter referred to as the 'Company'), is in accordance with Article 30 of the Personal Information Protection Act to protect users' personal information and rights and interests, and to promptly deal with related grievances and difficulties. We have established and operated the following personal information processing policy.
                    <br><br>
                    The company is doing its best to comply with and practice the responsibilities and obligations stipulated in the relevant laws and regulations.
                    <br>
                </p>
                <br>
                <h3 style="margin: 10;color:#447ec4">INDEX</h3><br>
                <p style="margin: 10;">
                    Article 1 Guidance on the collection and use of personal information<br><br>
                    Article 2 Matters concerning the installation and refusal to operate automatic personal information collection devices<br><br>
                    Article 3 Retention/Use Period and Destruction of Personal Information<br><br>
                    Article 4 Consignment of personal information processing<br><br>
                    Article 5 Provision of personal information to third parties<br><br>
                    Article 6 Overseas Transfer of Personal Information<br><br>
                    Article 7 Measures to ensure safety of personal information<br><br>
                    Article 8 Rights of users and legal representatives and how to exercise them<br><br>
                    Article 9 Remedy for personal information protection manager and infringement of user's rights and interests<br><br>
                    Article 10 Matters concerning changes to the personal information processing policy<br><br>
                    <br>
                </p>
                <br>

                <h3 style="margin: 10;color:#447ec4">Article 1 Guidance on the collection and use of personal information</h3><br>
                <p style="margin: 10;">
                    The company collects the minimum amount of personal information necessary to provide the service by changing the purpose of collection, item, retention and use period of personal information according to the service provided as follows.<br><br>
                    <v-col cols="12">
                    - A method in which the user directly inputs personal information in the process of using the website and application service operated by the company<br>
                    - A method of collecting information by consent to provide information during the consultation process between the user and the customer center in the process of the service<br>
                    - A method of automatically generating and collecting cookies, access logs, etc. in the course of using the service<br>
                    </v-col>
                </p>
                <br>
                <h4 style="margin: 10;color:#447ec4">•	Membership registration and service use (FAIRPASS Application)</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>Purpose of collection</th>
                            <th>Required</th>
                            <th>Choice</th>
                            <th>Retention/Use period</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Membership registration and user identification</td>
                            <td>Name, E-mail</td>
                            <td style="text-align:left">Gender, Year of birth, Phone number, Affiliation, Area of activity</td>
                            <td>Until membership withdrawal</td>
                        </tr>
                        <tr>
                            <td>Event participant identification and event operation</td>
                            <td>Name, E-mail</td>
                            <td style="text-align:left">Gender, Year of birth, Phone number, Other items (Items requested by the host)</td>
                            <td>Until membership withdrawal</td>
                        </tr>
                        <tr>
                            <td>Information on various notices related to services</td>
                            <td>Name, E-mail</td>
                            <td style="text-align:left">Phone number, Consultation details</td>
                            <td>Until membership withdrawal</td>
                        </tr>
                        <tr>
                            <td>Customer Relationship Management (CRM)</td>
                            <td>Name, E-mail</td>
                            <td style="text-align:left">Gender, Year of birth, Phone number</td>
                            <td>Until membership withdrawal</td>
                        </tr>
                    </tbody>
                </table>
                <br>

                <h4 style="margin: 10;color:#447ec4">•	Non-members</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>Purpose of collection</th>
                            <th>Required</th>
                            <th>Choice</th>
                            <th>Retention/Use period</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Event participant identification and event operation</td>
                            <td>Name, Phone number</td>
                            <td style="text-align:left">Gender, Year of birth, Phone number, Other items (Items requested by the host)</td>
                            <td>Up to 6 months from collection date</td>
                        </tr>
                        <tr>
                            <td>Information on various notices related to services</td>
                            <td>Name, Phone number</td>
                            <td style="text-align:left">Email, Consultation details</td>
                            <td>Up to 6 months from collection date</td>
                        </tr>
                        <tr>
                            <td>Customer Relationship Management (CRM)</td>
                            <td>Name, Phone number</td>
                            <td style="text-align:left">Gender, Year of birth, E-mail</td>
                            <td>Up to 6 months from collection date</td>
                        </tr>
                        <tr>
                            <td>Receive event prizes</td>
                            <td>Name, Phone number, Adress</td>
                            <td style="text-align:left">E-mail</td>
                            <td>Up to 6 months from collection date</td>
                        </tr>
                    </tbody>
                </table>
                <br>            

                <h4 style="margin: 10;color:#447ec4">•	Marketing</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>Purpose of collection</th>
                            <th>Required</th>
                            <th>Choice</th>
                            <th>Retention/Use period</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Service discount and use recommendation, new feature update information, consumer experience and awareness survey</td>
                            <td>Name, E-mail, Phone number</td>
                            <td style="text-align:left">Gender, Year of birth, Affiliation, Area of activity</td>
                            <td>From the date of collection to withdrawal of consent</td>
                        </tr>
                        <tr>
                            <td>Send regular newsletters, promote new features and services, and encourage use</td>
                            <td>E-mail</td>
                            <td style="text-align:left">Name, Phone number, Affiliation</td>
                            <td>From the date of collection to withdrawal of consent</td>
                        </tr>
                        <tr>
                            <td>Event participant identification and event operation</td>
                            <td>Name, E-mail</td>
                            <td style="text-align:left">Gender, Year of birth, Phone number, Other items (Items requested by the host)</td>
                            <td>Up to 6 months from collection date</td>
                        </tr>
                    </tbody>
                </table>
                <br>     

                <h4 style="margin: 10;color:#447ec4">•	Service operation and management</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>Purpose of collection</th>
                            <th>Required</th>
                            <th>Choice</th>
                            <th>Retention/Use period</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Customer Relationship Management (CRM)</td>
                            <td style="text-align:left">Name, E-mail, Phone number, Affiliation</td>
                            <td>Event Name</td>
                            <td>Up to 6 months from collection date</td>
                        </tr>
                        <tr>
                            <td>Host tax invoice issuance and settlement-related tasks (Host)</td>
                            <td style="text-align:left">Issuance of tax invoice (Company name, representative name, business registration number, copy of business registration certificate), account information (bank name, account holder, account number), contact information (name, phone number, e-mail)</td>
                            <td>Event Name</td>
                            <td>Until membership withdrawal</td>
                        </tr>
                        <tr>
                            <td>Payment(Membership)</td>
                            <td style="text-align:left">Card company name, Card number, Name, Mobile phone number, Payment approval number, Payment record</td>
                            <td>Event Name</td>
                            <td>Until membership withdrawal</td>
                        </tr>
                        <tr>
                            <td>Payment(Non-Member)</td>
                            <td style="text-align:left">Card company name, Card number, Name, Mobile phone number, Payment approval number, Payment record</td>
                            <td>Event Name</td>
                            <td>Up to 6 months from collection date</td>
                        </tr>
                        <tr>
                            <td>Receive event prizes</td>
                            <td style="text-align:left">Name, Phone number, Adress</td>
                            <td>Event Name</td>
                            <td>Up to 6 months from collection date</td>
                        </tr>
                    </tbody>
                </table>
                <br>     

                <h4 style="margin: 10;color:#447ec4">•	Resident registration number</h4><br>
                <table>
                    <thead>
                        <tr>
                            <th>Purpose of collection</th>
                            <th>Required</th>
                            <th>Choice</th>
                            <th>Retention/Use period</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Event participant identification and event operation</td>
                            <td>Name, Phone number, Resident registration number</td>
                            <td></td>
                            <td>Up to 6 months from collection date</td>
                        </tr>
                    </tbody>
                </table>
                <br><br>  

                <p style="margin: 10;">
                    <span style="color:red">The limited use of personal information collected by the “Company” is to provide smooth service as follows, and is not used for any other purpose. If the purpose of use is changed, we plan to take necessary measures, such as obtaining separate consent in accordance with Article 18 of the Personal Information Protection Act. In addition, if the user does not want to provide information, he or she may not consent to the collection and provision of personal information, and may withdraw at any time even after consent.</span><br>
                    <v-col cols="12">
                    •	Members’ information collected through membership registration or participation registration pages for each event is event-specific, such as customer consultation, user event registration, payment, ticket issuance, event venue access management, push notifications, review writing, surveys, and event participation. Personal information is used only within the scope for smooth event operation for information that the organizers and the company deem necessary.<br>
                    •	Personal information is used for member management, such as identification of members, confirmation of their intentions, and handling of inquiries or complaints.<br>
                    •	The company uses information collected from members to provide more diverse services to members and guide various event information (new exhibitions or events), and provides various notification services by e-mail, SMS/LMS text message, Kakao Notification Talk Advertising or marketing activities can be performed by means of services, PUSH notifications, etc. In this case, if you do not want to receive the service, you can notify the customer center over the phone or reject the service through a notified refusal method.<br>
                    •	In addition, measures to limit the use of members who violate laws and the Terms of Use, prevent and sanction acts that interfere with the smooth operation of the service, including fraudulent use, and unauthorized acts, prevent account theft and fraudulent transactions, and deliver notices , Personal information is used for user protection, service quality improvement, and stable operation, such as record retention for dispute mediation.<br>
                    •	If the purpose and items of member information processed by the company are changed, consent is requested in advance in accordance with the relevant laws and regulations.<br>
                    •	The company, in principle, prohibits the processing of resident registration numbers.<br>
                    •	It is processed only when the Constitutional Court Rules, the National Election Commission Rules and the Board of Audit and Inspection Rules specifically request the processing of resident registration numbers.<br>
                    •	The company does not provide our services to children under the age of 14 and does not collect personal information related thereto.<br>
                    </v-col>
                </p>

                <h3 style="margin: 10;color:#447ec4">Article 2 Matters concerning the installation and refusal to operate automatic personal information collection devices</h3><br>
                <p style="margin: 10;">
                    The company automatically generates/collects the following information from users in the process of using the service and may use it for the following purposes.<br>
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Purpose of Use of Automatically Collected Personal Information</h4>
                <v-col cols="12">
                    - Compliance with relevant laws and regulations: The company is obliged to keep the user's access record (login) to comply with the relevant laws and regulations.<br><br>
                    - Service quality improvement and product development: The date and time of visit, service use record, access IP information, cookie, browser information, advertisement identifier, and mobile communication terminal information items are collected, retained and used for up to 12 months from the date of collection, and deleted immediately after the period has elapsed.<br>
                </v-col>
                <br>
                <h4 style="margin: 10;color:#447ec4">•	Guidance on automatic collection of personal information and how to reject it</h4>
                <v-col cols="12">
                    - The following analysis tools are used to collect and analyze the main behaviors (behavioral information) of users. It is unlikely that any individual can be identified from the collected information.
                    <table>
                        <tbody>
                            <tr>
                                <td>Consignment company name</td>
                                <td style="text-align:left">Google Analytics, Naver Analytics</td>
                            </tr>
                        </tbody>
                    </table><br>
                    - 쿠키의 설치운영 및 거부 방법 : 아래 방법을 통해 쿠키 저장을 거부 할 수 있습니다.
                    <table>
                        <tbody>
                            <tr>
                                <td>Internet Explorer</td>
                                <td style="text-align:left">Tools at the top of the web browser>Internet Options>Option settings in the Privacy menu</td>
                            </tr>
                            <tr>
                                <td>Microsoft Edge</td>
                                <td style="text-align:left">Web browser top menu > Settings > View advanced settings > Cookie menu option setting</td>
                            </tr>
                            <tr>
                                <td>Chrome web browser</td>
                                <td style="text-align:left">Web browser top menu > Settings > Advanced > Content Settings > Cookie menu option setting</td>
                            </tr>
                            <tr>
                                <td>Chrome Mobile</td>
                                <td style="text-align:left">Chrome App > More at the top right > Delete browsing history > Delete browsing history > Select a time period > Check the boxes next to “Cookies and site data” and “Cached images or files” > Delete browsing history</td>
                            </tr>
                            <tr>
                                <td>Safari Mobile</td>
                                <td style="text-align:left">Safari App > Clear browsing history and website data > OK</td>
                            </tr>
                            <tr>
                                <td>Naver Mobile</td>
                                <td style="text-align:left">Naver App > Settings > Clear Cache + Browsing History > Delete Cookies</td>
                            </tr>

                        </tbody>
                    </table>
                </v-col>
                <br>

                <h3 style="margin: 10;color:#447ec4">Article 3 Retention/Use Period and Destruction of Personal Information</h3><br>
                <p style="margin: 10;">
                    The personal information collected by the company is processed within the personal information retention and use period agreed upon at the time of collecting personal information from users or within the personal information retention and use period in accordance with relevant laws, and when the purpose is achieved and the retention and use period elapses We will destroy the personal information in a way that cannot be recovered or reproduced without delay.<br>
                </p>
                <h4 style="margin: 10;color:#447ec4">•	User information</h4>
                <v-col cols="12">
                    - When a user withdraws from service membership, the company immediately deletes personal information. However, if it is necessary to preserve personal information in accordance with the relevant laws and regulations even after membership is canceled, the provisions of the applicable laws shall be followed.<br><br>
                    -  If the user withdraws consent to the collection and use of personal information, the company destroys the collected user's personal information without delay.<br><br>
                    -  When participating in an event, the retention period of personal information provided by a third party is kept for 6 months after the end of the event.<br><br>
                    - The following instructions will be notified 30 days before the status of a user account that has not used the service for one year is converted to dormant. Nevertheless, if the user does not access the service, the company keeps the user's personal information safe by separating it from the member DB in operation.<br><br>
                    (Guidelines) The fact that personal information is destroyed or separated and stored and managed until 30 days before the expiration date<br>
                    Expiration date and items of personal information<br>
                    (Information method) Either by e-mail, in writing, by phone or similar methods<br>
                </v-col>
                <br>
                <h4 style="margin: 10;color:#447ec4">•	Obligatory retention period under relevant laws and regulations</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Retention purpose</th>
                            <th>Statute of limitations</th>
                            <th>Retention period</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Retention of records of access</td>
                            <td>Protection of Communications Secrets Act</td>
                            <td>3 months</td>
                        </tr>
                        <tr>
                            <td>Records on display/advertising</td>
                            <td>Act on Consumer Protection in Electronic Commerce, Etc.</td>
                            <td>6 months</td>
                        </tr>
                        <tr>
                            <td>Records on payment and supply of goods, etc</td>
                            <td>Act on Consumer Protection in Electronic Commerce, Etc.</td>
                            <td>5 years</td>
                        </tr>
                        <tr>
                            <td>Records on contract or subscription withdrawal, etc.</td>
                            <td>Act on Consumer Protection in Electronic Commerce, Etc.</td>
                            <td>5 years</td>
                        </tr>
                        <tr>
                            <td>Records on consumer complaints or dispute handling</td>
                            <td>Act on Consumer Protection in Electronic Commerce, Etc.</td>
                            <td>3 years</td>
                        </tr>
                        <tr>
                            <td>Books and evidential documents for all transactions stipulated by the tax law</td>
                            <td>Basic National Tax Act</td>
                            <td>5 years</td>
                        </tr>
                    </tbody>
                </table>
                <br><br>

                <h3 style="margin: 10;color:#447ec4">Article 4 Consignment of personal information processing</h3><br>
                <p style="margin: 10;">
                    The company entrusts some personal information processing tasks for smooth personal information processing, and supervises whether the trustee handles personal information safely in accordance with the relevant laws and regulations when concluding a consignment contract. If the contents of the consignment service or the consignee are added or changed, we will disclose it without delay through the prior consent guide in accordance with the relevant laws or through this personal information processing policy.<br>
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Personal information processing consignment service and consignee information</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Fiduciary</th>
                            <th>Consignment Work</th>
                            <th>Contact</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Amazon Web Service</td>
                            <td>Operating systems and archiving data using cloud services</td>
                            <td>aws-korea-privacy@amazon.com</td>
                        </tr>
                        <tr>
                            <td>Nice Information Co., Ltd.</td>
                            <td>Personal Identification</td>
                            <td>niceid@nice.co.kr</td>
                        </tr>
                        <tr>
                            <td>Port One Co., Ltd</td>
                            <td>Payment</td>
                            <td>cs@portone.io</td>
                        </tr>
                        <tr>
                            <td>Tosspayments</td>
                            <td>Payment</td>
                            <td>1544-7772</td>
                        </tr>
                        <tr>
                            <td>Naver N Cloud</td>
                            <td>LMS Service(when invitation is sent)<br>E-mail (newsletter) Service</td>
                            <td>1544-5876</td>
                        </tr>
                        <tr>
                            <td>Channel Corp.</td>
                            <td>Operation of customer CS</td>
                            <td>https://channel.io/ko</td>
                        </tr>
                        <tr>
                            <td>NHN</td>
                            <td>Operation of sending notification messages</td>
                            <td>1588-7967</td>
                        </tr>
                        <tr>
                            <td>Firebase</td>
                            <td>Operation of push notification service</td>
                            <td>https://firebase.google.com/support</td>
                        </tr>
                        <tr>
                            <td>Kakaotalk</td>
                            <td>Membership registration and user identification</td>
                            <td>1577-3754</td>
                        </tr>
                        <tr>
                            <td>Naver</td>
                            <td>Membership registration and user identification</td>
                            <td>1588-3820</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>Membership registration and user identification</td>
                            <td>googlekrsupport@google.com</td>
                        </tr>
                        <tr>
                            <td>Apple</td>
                            <td>Membership registration and user identification</td>
                            <td>getsupport.apple.com</td>
                        </tr>


                    </tbody>
                </table>
                <br><br>

                <h3 style="margin: 10;color:#447ec4">Article 5 Provision of personal information to third parties</h3><br>
                <p style="margin: 10;">
                    The company processes the personal information of the information subject only within the scope specified in 'Article 1 Guidelines on Collection and Use of Personal Information', and only provides personal information when there is a separate prior consent of the user or a special request in the relevant laws. Provided to 3rd party.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Guidelines on provision of personal information to third parties if the user agrees in advance (participation in the event)</h4>
                <table>
                    <thead>
                        <tr>
                            <th>recipient</th>
                            <th>Purpose of provision</th>
                            <th>Provided items</th>
                            <th>Retention and use period</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Host of Event</td>
                            <td>Event participant identification and event operation</td>
                            <td style="text-align:left">Name, e-mail, Phone Number and other items (as requested by the host)</td>
                            <td>Until 6 months after the end of the event</td>
                        </tr>
                        <tr>
                            <td>Companies participating in the event (companies that allow the collection of the QR code issued upon entry)</td>
                            <td>Company, product and event information</td>
                            <td style="text-align:left">Name, e-mail, Phone Number, Gender</td>
                            <td>Until 6 months after the end of the event</td>
                        </tr>
                        <tr>
                            <td>Association of Korea Exhibition Industry<br>(AKEI) (Exhibition events only)</td>
                            <td>Part of the international exhibition certification system operated by the Ministry of Trade, Industry and Energy</td>
                            <td style="text-align:left">Name, Gender, Age</td>
                            <td>Until 6 months after the end of the event</td>
                        </tr>
                    </tbody>
                </table>
                <p style="margin: 10;">
                    ※ The personal information collection items provided for each event are different, so you can check the details in the application form for each event.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Information provided to third parties without prior consent based on relevant laws and regulations</h4>
                <p style="margin: 10;">
                    <v-col cols="12">
                        - In cases where it is necessary for statistical writing, academic research, or market research, it is provided in a form in which a specific individual cannot be identified<br>
                        - When requested by a government agency in accordance with relevant laws and regulations<br>
                        - For criminal investigation purposes or when there is a request from the Information Communication Ethics Committee<br>
                        - If there is a request in accordance with the procedures stipulated by other relevant laws and regulations<br>
                    </v-col>
                </p>
                <br>

                <h3 style="margin: 10;color:#447ec4">Article 6 Overseas Transfer of Personal Information</h3><br>
                <p style="margin: 10;">
                    •	The company does not provide personal information to other businesses abroad.
                </p>

                <h3 style="margin: 10;color:#447ec4">Article 7 Measures to ensure safety of personal information</h3><br>
                <p style="margin: 10;">
                    The company is doing its best to safely manage users' personal information to prevent personal information from being lost, stolen, leaked, altered or damaged, and takes necessary technical, managerial and physical measures.
                </p>
                <v-col cols="12">
                <h4 style="margin: 10;color:#447ec4">•	Minimization and training of personnel handling personal information</h4>
                <p style="margin: 10;">
                    We are implementing measures to manage personal information by minimizing the number of employees handling personal information and conducting periodic personal information protection training.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Establishment and execution of internal management plan</h4>
                <p style="margin: 10;">
                    For the safe handling of personal information, an internal management plan is established and implemented.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Storage of access records and prevention of forgery</h4>
                <p style="margin: 10;">
                    Records of access to the personal information processing system (web log, summary information, etc.) are stored and managed for at least one year to facilitate response in the event of a personal information infringement incident, and security functions are in place to prevent forgery, alteration, theft, or loss of access records. I am using
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Encryption of Personal Information</h4>
                <p style="margin: 10;">
                    Users' personal information is encrypted and stored and managed.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Technical measures against hacking, etc.</h4>
                <p style="margin: 10;">
                    The company installs security programs and periodically updates and inspects them to prevent leakage and damage of personal information caused by hacking or computer viruses. In addition, the system is installed in an area where access from outside is controlled and is monitored and blocked technically/physically.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Restriction of access to personal information</h4>
                <p style="margin: 10;">
                    We are taking measures to control access to personal information by granting, changing, or canceling access rights to the personal information processing system that processes personal information.
                </p>
                </v-col>

                <h3 style="margin: 10;color:#447ec4">Article 8 Rights of users and legal representatives and how to exercise them</h3><br>
                <p style="margin: 10;">
                    In order to protect the personal information rights of users (or legal representatives), the company has prepared the following methods.
                </p>
                <v-col>
                    <h4 style="margin: 10;color:#447ec4">•	User's rights and exercise method</h4>
                    <p style="margin: 10;">
                        - View or edit: My Page > Modify basic information<br><br>
                        - Membership withdrawal or deletion request: My Page > Leave Events<br><br>
                        - In addition, you may request suspension of processing and deletion of personal information in writing or by e-mail.<br><br>
                        - When the company requests correction or deletion of personal information errors, etc., the company does not use or provide the personal information until the correction or deletion is completed.<br><br>
                        - Requests for correction and deletion of personal information cannot be requested if the personal information is specified as the subject of collection in other laws and regulations.<br><br>
                        - The company verifies that the person who made the request, such as a request for viewing, a request for correction or deletion, or a request for suspension of processing according to user rights, is the person who made the request.<br><br>
                    </p>
                    <h4 style="margin: 10;color:#447ec4">•	Legal representative's rights and exercise method</h4>
                    <p style="margin: 10;">
                        - When a user's legal representative or an agent, such as a person who has been delegated, exercises the user's rights (view, correction, suspension of processing, deletion), a power of attorney must be submitted in accordance with Form No. 11 of the Enforcement Rules of the Personal Information Protection Act.<br>
                        - The company checks whether the person who made the request, such as a request for viewing, a request for correction or deletion, or a request for suspension of processing, according to user rights, is a legitimate agent.<br>
                    </p>
                </v-col>
                
                <h3 style="margin: 10;color:#447ec4">Article 9 Remedy for personal information protection manager and infringement of user's rights and interests</h3><br>
                <p style="margin: 10;">
                    The company is responsible for overall handling of personal information, and has designated the person in charge of personal information protection as follows to handle user complaints and damage relief related to personal information processing.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Privacy Officer</th>
                            <th>Position</th>
                            <th>Contact</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>SEO YOON JEON</td>
                            <td>CEO</td>
                            <td>02-2038-0060<br>privacy@fairpass.world</td>
                        </tr>
                    </tbody>
                </table>
                <h4 style="margin: 10;color:#447ec4">•	Role of Personal Information Protection Officer</h4>
                <p style="margin: 10;">
                    Users may inquire to the person in charge of personal information protection regarding all personal information protection related inquiries, complaint handling, damage relief, etc. that occurred while using the service. The company will respond to and handle inquiries from users without delay.
                </p>
                <h4 style="margin: 10;color:#447ec4">•	Institutions that can receive help related to infringement of rights and interests</h4>
                <p style="margin: 10;">
                    If you need further assistance regarding infringement of the user's rights, please contact the following organizations.
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>Classification</th>
                            <th>Explanation</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Personal Information Infringement Report Center (operated by Korea Internet & Security Agency)</td>
                            <td style="text-align:left">
                                - Responsibilities: Report personal information infringement, apply for consultation<br>
                                - Website: privacy.kisa.or.kr<br>
                                - Phone: (without area code) 118<br>
                                - Address: (58324) 9 Jinheung-gil, Naju-si, Jeollanam-do (301-2 Bitgaram-dong) 3rd floor Personal Information Infringement Reporting Center
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Personal Information Dispute Mediation Committee</td>
                            <td style="text-align:left">
                                - Jurisdiction: personal information dispute mediation application, collective dispute mediation (civil settlement)<br>
                                - Website: www.kopico.go.kr<br>
                                - Phone: (without area code) 1833-6972<br>
                                - Address: (03171) 4th floor, Seoul Government Complex, 209, Sejong-daero, Jongno-gu, Seoul
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Supreme Prosecutor's Office Cybercrime Investigation Team </td>
                            <td style="text-align:left">- Phone: 02-3480-3573<br>- Website: www.spo.go.kr</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>National Police Agency Cyber Security Bureau</td>
                            <td style="text-align:left">- Phone: 182<br>- Website: cyberbureau.police.go.kr</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <br>
                <h3 style="margin: 10;color:#447ec4">Article 10 Matters concerning changes to the personal information processing policy</h3><br>
                <p style="margin: 10;">
                    The personal information processing policy is applied from the enforcement date, and if there is an addition, deletion, or correction of changes in accordance with relevant laws and policies, it will be notified through the homepage without delay.
                </p>

                <p style="margin: 10;">
                    Effective date 2023-05-30
                </p>

                <br><br>     
                <p style=""><input type="checkbox" v-model="checkbox2">&nbsp;&nbsp;I agree with the above.</p>

            </v-card-text>
            
          </template>
            <v-card-actions>
                <v-row justify="center" class="mt-5 mb-5" dense no-gutters>
                    <v-col cols="6">
                        <button class="bottombtn_pc2" @click="dialog1 = false">{{$t('common_msg4')}}</button>
                    </v-col>
                    <v-col cols="6">
                        <button class="bottombtn_pc1" @click="agree1">{{$t('common_msg3')}}</button>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog2"
        width="700" :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        >

        <v-card>

        <template v-if="lan_code == 0">
          <v-card-text>
            <br>
            <h2 style="text-align: center;">마케팅정보 수신동의</h2>
            <br>
            <h3 style="margin: 10;color:#447ec4">페어패스의 광고성정보 수신동의 서비스를 위한 개인정보 수집 및 이용에 관한 동의서</h3><br>
            <p style="margin: 10;">
                회사는 수집한 개인정보를 관리함에 있어서 개인정보보호법 제30조에 의거 이용자의 개인정보보호와 권익을 보호하고 책임과 의무를 준수하고 있습니다. 개인정보 수집 및 이용과 관련하여 아래와 같은 사항을 안내해 드리오니 내용을 숙지하신 후 해당 내용에 동의하여 주시기 바랍니다.<br><br>
                •	본 행사와 유사한 행사(전시/박람회, 컨퍼런스, 이벤트) 등의 행사 개최정보 안내<br>
                •	유사 행사의 무료 초청장 수신<br>
                •	행사 참여 후 만족도 조사 설문링크 및 이벤트 참여링크 수신.<br><br>
                동의를 원하지 않으실 경우, 거부할 권리가 있으며 수신동의 거부 시 광고 및 이벤트 안내, 초청장 수신 등의 서비스를 제공받으실 수 없습니다.<br><br>
            </p>
            <br>
            <br>     
            <p style=""><input type="checkbox" v-model="checkbox3">&nbsp;&nbsp;위 내용에 동의합니다.</p>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-text>
            <br>
            <h2 style="text-align: center;">Consent to Receive Marketing Information</h2>
            <br>
            <h3 style="margin: 10;color:#447ec4">Consent Form for the Collection and Use of Personal Information for FairPass Advertising Information Subscription Service</h3><br>
            <p style="margin: 10;">
                The company, in managing collected personal information, complies with Article 30 of the Personal Information Protection Act to protect the privacy and rights of users and fulfills responsibilities and obligations. Regarding the collection and use of personal information, we provide the following information for your guidance. Please review the details and consent to the terms after understanding the content.<br><br>
                •	Information on Events Similar to This Event (Exhibitions/Conferences/Events)<br>
                •	Reception of Free Invitations for Similar Events<br>
                •	Receipt of Satisfaction Survey Link and Event Participation Link After Event Attendance.<br><br>
                If you do not wish to consent, you have the right to refuse, and refusal to consent may result in the inability to receive services such as advertising, event notifications, and invitation receipt.<br><br>
            </p>
            <br>
            <br>     
            <p style=""><input type="checkbox" v-model="checkbox3">&nbsp;&nbsp;I agree to the above terms.</p>
          </v-card-text>
        </template>

          <v-card-actions>
            <v-row justify="center" class="mt-5 mb-5" dense no-gutters>
                <v-col cols="6">
                    <button class="bottombtn_pc2" @click="dialog2 = false">{{$t('common_msg4')}}</button>
                </v-col>
                <v-col cols="6">
                    <button class="bottombtn_pc1" @click="agree2">{{$t('common_msg3')}}</button>
                </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>    


    <div class="d-flex justify-center" 
         :style="$vuetify.breakpoint.smAndDown ? 'margin-top:30px' : 'margin-top:100px'">
        <div style="width:700px">

            <div class="blue_text">{{$t('agree_label1')}}</div>

            <div class="gray_text mt-5 mb-5">
                {{$t('agree_label2')}}
            </div>

            <table style="width:100%;border-collapse: collapse;">

                <tr v-if="showmkt">
                    <td rowspan="2" width="15%" style="text-align: center !important;">
                        <input class="join_check" type="checkbox" v-model="checkbox1" @change="change1">
                    </td>
                    <td colspan="2" class="tdtile_text pa-md-8 pa-3">
                        {{$t('agree_label3')}}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="pa-md-8 pa-3">
                        <div class="tdtile_text">{{$t('agree_label13')}}</div><br>
                        <div class="desc_text">{{$t('agree_label4')}}</div>
                    </td>
                </tr>
                
                <tr>
                    <td width="15%" style="text-align: center !important;">
                        <input class="join_check" type="checkbox" v-model="checkbox2">
                    </td>
                    <td class="tdtile_text pa-md-8 pa-3">
                        {{$t('agree_label5')}}
                    </td>
                    <td :width="$vuetify.breakpoint.smAndDown ? '20%' : '15%'">
                        <div class="btncursor show_text text-center" @click="dialog1 = true">
                            {{$t('agree_label6')}}
                        </div>
                    </td>
                </tr>
    
                <tr v-if="showmkt">
                    <td width="15%" style="text-align: center !important;">
                        <input class="join_check" type="checkbox" v-model="checkbox3">
                    </td>
                    <td class="tdtile_text  pa-md-8 pa-3">
                        {{$t('agree_label7')}}
                    </td>
                    <td :width="$vuetify.breakpoint.smAndDown ? '20%' : '15%'">
                        <div class="btncursor show_text text-center" @click="dialog2 = true">
                            {{$t('agree_label6')}}
                        </div>
                    </td>
                </tr>
            </table>
            <button class="bottombtn_pc1" @click="agreeall">{{$t('common_msg5')}}</button>

        </div>
    </div>
    
  

  </v-container>
</template>

<script>
export default {

  data: () => ({
        EVENTCODE:"",
        OPTIONCODE:"",
        LOCALCODE:"",
        NAME:"",

        checkbox1:false,
        checkbox2:false,
        checkbox3:false,
        dialog1:false,
        dialog2:false,

        showmkt:true,
        use_mkt:1,
        lan_code:0, //한국어
  }),

  mounted(){  
    window.scrollTo(0,0);
    //path를 통해서 들어올 경우 ex) /경로/값
    //console.log("get code " + JSON.stringify(this.$route.query));
    this.EVENTCODE  = this.$route.query.eventcode;

    this.OPTIONCODE = this.$route.query.optioncode;
    this.LOCALCODE  = this.$route.query.localcode;
    this.NAME       = this.$route.query.name;

    if(this.$route.query.use_mkt != null && this.$route.query.use_mkt != undefined){
      this.use_mkt = Number(this.$route.query.use_mkt);
    }else{
      this.use_mkt = 1;
    }
    if(this.use_mkt == 0){
        this.showmkt = false;
        this.checkbox3 = true;
    }

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
        this.$i18n.locale = 'ko';
    }else{
        this.$i18n.locale = 'en';
    }
    
  },

  methods: {

    change1(e){
        if(e.target.checked == true){
            this.checkbox2 = true;
            if(this.showmkt) {
                this.checkbox3 = true;
            }
        }else{
            this.checkbox2 = false;
            if(this.showmkt){
                this.checkbox3 = false;
            }
        }
    },

    agree1(){
        if(this.checkbox2 == false){
            this.$alert(this.$t('agree_label9'));
            return;
        }
        this.dialog1 = false;
    },

    agree2(){
        if(this.checkbox3 == false){
            this.$alert(this.$t('agree_label10'));
            return;
        }
        this.dialog2 = false;
    },

    agreeall(){

        if(this.checkbox2 == false){
            this.$alert(this.$t('agree_label11'));
            return;
        }
        
        /*
        if(this.checkbox3 == false){
            this.$alert(this.$t('agree_label12'));
            return;
        }
        */

        this.$router.push({path:"/RegJoin", query:{eventcode:this.EVENTCODE, localcode:this.LOCALCODE, optioncode:this.OPTIONCODE, name:this.NAME, lan_code:this.lan_code, private:this.checkbox2, mkt:this.checkbox3}});


    }
    
  },


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.bottombtn_pc1{
    width:100%; height:58px;
    font-size:16px; 
    font-family: 'NotoSansKR-Regular' !important;
    text-align: center !important;
    color: #fff !important;
    border-radius: 0px !important;
    background-color: #627BFF !important;
  }
  .btncursor{
    cursor: pointer;
  }

  .show_text{
    font-size: 13px;
    font-family: 'NotoSansKR-Regular' !important;
    color: #858585; 
  }

  .tdtile_text{
    font-size: 14px;
    font-family: 'NotoSansKR-Medium' !important;
    color: #515151; 
  }

  .desc_text{
    font-size: 12px;
    font-family: 'NotoSansKR-Regular' !important;
    color: #515151; 
  }

  h2{
    font-size: 16px;
    font-family: 'NotoSansKR-Medium' !important;
    color: #3D3D3D; 
  }
  h3{
    font-size: 14px;
    font-family: 'NotoSansKR-Medium' !important;
  }

    p{
        font-size: 14px;
        font-family: 'NotoSansKR-Regular' !important;
        color:#3D3D3D;
    }

label{
    color:#ffffff;
}

table{
    border-radius: 0px;
    border-collapse: separate; 
    border-spacing:0;
    border: 0px solid #DADADA;
}
  
td {
    font-size: 14px;
    font-family: 'NotoSansKR-Regular' !important;
    color:3D3D3D;
    border: 1px solid #DADADA;
}
thead {
    background-color: #ffffff;
}
tbody {
    background-color: #ffffff;
}

.blue_text{
    font-size:14px;
    color:#627BFF;
    font-family: 'NotoSansKR-Medium' !important;
}

.gray_text{
    font-size:14px;
    color:#3D3D3D;
    font-family: 'NotoSansKR-Regular' !important;
}


/* 체크박스 */
.join_check{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 27px; /* 체크박스의 너비 */
    height: 27px; /* 체크박스의 높이 */
    border-radius: 50px; /* 체크박스의 테두리 둥글기 */
    border: 1px solid #627BFF;
    outline: none; /* 포커스시 윤곽선 제거 */
    cursor: pointer; /* 커서 모양 설정 */  
  }
  .join_check:checked{
    background-color: #627BFF; /* 체크된 상태일 때의 배경색 */
    border-color: #627BFF; /* 체크된 상태일 때의 테두리 색 */
  }
  .join_check::before{
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 20px; /* 체크박스의 높이와 동일하게 설정 */
    color: #fff; /* 체크 아이콘의 색상 */
    font-size: 16px; /* 체크 아이콘의 크기 */
  }

  .bottombtn_pc1{
    width:100%; height:58px;
    font-size:16px; 
    font-family: 'NotoSansKR-Regular' !important;
    text-align: center !important;
    color: #fff !important;
    border-radius: 0px !important;
    background-color: #627BFF !important;
  }

  
  .bottombtn_pc2{
    width:100%; height:58px;
    font-size:16px;
    font-family: 'NotoSansKR-Medium' !important;
    text-align: center !important;
    color: #525252 !important;
    border-radius: 0px !important;
    background-color: #E8E8E8 !important;
  }
</style>