<template>
  <!-- 참여기업 방문객 확인 페이지 -->
  <v-container fluid>

    <!-- 관람객 정보 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog_user" max-width="500px">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11" sm="11" xs="11">
              <span class="dialog_title">{{$t('appeventcomout_label1')}}</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn class="dialog_close_btn mr-1" outlined rounded fab x-small @click="dialog_user = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              <v-row dense> 
                <v-text-field v-model="visitorItem.COM_NAME" :label="$t('appeventcomout_label2')" disabled></v-text-field>
              </v-row>
              <v-row dense> 
                <v-text-field v-model="visitorItem.EVENT_NAME" :label="$t('appeventcomout_label3')" disabled></v-text-field>
              </v-row>
              <v-row dense>
                  <v-text-field v-model="visitorItem.OPTION_NAME" :label="$t('appeventcomout_label4')" disabled></v-text-field>
              </v-row>

              <!-- 행사별 커스텀 기본정보 -->              
              <template v-for="item in infoItem">
                <template v-if="item.USETYPE == 1">
                  <template v-if="item.LANTYPE == 4">
                    <v-row dense :key="item">
                      <label style="color:rgba(0,0,0,0.38)">{{item.NAME}}</label>
                    </v-row>
                    <template v-if="visitorItem['INFO'+item.INPUT_CODE] != null && visitorItem['INFO'+item.INPUT_CODE] != undefined && visitorItem['INFO'+item.INPUT_CODE].length > 1">
                      <v-row dense :key="item.INPUT_CODE" justify="center">
                        <img  :height="item.HEIGHT" :width="item.WIDTH" :src="visitorItem['INFO'+item.INPUT_CODE]" class="image"/>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row dense :key="item.INPUT_CODE" justify="center">
                        <label style="color:rgba(0,0,0,0.38)">{{$t('appeventcomout_label5')}}</label>
                      </v-row>
                    </template>
                  </template>

                  <template v-else-if="item.LANTYPE == 5">
                    <v-row dense :key="item.INPUT_CODE">
                      <label style="color:rgba(0,0,0,0.38)">{{item.NAME}}</label>
                    </v-row>
                    <template v-if="visitorItem['INFO'+item.INPUT_CODE] != null && visitorItem['INFO'+item.INPUT_CODE] != undefined && visitorItem['INFO'+item.INPUT_CODE].length > 1">
                      <v-row dense :key="item.INPUT_CODE" justify="center">
                        <a :href="visitorItem['INFO'+item.INPUT_CODE]" download target="_blink">File Download</a>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row dense :key="item.INPUT_CODE" justify="center">
                        <label style="color:rgba(0,0,0,0.38)">{{$t('appeventcomout_label22')}}</label>
                      </v-row>
                    </template>
                  </template>

                  <template v-else>
                    <template v-if="item.MAXLINES == 1">
                      <v-row dense  :key="item.INPUT_CODE">
                        <v-textarea dense no-resize outlined height="70"
                        v-if="item.USETYPE == 1" v-model="visitorItem['INFO'+item.INPUT_CODE]" :label="item.NAME" disabled></v-textarea>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row dense  :key="item.INPUT_CODE">
                        <v-text-field v-if="item.USETYPE == 1" v-model="visitorItem['INFO'+item.INPUT_CODE]" :label="item.NAME" disabled></v-text-field>
                      </v-row>
                    </template>
                  </template>
                </template>
              </template>

              <v-row dense v-if="useInfo.USETEL == 1">
                  <v-text-field v-model="visitorItem.TEL" :label="$t('appeventcomout_label6')" disabled></v-text-field>
              </v-row>
              <v-row dense v-if="useInfo.USEMAIL == 1">
                  <v-text-field v-model="visitorItem.EMAIL" :label="$t('appeventcomout_label7')" disabled></v-text-field>
              </v-row>

              <v-row dense v-if="useInfo.USEPW == 1">
                  <v-text-field v-model="visitorItem.PRINT_NUMBER" :label="$t('appeventcomout_label8')" disabled></v-text-field>
              </v-row>
              <v-row dense v-if="useInfo.USESEX == 1">
                  <v-text-field v-model="visitorItem.SEX_NAME" :label="$t('appeventcomout_label9')" disabled></v-text-field>
              </v-row>
              <v-row dense v-if="useInfo.USEYEAR == 1">
                  <v-text-field v-model="visitorItem.YEAR" :label="$t('appeventcomout_label10')" counter="4" :suffix="$t('appeventcomout_label11')" disabled></v-text-field>
              </v-row>
              <v-row dense v-if="useInfo.USENATION == 1">
                  <v-text-field v-model="visitorItem.NAMEKOR" :label="$t('appeventcomout_label12')" disabled></v-text-field>
              </v-row>
              <v-row dense>
                  <v-text-field v-model="visitorItem.STATE_NAME" :label="$t('appeventcomout_label13')" disabled></v-text-field>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
            <v-btn class="dialog_cancel_btn" rounded large @click="dialog_user = false">{{$t('common_msg4')}}</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row align="center">
      <v-col cols="12" style="background:#F1F1F1">
        <label class="menunamestyle">{{$t('appeventcomout_label14')}}</label>
      </v-col>
    </v-row>

    <v-row align="center" class="mt-5">
      <v-spacer/>
      <v-col cols="5" md="2" sm="6" xs="6" class="pa-0">
        <v-menu
          v-model="date1"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
          >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="search_start_date"
              :label="$t('appeventcomout_label15')"
              prepend-icon="mdi-calendar"
              readonly outlined dense hide-details
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="search_start_date" @input="date1 = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
        </v-menu>      
      </v-col>
      <v-col cols="5" md="2" sm="6" xs="6" class="pa-0 mr-1 ml-1">
        <v-menu
          v-model="date2"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
          >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="search_end_date"
              :label="$t('appeventcomout_label16')"
              prepend-icon="mdi-calendar"
              readonly outlined dense hide-details
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="search_end_date" @input="date2=false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
        </v-menu>                    
      </v-col>
    
      <v-col cols="12" align="end" class="d-flex hidden-md-and-up">
        <label class="total_label">{{$t('common_msg6')}}</label><label class="total_label">{{totalcnt}}</label>
        <v-spacer/>
        <v-btn style="height:30px;width:70px;background-color:#e5e5e5;border: thin solid #d1d1d1" elevation="0" outlined large @click="search_run">{{$t('common_msg8')}}</v-btn>
        <v-btn style="height:30px;width:70px;margin-left:5px" color="#4e6ef7" large rounded  @click="startDownload()"><v-icon color="white">mdi-cloud-download</v-icon></v-btn>
      </v-col>

      
    </v-row>


  <v-row align="start" >
    <v-col>
      <!-- 테이블 -->
      <v-data-table
        :headers="headers"
        :items="itemList"
        :no-data-text="$t('common_msg30')"
        hide-default-footer
        class="datatablestyle"
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        >
        <template v-slot:header.PLACENAME>
          <a :class="sort_name=='PLACENAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('PLACENAME')">{{$t('pecomout_label3')}}</a>
        </template>
        <template v-slot:header.NAME>
          <a :class="sort_name=='NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('NAME')">{{$t('pecomout_label4')}}</a>
        </template>
        <template v-slot:header.STATE_NAME>
          <a :class="sort_name=='STATE_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('STATE_NAME')">{{$t('pecomout_label5')}}</a>
        </template>
        <template v-slot:header.USER_TYPENAME>
          <a :class="sort_name=='USER_TYPENAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('USER_TYPENAME')">{{$t('pecomout_label6')}}</a>
        </template>
        <template v-slot:header.TEL>
          <a :class="sort_name=='TEL'?'cheader_sortselect':'cheader_sort'" @click="customsort('TEL')">{{$t('pecomout_label7')}}</a>
        </template>
        <template v-slot:header.SHOWDATE>
          <a :class="sort_name=='SHOWDATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('SHOWDATE')">{{$t('pecomout_label8')}}</a>
        </template>
        <template v-slot:header.INTIME>
          <label class="cheader_normal" >{{$t("pecomout_label9")}}</label>
        </template>
        <template v-slot:header.OUTTIME>
          <label class="cheader_normal" >{{$t("pecomout_label10")}}</label>
        </template>
        <template v-slot:header.SHOWMIN>
          <label class="cheader_normal" >{{$t("pecomout_label11")}}</label>
        </template>

        <template v-slot:item.NAME="{ item }">  
          <a @click="loadvisitor(item.VISITOR_IDX)" >{{item.NAME}}</a>
        </template>

      </v-data-table>
    </v-col>
  </v-row>


  <!-- 하단 페이징 영역 -->
  <v-row>
    <v-col cols="12" md="12" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10" style="font-size:16px" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn style="font-size:16px" color="red" icon small @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn class="font-size:16px" icon small @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10" style="font-size:16px" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
  </v-row>

  </v-container>
</template>
<script>

import moment from 'moment'
import XLSX from 'xlsx'

export default {

  beforeDestroy(){

  },

  mounted(){
    this.search_start_date = moment().subtract(7, 'DAY').format("YYYY-MM-DD");
    this.search_end_date   = moment().format("YYYY-MM-DD");

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    this.initpage();
  },

  data: () => ({
    useInfo:{},
    //필수 페이징 파라메터
    totalcnt : 0,       //전체 아이템 개수
    page : 0,
    maxpage : 0,        //전체 페이지 개수
    startpage : 1,      //시작 인덱스
    bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
    subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
    loading : false,    //로딩 표시

    //테이블 정의
    headers: [
      {text: '위치', value: 'PLACENAME', sortable: false, align:'center' },
      {text: '이름', value: 'NAME', sortable: false, align:'center'},
      {text: '상태', value: 'STATE_NAME', sortable: false, align:'center'},
      {text: '회원', value: 'USER_TYPENAME', sortable: false, align:'center'},
      {text: '전화번호', value: 'TEL', sortable: false, align:'center' },
      {text: '관람일', value: 'SHOWDATE', sortable: false, align:'center' },
      {text: '입장시간', value: 'INTIME', sortable: false, align:'center' },
      {text: '퇴장시간', value: 'OUTTIME', sortable: false, align:'center' },
      {text: '관람시간(분)', value: 'SHOWMIN', sortable: false, align:'center' },
    ],
    itemList: [],

    sort_name: 'SHOWDATE',
    sort_type: true,    //true : DESC, false: ASC

    search_keyword : '',
    search: '',         //필터링용 검색어

    //한 페이지 ROW 개수 설정
    pagerow : 20,       //한 페이지에 보여줄 row수
    pagerow_combo : [
        {NAME:'20', VALUE:20}
      , {NAME:'50', VALUE:50}
      , {NAME:'100', VALUE:100}
      , {NAME:'200', VALUE:200}
    ],    


    date1:false,
    date2:false,
    search_start_date : '',
    search_end_date : '',
    
    list:[],

    SEARCH_COM_IDX: null,
    SEARCH_COM_NAME: '',
    SEARCH_EVENT_IDX: null,
    SEARCH_EVENT_NAME: '',
    SEARCH_PLACE_IDX: null,
    SEARCH_PLACE_NAME: '',
    grade : 0,

    comoutItem:[
        {name:'전체',   value:0}
      , {name:'입장',   value:1}
      , {name:'퇴장',   value:2}
    ],
    COMOUT_TYPE:0,

    dialog_user:false,    
    visitorItem: {},
    infoItem:[],    //행사별 기본접수양식 커스텀 정보 
    lan_code:0,
  }),

  methods: {

    changecomouttype(cl){
      this.COMOUT_TYPE = cl;
    },

    insertAt(array, index, item) {
      array.splice(index, 0, item);
    },

    startDownload(){

        this.$confirm(this.$t('appeventcomout_label17')).then(() => {

          if(this.search_start_date.length < 4){
            this.$alert(this.$t('appeventcomout_label18'));
            return;
          }
          if(this.search_end_date.length < 4){
            this.$alert(this.$t('appeventcomout_label19'));
            return;
          }
          if(this.search_start_date > this.search_end_date){
            this.$alert(this.$t('appeventcomout_label20'));
            return;
          }        
          
          
          this.loading = true;
          this.$http.post(this.$host+'/part/VisitorComoutExcel',{
              search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
            , sort_name:this.sort_name
            , sort_type: this.sort_type==true ? "DESC" : "ASC" 
            , COMOUT_TYPE: 0
            , LAN_CODE: this.$i18n.locale
            , search_start_date:this.search_start_date == undefined ? '' : this.search_start_date
            , search_end_date:this.search_end_date == undefined ? '' : this.search_end_date
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            
            this.loading = false;
            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                
                const list = result.data.resultData;
                var item_sheet = XLSX.utils.json_to_sheet(list);
                
                //컬럼별 넓이
                var wscols = [{wch:30},{wch:20},{wch:10},{wch:10},{wch:10},{wch:20,},{wch:20},{wch:15},{wch:15},{wch:10}];
                item_sheet['!cols'] = wscols;

                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, item_sheet, this.$t('pecomout_label2'));
                XLSX.writeFile(wb, this.$t('pecomout_label2')+'_'+Date.now().toString()+'.xlsx');


            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert(this.$t('common_msg9'));
              this.$router.back();
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert(this.$t('common_msg1'));
            }else{
              this.$alert(error.message);
            }
          });

        });
    },

    initpage(){
      //this.loadpage(1);
    },


    loadvisitor(vidx){

        this.$http.post(this.$host+'/part/VisitorDetail',{
            VISITOR_IDX: vidx
          , LAN_CODE:this.$i18n.locale
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              const list = result.data.resultData;
              this.visitorItem = list[0];
              this.dialog_user = true;

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert(this.$t('common_msg9'));
            this.$router.back();
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
        });

    },              

    loadpage(p){
      if(this.search_start_date.length < 4){
        this.$alert(this.$t('appeventcomout_label18'));
        return;
      }
      if(this.search_end_date.length < 4){
        this.$alert(this.$t('appeventcomout_label19'));
        return;
      }
      if(this.search_start_date > this.search_end_date){
        this.$alert(this.$t('appeventcomout_label20'));
        return;
      }        
      
      this.itemList.splice(0, this.itemList.length);   //데이터 초기화
      this.loading = true;
      this.page = p;

      this.$http.post(this.$host+'/part/VisitorComoutList',{
            search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
          , sort_name:this.sort_name
          , sort_type: this.sort_type==true ? "DESC" : "ASC" 
          , page: this.page
          , pagerow: this.pagerow
          , COMOUT_TYPE: 0
          , LAN_CODE: this.$i18n.locale
          , search_start_date:this.search_start_date == undefined ? '' : this.search_start_date
          , search_end_date:this.search_end_date == undefined ? '' : this.search_end_date
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{
        
        if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            this.totalcnt = result.data.totalcnt;
            this.makepaging();
            const list = result.data.resultData;
            list.forEach(element => {
                this.itemList.push(element); 
            });

            this.loading = false;

        }else if(result.data.resultCode == 2){
          //로그인 필요
          this.loading = false;
          this.$alert(this.$t('common_msg10'));
          this.$router.back();
        }else{
          this.loading = false;
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert(this.$t('common_msg1'));
        }else{
          this.$alert(error.message);
        }
      });

    },    

    search_run(){

      this.loadpage(1);

    },

    customsort(sortname){
      this.sort_name = sortname;
      this.sort_type = !this.sort_type;
      this.loadpage(this.page);
    },

    prev(){
        this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
        this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
        this.loadpage(temp_page);
    },
    next(){
        this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
        this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        var temp_page = this.bottompage;
        this.loadpage(temp_page);
    },

    makepaging(){
      var div1 = this.totalcnt / this.pagerow; 
      var div2 = this.totalcnt % this.pagerow;
      if(div2 == 0){  
          this.maxpage = parseInt(div1);    
      }else{
          this.maxpage = parseInt(div1+1);
      }
      if(this.bottompage == 1){
        this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
      }
    },


  }
    
}

</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

  .search_btn_freesize {
    color: #2f2f2f !important;
    background-color: #e5e5e5 !important;
    border: thin solid #d1d1d1 !important;
    font-size: 16px !important;
    height: 43px !important;
  }

  .dialog_close_btn {
    color: #e5e5e5 !important;
    background-color: #ffffff !important;
    border-color:#e5e5e5 !important; 
    border-style:solid !important; 
    border-width:3px 3px 3px 3px !important;
  }


</style>