<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <rect opacity="0.8" width="21" height="21" fill="url(#pattern0_395_89)"/>
        <defs>
        <pattern id="pattern0_395_89" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image0_395_89" transform="scale(0.0078125)"/>
        </pattern>
        <image id="image0_395_89" width="128" height="128" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE90lEQVR4nO2d26umcxTHP9swaDaaCUUoyqG4YGq4MQgpp2JShuSQppjklOx/YTvkArlwaAxyQ8rhBlO4cChcOGQUk0MImZDTYHj01k9205j9rue01rOe76d+V7Pf2d9nrfWu5/mt7+99NwghhBBCCCGEEEIIIUR2Di5LjJDDgY+AT4AjvcWIfjkW+AKoyvoaOF5JGAcnAt8tSH5V1vfAyd7iRLecDvy0k+RXZf0CnK0k5OQCYNsukl+V9TtwsbdY0S7XAH9NkfyqrL+BG5SEHMyVhFY11ry3eFGfGeD2momvFqy7gd2UiGGxBHigheRXZT0C7O59UWI6lgJPtJj8qqyngb2UhNjMApsMSX2/rGl/flP5HSIgy4HXDMl8E9i/vO7VGq8TgTgIeMeQxJeAfRa8fhnwvOH1HwCHOF6v2MHU+biFe/lS47PDp8BRyoS/qfNli0/zS4y7B5lIAU2dpvv5GeP8QCaSA2csYuq0MdGbM/z/MpF6ZC3wx5SJmYyAb+zJQ/gTuKLF6xRBErK2x4ITLbXkbcX+bYtzSpvv8pYjWnoo+6Gjkz0nAVsNOu6RidQc67bsG+AEuuM447bzUZlI9Yk6mDnCOHh6Bti7B12psI5mNwOH9jx6ftc4et63R32jMnXeAg5w0LkCeH0AOgeF9Z31svM7axZ4IXCnSm3qPBvk3ron8GTAZ5VBYX26fgzYg1i7lYeMJlKXu5VBYd1f3xt0fz0D3GmcV6xm5JybcMI2Z5xYXshIucQ4Y7+Z4bDe6Flcyci41hCg7cBVDI9LZSK10yLXMFzOA35Ndotr9JB0hyEYkwMfZzJ8TikPfKM2kSbbpAcNQdhadgdZWAl8azSRIm1zex2UfFXmAtk4Bvh8bCbSMuOodEtx27JyGPDhgEbdvZolbwMHkp8VwBvZTSSrqfMKsB/jYRZ4MauJZD0w8VyGe13NZ6OnDHH6DDiaZKbO45medmvujjYEOvLWiNXG/e59Gfe7NecjdxnnI5MPxoQzdTTxGqmJZJ153+ItODDXDc0jWW8UfLW34AFwWXEIp31D3TSEljX58sWLvIQOkPOB36KaSNaTLz8DZ/UpMAmnAT9GOyk1+XKFjQZRk7Wqa1GJWWWM9cYuv87Oaur8u0QzrPHu5LS09fy7CqA96sS8VRPJauqoANqlbtxbMZEmf0/nvQYidAtoTpPYby4WdG1TZ0tDASqA5jSNfy0TaWUxHqoBFEAbGqvk12c+YremTO7GEqAq+fVtr3O6+vIp/tDCfJIAVQMugMUmspMcrqv7y6+f4pM60QOUvQAW82RupSG3LeJCRQ/QGArg/1zZ+bZm//fv4pM60QM0lgLY8VzGwyV3rR5dOnUn/xY9QGMqAEqONpSc9UL0AI2tAHrHW2DXv79Kfn2DF6gCcEYFkDu+4QWqAzijAsgd3/AC1QGcUQHkjm94geoAzqgAcsc3vEB1AGdUALnjG16gOoAzKoDc8Q0vUB3AGRVA7viGF6gO4IwKIHd8wwtUB3BGBZA7vuEFqgM4owLIHd/wAtUBnFEB5I5veIHqAM6oAHLHN7xAdQBnVAC54xteoDqAMyqA3PENL1AdwBkVQO74hheoDuBMFXzp+jrGO8EqAGe8E6wCcMY7wSoAZ7wTrAJwxjvBKgBnvBOsAnDGO8EqACGEEEIIIYQQQgghhBBCCCGEEEII/uMfwMzUicWO7AwAAAAASUVORK5CYII="/>
        </defs>
        </svg>
        
</template>
    
<script>
    export default {
        
    }
</script>