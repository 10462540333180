<template>
  <v-container fluid>
    
    <v-row class="mt-5"></v-row>   
    <v-row justify="center">
        <v-img
            :aspect-ratio="16/9"
            :src="info.IMAGE"
            max-width="800"
            >
        </v-img>
    </v-row>   

    <v-row class="pt-10" style="background:#4e6ef7;" align="center" justify="center">        
        <label style="font-size:15px;color:#ffffff">{{$t('invitresult_label1')}}</label>
    </v-row>
    <v-row class="pt-5" style="background:#4e6ef7;" align="center" justify="center">        
      <v-col cols="11">
        <p style="font-size:15px;color:#ffffff">{{$t('invitresult_label2')}}<br><br>{{$t('invitresult_label3')}}</p>
      </v-col>
    </v-row>
    <v-row style="background:#4e6ef7" align="center" justify="center" class="pt-5">
        <v-col cols="12" md="12" sm="12" xs="12" align="center">
          <v-btn width="50%" large rounded color="white" @click="runinvit">{{$t('invitresult_label4')}}</v-btn>
        </v-col>
    </v-row>   
    <v-row style="background:#4e6ef7;height:500px" align="center" justify="center">
    </v-row>   

  </v-container>
</template>

<script>
export default {

  data: () => ({
    INVITCODE:"",
    info:{},
    lan_code:0, //한국어
  }),

  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    window.scrollTo(0,0);
    
    //console.log("get code " + JSON.stringify(this.$route.query.code));
    this.INVITCODE = this.$route.query.code;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    this.loadpage();
  },

  methods: {

    runinvit(){
      this.$router.push({path:"CheckInvit", query:{code:this.INVITCODE, lan_code:this.lan_code}});
    },

    loadpage(){
      //초청장 정보 가져오기
        this.$http.post(this.$host+'/ime/InvitCodeInfo',{
              INVITCODE:this.INVITCODE
            , ADMIN_IDX:1
            , LAN_CODE:this.$i18n.locale
        })
        .then((result)=>{
            if(result.data.resultCode == 0){
                this.CODEVAL = 1;   //일단 초청장은 유효한 상태
                this.info = result.data.resultData[0];
                //console.log("info : " + JSON.stringify(result.data.resultData[0]));
            }else if(result.data.resultCode == 8){
              this.$alert(this.$t('invitresult_label5'));
            }else if(result.data.resultCode == 9){
              this.$alert(this.$t('invitresult_label6'));
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
                this.$alert(this.$t('common_msg1'));
            }else{
                this.$alert(error.message);
            }
        });      

    },

  },

}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>