<!-- 이름/전화번호를 입력하고 입장권 확인시 -->
<template>
  <v-container fluid style="max-width:800px">

  <v-row justify="center">
    <v-col cols="12" md="7" align="center" class="pa-0" >
            <v-img
                :aspect-ratio="16/9"
                :src="EVENT_IMAGE"
                style="border-radius: 0px;"
                class="ma-0 ma-md-2 pa-0"
                >
            </v-img>
    </v-col>
  </v-row>

  <v-row  align="center" justify="center" class="mt-6 mb-3">        
    <v-col cols="12" align="center">
      <label style="font-weight:bold;font-family: 'AppleSDGothicNeoM', sans-serif;font-size:18px;color:#000000">{{ NAME }}</label>
    </v-col>
    <v-col cols="12" align="center" class="pa-0">
      <label style="font-family: 'AppleSDGothicNeoM', sans-serif;font-size:18px;color:#000000">{{$t('runqrticket_label1')}}</label>
    </v-col>
  </v-row>

  <!-- 
    <v-row  align="center" justify="center" class="mt-10">        
      <v-col cols="12" align="center">
        <label style="font-size:20px;color:#4e6ef7;font-weight:bold">{{EVENT_NAME}}</label>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="pa-0 ma-0 mt-5">        
      <v-col cols="11" style="text-align:center;">
        <label style="font-size:15px;color:#808080">{{ lan_code == 0 ? '입장권 발급 키오스크에 휴대폰번호를 입력하거나 QR코드를 이용해서 입장권을 출력하세요' : 'Enter your mobile phone number in the ticket issuance kiosk or print your ticket using the QR code'}}</label>
      </v-col>        
    </v-row>
    <v-row v-if="TEL.length > 0" class="pa-0 ma-0" align="center" justify="center">        
      <v-col cols="12" align="center">
        <label style="font-size:18px;color:#101010;font-weight:bold">{{ lan_code == 0 ? '휴대폰번호 : ' : 'Mobile : ' }}{{TEL}}</label>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0" align="center" justify="center">        
      <v-col cols="12" align="center">
        <label style="font-size:20px;color:#101010">{{ lan_code == 0 ? 'QR코드를 읽혀주세요' : 'Please read the QR code' }}</label>
      </v-col>
    </v-row>
  -->

    <v-row dense no-gutters class="pa-0 ma-0" style="text-align:center" justify="center">
      <v-col cols="10" md="4" class="qr_round">
        <canvas id="canvas"></canvas>
        <label v-if="USE_PASSWORD == 1" class="pw_round" style="display:block">{{ PRINT_NUMBER.length > 0 ? $t('runqrticket_label2')+PRINT_NUMBER : ''}}</label>
      </v-col>
    </v-row>   

    <v-row align="center" justify="center" class="mt-10">
      <v-col cols="12" md="7" align="center" class="pa-0">
        <div class="warning-message">{{$t('runqrticket_label3')}}</div>
      </v-col>
    </v-row>   

    <v-row align="center" justify="center" class="mt-10">
      <v-col cols="12" xs="12" sm="12" md="8" align="center">
        <v-btn @click="back" color="white" width="40%" >{{$t('runqrticket_label4')}}</v-btn>
      </v-col>
    </v-row>   

  </v-container>
</template>

<script>
import QRCode from 'qrcode'
export default {

  data: () => ({

    PRINT_QR:"",
    PRINT_NUMBER:"",
    TEL:"",
    EVENT_NAME:"",
    EVENT_IMAGE:"",
    NAME:"",
    USE_PASSWORD:0,

    EVENTCODE:"",
    VISITORCODE:"",
    lan_code:0, //한국어

  }),

  mounted(){  

    window.scrollTo(0,0);

    //path를 통해서 들어올 경우 ex) /경로/값
    //console.log("get code222 " + JSON.stringify(this.$route.query));
    this.EVENTCODE      = this.$route.query.eventcode;
    this.VISITORCODE    = this.$route.query.visitorcode;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    this.checkinvit();

  },

  methods: {
    
    back(){
      this.$router.back();
    },

    checkinvit(){

      this.$http.post(this.$host+'/web/RunQRticket',{
            EVENT_IDX:this.EVENTCODE
          , VISITOR_IDX:this.VISITORCODE
          , LAN_CODE:this.$i18n.locale
          , ADMIN_IDX:1
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{
        if(result.data.resultCode == 0){

          //토큰 저장
          if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
            this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
          }
          if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
            this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
          }

          //출력정보
          this.checkdata = result.data.resultData[0];
          //console.log("checkdata : " + JSON.stringify(this.checkdata));

          if(this.checkdata.PRINT_QR == null || this.checkdata.EVENT_NAME == null || this.checkdata.TEL == null){
            this.$alert(this.$t('runqrticket_label5'));
            return;
          }

          if(this.checkdata.COST_TYPE == 1){
            if(this.checkdata.STATE == 1){
              this.$alert(this.$t('runqrticket_label6')).then(() => {
                this.$router.back();
              });
              return;
            }
          }

          if(this.checkdata.STATE > 4){
            //5,6은 환불 취소
            this.$alert(this.$t('runqrticket_label7')).then(() => {
              this.$router.back();
            });
            /*
            this.$confirm("취소하신 행사입니다.").then(() => {
              this.$router.back();
            }).catch(()=>{
              this.$router.back();
            });
            */
            return;
          }

          this.PRINT_QR     = this.checkdata.PRINT_QR;
          this.PRINT_NUMBER = this.checkdata.PRINT_NUMBER != null && this.checkdata.PRINT_NUMBER != undefined ? this.checkdata.PRINT_NUMBER : "";
          this.TEL          = this.checkdata.TEL;
          this.EVENT_NAME   = this.checkdata.EVENT_NAME;
          this.EVENT_IMAGE  = this.checkdata.IMAGE;
          this.NAME         = this.checkdata.INFO1;
          this.USE_PASSWORD = this.checkdata.USE_PASSWORD;
          
          if(this.checkdata.EVENT_DATE_VAL == 0){
            //행사기간 지남
            this.$alert(this.$t('runqrticket_label8'));
          }else{
            var canvas = document.getElementById('canvas');
            QRCode.toCanvas(canvas, String(this.PRINT_QR), {width:250},function (error) {
            if (error) console.error(error)
              console.log('success!');
            })
          }
        }else if(result.data.resultCode == 2){
          this.$alert(this.$t('common_msg9')).then(()=>{
            this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
          });
        }else{
          this.$alert(result.data.resultMsg);
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });            

    },

  },

}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
  .pw_round{
    background-color: #4e6ef7 !important;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 50px;
    color:white;
    text-align: center;
    padding-top: 10px;
    font-size:18px;
    box-shadow:0px 0px 2px #c0c0c0;
  }
  .qr_round{
    background-color: white !important;
    border-radius: 20px !important; 
    border-width:1px 1px 1px 1px !important;
    box-shadow:0px 0px 2px #c0c0c0;
  }

  .warning-message {
    background: linear-gradient(60deg, #03045e, #023e8a, #0077b6, #0096c7, #0077b6, #023e8a, #03045e);
    background-size: 200% 100%;
    animation: gradient-animation 5s linear infinite;
    color: white;
    padding: 10px 5px 10px 5px;
    font-family: 'AppleSDGothicNeoM', sans-serif;
  }
  @keyframes gradient-animation {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }


</style>