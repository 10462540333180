<template>
  <v-container fluid>

    <!-- 링크 입력 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="link_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card :class="$vuetify.breakpoint.smAndDown ?'' : 'dialog_round pa-10'">

          <v-card-text>
            <v-row align="center" justify="center" class="mt-10 mb-10" dense >
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->
                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">{{$t('join_label1')}}</p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" placeholder="Link"
                      single-line v-model="LINKADDR" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined  hide-details/>
                  </v-col>
                </v-row>    
            </v-col>
          </v-row>
        </v-card-text>  

          <v-card-actions class="mb-10">
            <v-row justify="center">
              <v-btn class="dialog_cancel_btn mr-5" rounded large  @click="link_dialog = false">{{$t('common_msg21')}}</v-btn>
              <v-btn class="dialog_btn" rounded large  @click="save_link">{{$t('common_msg22')}}</v-btn>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>


    <!-- 이미지 크롭 팝업 (가로) -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:#4e6ef7" class="d-flex align-center justify-center">
              <span style="color:white">{{$t('join_label2')}}</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0" justify="center">
                    <v-btn elevation="0" class="dialog_cancel_btn mr-5" width="100%" height="50px" @click="crop_dialog = false">{{$t('common_msg21')}}</v-btn>
                    <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">{{$t('common_msg22')}}</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="purple"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">{{$t('common_msg11')}}</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <v-row justify="center" class="gray_text ma-0 pa-0">
      <v-col cols="12" md="6" 
        :style="$vuetify.breakpoint.smAndDown ? 'padding: 0px 0px 0px 0px': 'padding: 20px 26px 50px 26px'">
  
        <v-row align="center" justify="center" class="mt-8 mt-md-15 mb-2">
        <v-col cols="12" align="center">
            <label class="event_title">{{NAME}}</label>
        </v-col>
      </v-row>
      <v-row class="mt-1 mb-3" dense>
        <v-col cols="12" align="start">
          <label class="blue_text">{{ $t('regjoin_label11') }}</label>
        </v-col>
      </v-row>

      <div style="border: 1px solid #DADADA;" 
        :style="$vuetify.breakpoint.smAndDown ? 'padding: 30px 10px 30px 10px': 'padding: 40px 26px 40px 26px'"
        class="gray_text">

      <!-- 기본 입력 정보-->
      <template v-for="inputitem in INPUTLIST">
      
      <template v-if="inputitem.USETYPE == 1">  <!-- 사용이면 -->
        
        <!-- 질문 -->
        <v-row dense no-gutters :key="inputitem.NAME+1" class="ma-0 pa-0 mt-0" align="center">
          <template v-if="inputitem.REQTYPE == 1">
            <v-col cols="12"><span style="color:#627BFF">*</span>&nbsp;{{inputitem.INPUTDESC}}</v-col>
          </template>
          <template v-else>
            <v-col cols="12">{{ inputitem.INPUTDESC }}</v-col>
          </template>
        </v-row>

        <!-- 입력 -->
        <template v-if="inputitem.LANTYPE == 4">  

          <!-- 이미지 등록 -->
          <template v-if="inputitem.INPUT_TEXT != null && inputitem.INPUT_TEXT != undefined && inputitem.INPUT_TEXT.length > 0">
            <v-row dense  justify="center" class="ma-0 pa-0 mt-3 mb-0" :key="inputitem.NAME+3">
              <img  :height="inputitem.HEIGHT" :width="inputitem.WIDTH" :src="inputitem.INPUT_TEXT" class="image">
            </v-row>
            <v-row dense justify="center" class="ma-0 pa-0 mt-3 ml-1 mr-1 mb-5" :key="inputitem.NAME+4">
              <v-col cols="12" align="center">
                <v-btn text class="gray_text" @click="fileSelect(inputitem)">{{$t('common_msg37')}}</v-btn>
                <v-btn text class="gray_text" @click="clearFile(inputitem)">{{$t('common_msg38')}}</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row dense class="ma-0 pa-0 mt-3 ml-1 mr-1 mb-8" align="center" justify="center" :key="inputitem.NAME+5">
              <v-col cols="12" class="gray_border pt-5" align="center">
                <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="fileSelect(inputitem)">mdi-image</v-icon>
                <!-- <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="linkadd(inputitem)">mdi-link</v-icon> -->
                <p class="info_text mt-2">{{$t('common_msg36')}}</p>
              </v-col>
            </v-row>
          </template>

        </template>

        <template v-else-if="inputitem.LANTYPE == 5">  
          <!-- 파일 등록 -->
          <template v-if="inputitem.INPUT_TEXT != null && inputitem.INPUT_TEXT != undefined && inputitem.INPUT_TEXT.length > 0">
            <v-row dense  justify="center" class="ma-0 pa-0 mt-3 mb-0" :key="inputitem.NAME+6">
              <a :href="inputitem.INPUT_TEXT" target="_blink" download>File Download</a>
            </v-row>
            <v-row dense justify="center" class="ma-0 pa-0 mt-3 ml-1 mr-1 mb-5" :key="inputitem.NAME+7">
              <v-col cols="12" align="center">
                <v-btn text class="gray_text" @click="fileSelect_all(inputitem)">{{$t('common_msg37')}}</v-btn>
                <v-btn text class="gray_text" @click="clearFile(inputitem)">{{$t('common_msg38')}}</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row dense class="ma-0 pa-0 mt-3 ml-1 mr-1 mb-8" align="center" justify="center" :key="inputitem.NAME+8">
              <v-col cols="12" class="gray_border pt-5" align="center">
                <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="fileSelect_all(inputitem)">mdi-upload</v-icon>
                <p class="info_text mt-2">{{$t('common_msg39')}}</p>
              </v-col>
            </v-row>
          </template>
        </template>

        <template v-else-if="inputitem.LANTYPE == 2">  
          <!-- 영문만 입력 가능 -->
          <v-row dense :key="inputitem.NAME+2" align="center" justify="center" class="ma-0 pa-0 mt-md-2">
            <v-col cols="12">
              <!-- INPUT_CODE 1 = 이름은 변경 불가 -->
              <template v-if="inputitem.INPUT_CODE == 1">
                <template v-if="inputitem.MAXLINES == 1">
                  <v-textarea no-resize height="70" outlined
                    disabled :id="inputitem.NAME" :rules="engrule" :counter="inputitem.LIMITTEXT" v-model="inputitem.INPUT_TEXT"
                    :dense="$vuetify.breakpoint.smAndDown"
                    />
                </template>
                <template v-else>
                  <v-text-field disabled :id="inputitem.NAME" outlined :rules="engrule" :counter="inputitem.LIMITTEXT" v-model="inputitem.INPUT_TEXT"
                  :dense="$vuetify.breakpoint.smAndDown"
                  />
                </template>
              </template>
              <template v-else>
                <template v-if="inputitem.MAXLINES == 1">
                  <v-textarea no-resize height="70" outlined
                    :id="inputitem.NAME" :rules="engrule" :counter="inputitem.LIMITTEXT" v-model="inputitem.INPUT_TEXT"
                    :dense="$vuetify.breakpoint.smAndDown"
                    />
                </template>
                <template v-else>
                  <v-text-field :id="inputitem.NAME" outlined :rules="engrule" :counter="inputitem.LIMITTEXT" v-model="inputitem.INPUT_TEXT"
                  :dense="$vuetify.breakpoint.smAndDown"
                  />
                </template>
              </template>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row dense :key="inputitem.NAME+3" align="center" justify="center" class="ma-0 pa-0 mt-md-2">
            <v-col cols="12">
              <template v-if="inputitem.INPUT_CODE == 1">
                <template v-if="inputitem.MAXLINES == 1">
                  <v-textarea no-resize height="70" outlined
                    disabled v-model="inputitem.INPUT_TEXT" :id="inputitem.NAME" :counter="inputitem.LIMITTEXT" 
                    :dense="$vuetify.breakpoint.smAndDown"
                    />
                </template>
                <template v-else>
                  <v-text-field disabled v-model="inputitem.INPUT_TEXT" :id="inputitem.NAME" outlined :counter="inputitem.LIMITTEXT" 
                  :dense="$vuetify.breakpoint.smAndDown"
                  />
                </template>
              </template>
              <template v-else>
                <template v-if="inputitem.MAXLINES == 1">
                  <v-textarea no-resize height="70" outlined
                    v-model="inputitem.INPUT_TEXT" :id="inputitem.NAME" :counter="inputitem.LIMITTEXT" 
                    :dense="$vuetify.breakpoint.smAndDown"
                    />
                </template>
                <template v-else>
                  <v-text-field v-model="inputitem.INPUT_TEXT" :id="inputitem.NAME" outlined :counter="inputitem.LIMITTEXT" 
                  :dense="$vuetify.breakpoint.smAndDown"
                  />
                </template>
              </template>
            </v-col>
          </v-row>
        </template>

      </template>
    </template>

    <!-- 필수 입력 정보-->
    <v-row v-if="useInfo.USEMAIL == 1" dense no-gutters align="center" class="ma-0 pa-0 mt-8">
      <v-col cols="12"><span style="color:#627BFF">*</span>{{$t('join_label4')}}</v-col>
    </v-row>
    <v-row v-if="useInfo.USEMAIL == 1" dense align="center" justify="center" class="ma-0 pa-0 mt-md-2">
      <v-col cols="12">
        <v-text-field id="INPUT_EMAIL" v-model="INPUT_EMAIL" outlined hide-details :dense="$vuetify.breakpoint.smAndDown"/>
      </v-col>
    </v-row>

    <v-row v-if="useInfo.USETEL == 1" dense no-gutters class="ma-0 pa-0 mt-5" align="center">
      <v-col cols="12"><span style="color:#627BFF">*</span>&nbsp;{{$t('regedit_label2')}}</v-col>
    </v-row>
    <v-row v-if="useInfo.USETEL == 1" dense align="center" justify="center" class="ma-0 pa-0 mt-md-2">
      <v-col cols="12">
        <v-text-field disabled :rules="mobile" id="INPUT_TEL" outlined counter="20" v-model="INPUT_TEL" type="number"
        :dense="$vuetify.breakpoint.smAndDown"
          />
      </v-col>
    </v-row>

    <v-row v-if="useInfo.USEPW == 1" dense no-gutters class="ma-0 pa-0 mt-0" align="center">
      <v-col cols="12"><span style="color:#627BFF">*</span>&nbsp;{{$t('regedit_label3')}}</v-col>
    </v-row>
    <v-row v-if="useInfo.USEPW == 1" dense align="center" justify="center" class="ma-0 pa-0 mt-md-2">
      <v-col cols="12">
        <v-text-field :rules="numberrules" id="INPUT_PW" outlined :counter="4" v-model="INPUT_PW" type="number" 
        :dense="$vuetify.breakpoint.smAndDown"
          />
      </v-col>
    </v-row>

    <v-row v-if="useInfo.USESEX == 1" dense no-gutters class="ma-0 pa-0 mt-0" align="center">
      <v-col cols="12"><span style="color:#627BFF">*</span>&nbsp;{{$t('regedit_label4')}}</v-col>
    </v-row>
    <v-row v-if="useInfo.USESEX == 1" dense no-gutters class="ma-0 pa-0 mt-md-2" align="center" justify="center">
      <v-col cols="12">
        <v-select id="INPUT_SEX" outlined hide-details :menu-props="{ origin: 'bottom', offsetY: true }" style="font-size:14px;"
          v-model="INPUT_SEX" :items="SEXLIST"  item-text="name" item-value="value" 
          :dense="$vuetify.breakpoint.smAndDown"
          >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title style="font-size: 13px;">
                {{ data.item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </template>                
        </v-select>
      </v-col>
    </v-row>

    <v-row v-if="useInfo.USEYEAR == 1" dense no-gutters class="ma-0 pa-0 mt-8" align="center">
      <v-col cols="12"><span style="color:#627BFF">*</span>&nbsp;{{$t('regedit_label16')}}</v-col>
    </v-row>
    <v-row v-if="useInfo.USEYEAR == 1" dense no-gutters class="ma-0 pa-0 mt-md-2" align="center" justify="center">
      <v-col cols="12" class="pl-1 pr-1">
        <v-text-field type="number" :rules="yearrules" outlined oninput="javascript:this.value=this.value.replace(/[^0-9]/g,'');"
          id="INPUT_YEAR" v-model="INPUT_YEAR"  counter="4" :suffix="$t('join_label9')"
          :dense="$vuetify.breakpoint.smAndDown"
          />
      </v-col>
    </v-row>

    <v-row v-if="useInfo.USENATION == 1" dense no-gutters class="ma-0 pa-0 mt-0" align="center">
      <v-col cols="12"><span style="color:#627BFF">*</span>&nbsp;{{$t('regedit_label6')}}</v-col>
    </v-row>
    <v-row v-if="useInfo.USENATION == 1" dense no-gutters class="ma-0 pa-0 mt-md-2" align="center" justify="center">
      <v-col cols="12" class="pl-1 pr-1">
        <v-select 
          id="INPUT_NATION" hide-details @change="testnation" style="font-size:14px;"
          v-model="INPUT_NATION" :items="NATIONCODE" :menu-props="{ origin: 'bottom', offsetY: true }"
          :item-text="lan_code == 0 ? 'NAMEKOR' : 'NAMEENG'" outlined
          :dense="$vuetify.breakpoint.smAndDown"
          item-value="NACODE">
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title style="font-size: 13px;">
              {{ lan_code == 0 ? data.item.NAMEKOR : data.item.NAMEENG }}
            </v-list-item-title>
          </v-list-item-content>
        </template>                
      </v-select>
      </v-col>
    </v-row>

    <v-row class="mt-3"/>

    <!-- 행사별 설문정보 -->
    <template v-for="formatitem in TITLELIST">

    <template v-if="formatitem.TYPE == 0">  <!-- 주관식 -->
      <v-row :key="formatitem.IDX+'TITLE'" :id="formatitem.IDX+'TITLE'" dense no-gutters class="ma-0 pa-0 mt-8" align="center">
        <v-col cols="12"><span style="color:#627BFF" v-if="formatitem.REQTYPE == 1">*&nbsp;</span>{{formatitem.TITLE}}</v-col>
      </v-row>
      <v-row dense :key="formatitem.IDX+'INPUT'" align="center" justify="center" class="ma-0 pa-0 mt-md-2">
        <v-col cols="12">
          <v-text-field :id="formatitem.IDX+'INPUT'" outlined v-model="formatitem.INPUT_TEXT" :dense="$vuetify.breakpoint.smAndDown"/>
        </v-col>
      </v-row>
    </template>

    <template v-else-if="formatitem.TYPE == 1"> <!-- 단일선택 -->
      <v-row :key="formatitem.IDX+'TITLE'" :id="formatitem.IDX+'TITLE'" dense no-gutters class="ma-0 pa-0 mt-8" align="center">
        <v-col cols="12"><span style="color:#627BFF" v-if="formatitem.REQTYPE == 1">*&nbsp;</span>{{formatitem.TITLE}}</v-col>
      </v-row>
      <v-row :key="formatitem.IDX+'INPUT'" dense no-gutters class="ma-0 pa-0 mt-4" align="center" justify="center"> 
        <v-col cols="12">
          <v-select hide-details v-model="formatitem.SELECT_SINGLE_CHOICE" :id="formatitem.IDX+'INPUT'" outlined
              :items="getChildDropDownWidgets(formatitem.FORMAT_IDX)" :menu-props="{ origin: 'bottom', offsetY: true }"
              :label="$t('common_msg23')" item-text="NAME" item-value="FORMAT_OPTION_IDX" 
              style="font-size:14px;" :dense="$vuetify.breakpoint.smAndDown"
              >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px;">
                  {{ data.item.NAME }}
                </v-list-item-title>
              </v-list-item-content>
            </template>                
          </v-select>
        </v-col>
      </v-row>              
    </template>

    <template v-else-if="formatitem.TYPE == 2"> <!-- 멀티선택 -->
      <v-row :key="formatitem.IDX+'TITLE'" :id="formatitem.IDX+'TITLE'" dense no-gutters class="ma-0 pa-0 mt-8" align="center">
        <v-col cols="12"><span style="color:#627BFF" v-if="formatitem.REQTYPE == 1">*&nbsp;</span>{{formatitem.TITLE}}</v-col>
      </v-row>
      <v-row :key="formatitem.IDX+'INPUT'" dense no-gutters class="ma-0 pa-0 mt-md-2" align="center" justify="center"> 
        <v-col cols="12">
          <template v-for="fitem in getChildCheckWidgets(formatitem.FORMAT_IDX)">
            <v-row dense no-gutters class="ma-0 pa-0" align="center" :key="fitem.NAME">
              <v-col cols="12" >
                <label><input type="checkbox" v-model="fitem.CHECK" :value="fitem.FORMAT_OPTION_IDX">&nbsp;{{fitem.NAME}}</label>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>              
    </template>
    
    <template v-else-if="formatitem.TYPE == 9"> <!-- 단순설명 -->
      <v-row :key="formatitem.IDX+'TITLE'" dense no-gutters class="ma-0 pa-0 mt-8" align="center">
        <v-col cols="12" style="color:#800000;font-size:16px">{{formatitem.TITLE}}</v-col>
      </v-row>
    </template>

    </template>

  </div>
  <button class="bottombtn_pc1" @click="runjoincheck">{{$t('regedit_label7')}}</button>
  <div style="height:150px"/>

    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import cmvalidation from '../assets/js/cmvalidation.js'
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import AWS from 'aws-sdk';  
export default {
    components:{
      VueCropper
    },

  data: () => ({

        EVENTCODE:"",
        VISITORCODE:"",

        visitor:{},
        TITLELIST:[],   //설문정보
        INPUTLIST:[],   //기본입력질문양식
        NATIONCODE:[],  //국가코드

        loading:false,
        testvalue:"",
        temp_idx: -1,

        CODEVAL:0,  //0이면 없는 초청장
        MSG:"",
        NAME:"",
        info:{
            NAME:"",
            USESTATE:0,
        },
        useInfo:{},

        INPUT_TEL:"",
        INPUT_PW:"",
        INPUT_SEX:1,
        INPUT_YEAR:"1985",
        INPUT_NATION:"KR",
        INPUT_EMAIL: "",

        LIST:[],        //행사 설문 접수양식 전체값
    
        SEXLIST:[],

        send_flag :false,
        mobile: null,
        numberrules:null,
        engrule:null,
        yearrules:null,

      link_dialog: false,
      link_item:null,
      LINKADDR:"",
      s3: null,   //aws

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:300,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 1200,
        limitMinSize: [100, 100]
      },            
      crop_dialog:false,
      photo_item:null,
      imgSrc:'',
      lan_code:0, //한국어
  }),


  beforeDestroy() {

      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){

        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }

        let elements2 = document.getElementsByName("inputfile");
        for(var j=0, len2=elements2.length ; j != len2; ++j){
            elements2[0].parentNode.removeChild(elements2[0]);
        }

      }

  },  

  mounted(){  
    
    window.scrollTo(0,0);
    
    //path를 통해서 들어올 경우 ex) /경로/값
    //console.log("get code " + JSON.stringify(this.$route.query));
    this.EVENTCODE    = this.$route.query.eventcode;
    this.VISITORCODE  = this.$route.query.visitorcode;

    //s3
    this.s3 = new AWS.S3({
      "accessKeyId": process.env.VUE_APP_UPLOADK,		
      "secretAccessKey": process.env.VUE_APP_UPLOADS,
      "region": process.env.VUE_APP_UPLOADR,
      params:{Bucket:process.env.VUE_APP_UPLOADB}
    });

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
        this.$i18n.locale = 'ko';
    }else{
        this.$i18n.locale = 'en';
    }

    this.SEXLIST = [
        {name:this.$t('common_msg24'),   value:1}
      , {name:this.$t('common_msg25'), value:2}
    ];

    let msg1 = this.$t('common_msg2');
    this.mobile =  value => !value || !/[^0-9]/.test(value) || msg1;
    let msg2 = this.$t('common_msg26');
    this.numberrules = [v => Number.isInteger(Number(v)) || msg2];
    let msg3 = this.$t('common_msg27');
    this.engrule = [v => !v || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(v) || msg3 ];
    let msg4 = this.$t('common_msg26');
    let msg5 = this.$t('common_msg28');
    this.yearrules = [v => Number.isInteger(Number(v)) || msg4, v => v.length == 4 || msg5];

    this.getNationCode();
  },

  methods: {
    
    testnation(){
      //console.log(JSON.stringify(this.INPUT_NATION));
    },

    test(item){
      console.log(JSON.stringify(item));
    },

    //해당 포맷 옵션 아이디 리스트 가져오기
    getChildDropDownWidgets(format_idx){
      var temp_list = [];
      if(this.LIST.length < 1) return temp_list;
      this.LIST.forEach(element=>{
        if(element.FORMAT_IDX == format_idx){
          temp_list.push(element);
        }
      })
      return temp_list;
    },

    getChildCheckWidgets(format_idx){
      var temp_list = [];
      this.LIST.forEach(element=>{
        if(element.FORMAT_IDX == format_idx){
          element.CHECK = false;
          if(element.SELECT_OPTION_IDX != null && element.SELECT_OPTION_IDX != undefined){
            element.CHECK = true;
          }
          temp_list.push(element);
        }
      })
      return temp_list;
    },

    //검증용 - 셋팅용 사용하면 값 초기화 되서 안됨.
    getChildCheckWidgets_check(format_idx){
      var temp_list = [];
      this.LIST.forEach(element=>{
        if(element.FORMAT_IDX == format_idx){
          temp_list.push(element);
        }
      })
      return temp_list;
    },


    getNationCode(){
      //국가코드 가져오기
      this.$http.post(this.$host+'/ime/NationCode',{
        LAN_CODE:this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.NATIONCODE = result.data.resultData;
            this.loadRegInfo();
          }else{
            let infomsg = this.$t('join_label11');
            this.$alert(infomsg);
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      
    },

    loadRegInfo(){
      //행사별 신청양식 가져오기
      this.$http.post(this.$host+'/web/loadRegInfo',{
          EVENT_IDX:this.EVENTCODE
        , VISITOR_IDX:this.VISITORCODE
        , LAN_CODE:this.$i18n.locale
        , ADMIN_IDX:1
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{
          if(result.data.resultCode == 0){
            
            this.useInfo = result.data.useData;
            
            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            //기본 입력정보는 무조건 있어야 한다.
            if(result.data.resultData1 == null || result.data.resultData1 == undefined || result.data.resultData1.length == 0){
              this.$alert(this.$t('regedit_label8'));
              this.$router.replace({path:"RunEventInfo", query:{eventcode:this.EVENTCODE, visitorcode:this.VISITORCODE}});
              return;
            }

            //신청정보 복원
            this.visitor    = result.data.resultData1[0];
            
            //기본양식 복원
            this.INPUTLIST = result.data.resultData3;

            if(this.visitor != null){

              //내국인/외국인 유무에 따른 언어 설정 변경
              if(this.visitor.ISFOREIGNER == 0){
                this.$i18n.locale = 'ko';
              }else{
                this.$i18n.locale = 'en';
              }

              this.INPUTLIST.forEach(element=>{

                //외국인이면 기본등록정보 영문으로 표시
                if(this.visitor.ISFOREIGNER == 1){
                  element.NAME      = element.ENAME;
                  element.INPUTDESC = element.EINPUTDESC;
                }

                if(element.INPUT_CODE == 1){
                  element.INPUT_TEXT = this.visitor.INFO1;
                }else if(element.INPUT_CODE == 2){
                  element.INPUT_TEXT = this.visitor.INFO2;
                }else if(element.INPUT_CODE == 3){
                  element.INPUT_TEXT = this.visitor.INFO3;
                }else if(element.INPUT_CODE == 4){
                  element.INPUT_TEXT = this.visitor.INFO4;
                }else if(element.INPUT_CODE == 5){
                  element.INPUT_TEXT = this.visitor.INFO5;
                }else if(element.INPUT_CODE == 6){
                  element.INPUT_TEXT = this.visitor.INFO6;
                }else if(element.INPUT_CODE == 7){
                  element.INPUT_TEXT = this.visitor.INFO7;
                }else if(element.INPUT_CODE == 8){
                  element.INPUT_TEXT = this.visitor.INFO8;
                }else if(element.INPUT_CODE == 9){
                  element.INPUT_TEXT = this.visitor.INFO9;
                }else if(element.INPUT_CODE == 10){
                  element.INPUT_TEXT = this.visitor.INFO10;
                }

              });

              this.INPUT_TEL   = this.visitor.TEL;
              this.INPUT_SEX   = this.visitor.SEX;
              this.INPUT_YEAR  = String(this.visitor.YEAR);
              this.INPUT_NATION = this.visitor.NATION;
              this.INPUT_EMAIL = this.visitor.EMAIL;
              this.INPUT_PW    = this.visitor.PRINT_NUMBER;

            }

            //설문정보 복원
            this.LIST = result.data.resultData2;
            this.temp_idx = -1;
            this.LIST.forEach(item=>{

              //내국인 외국인에 따른 질문 영문화 처리
              if(this.visitor.ISFOREIGNER == 1){
                item.TITLE = item.TITLE_ENG == null || item.TITLE_ENG == undefined || item.TITLE_ENG.length == 0 ? item.TITLE : item.TITLE_ENG;
                item.NAME  = item.NAME_ENG == null || item.NAME_ENG == undefined || item.NAME_ENG.length == 0 ? item.NAME : item.NAME_ENG;
              }

              if(item.IDX != this.temp_idx){  //idx가 다르면 title케이스임

                this.TITLELIST.push(item);
                this.temp_idx = item.IDX;
                if(item.TYPE == 0){
                  //주관식 입력값 복원
                  item.INPUT_TEXT = item.VALUE;

                }else if(item.TYPE == 1){
                  //단일선택 선택값 복원
                  for(var i=0;i<this.LIST.length;i++){
                    var el1 = this.LIST[i];
                    if(item.FORMAT_IDX == el1.FORMAT_IDX){
                      if(el1.SELECT_OPTION_IDX != null && el1.SELECT_OPTION_IDX != undefined){
                        item.USE_CHOICE = true;       //이게 있는 객체가 선택된 값을 저장하고 있는 객체다.
                        item.SELECT_SINGLE_CHOICE = el1.SELECT_OPTION_IDX;
                        break;
                      }
                    }
                  }

                }else if(item.TYPE == 2){

                  //다중선택 복원
                  for(var j=0;j<this.LIST.length;j++){
                    var el2 = this.LIST[j];
                    if(item.FORMAT_IDX == el2.FORMAT_IDX){
                      el2.CHECK = false;
                      if(el2.SELECT_OPTION_IDX != null && el2.SELECT_OPTION_IDX != undefined){
                        el2.CHECK = true;
                      }
                    }
                  }

                }

              }

            });

          }else if(result.data.resultCode == 2){
            this.$alert(this.$t('regedit_label9')).then(()=>{
              this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
            });

          }else{
            this.$alert(this.$t('regedit_label10'));
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      
    },



    runjoincheck(){
      this.$confirm(this.$t('regedit_label11')).then(() => {
        this.runjoin();
      });      
    },


    runjoin(){

      //신청하기
      if(this.send_flag){
        this.$alert(this.$t('regedit_label12'));
        return;
      }

      //기본정보 체크
      const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      for(var i=0;i<this.INPUTLIST.length;i++){
        var element = this.INPUTLIST[i];
        if(element.USETYPE == 1){
          
          var temp = document.getElementById(element.NAME);

          if(element.LANTYPE == 4){
            if(element.INPUT_TEXT == null || element.INPUT_TEXT == undefined || element.INPUT_TEXT.length == 0){
              alert(element.INPUTDESC);
              this.$vuetify.goTo(temp, {duration:200, offset:50, easing:"easeInOutCubic"});
              temp.focus();
              return;
            }

          }else if(element.LANTYPE == 5){
            //파일첨부
            //필수입력 체크
            if(element.REQTYPE == 1){ 
              if(element.INPUT_TEXT == undefined || element.INPUT_TEXT.length < 1){
                alert(element.INPUTDESC);
                this.$vuetify.goTo(temp, {duration:200, offset:50, easing:"easeInOutCubic"});
                temp.focus();
                return;
              }
            }

          }else{

            //필수입력 체크
            if(element.REQTYPE == 1){ 
              if(temp.value == undefined || temp.value.length < 1){
                alert(element.INPUTDESC);
                this.$vuetify.goTo(temp, {duration:200, offset:50, easing:"easeInOutCubic"});
                temp.focus();
                return;
              }
            }

            //자리수 체크
            if(temp.value.length > element.LIMITTEXT){
              let infomsg = this.lan_code == 0 ? element.NAME+'값의 최대 입력 자리수를 초과하였습니다.' : 'The maximum number of input digits of the '+element.NAME+' value has been exceeded.';
              alert(infomsg);
              this.$vuetify.goTo(temp, {duration:200, offset:50, easing:"easeInOutCubic"});
              temp.focus();
              return;
            }

            //영문만인 경우 처리
            if(element.LANTYPE == 2 && temp.value.length > 0){
              if(korean.test(temp.value)){
                let infomsg = this.lan_code == 0 ? element.NAME+'값에 한글을 사용할 수 없습니다.' : 'You cannot use Korean for '+element.NAME+' values.';
                alert(infomsg);
                this.$vuetify.goTo(temp, {duration:200, offset:50, easing:"easeInOutCubic"});
                temp.focus();
                return;
              }
            }

          }

        }
      }

      //필수정보 체크
      const numberVal = /[^0-9]/g;

      if(this.useInfo.USETEL == 1){
        var input_tel = document.getElementById("INPUT_TEL");
        if(this.INPUT_TEL == undefined || this.INPUT_TEL.length < 1){
          let infomsg = this.$t('join_label13');
          alert(infomsg);
          this.$vuetify.goTo(input_tel, {duration:200, offset:50, easing:"easeInOutCubic"});
          input_tel.focus();
          return;
        }
        if(this.INPUT_TEL.length > 20){
          let infomsg = this.$t('join_label14');
          alert(infomsg);
          this.$vuetify.goTo(input_tel, {duration:200, offset:50, easing:"easeInOutCubic"});
          input_tel.focus();
          return;
        }
        if(numberVal.test(this.INPUT_TEL)){
          let infomsg = this.$t('join_label15');
          alert(infomsg);
          this.$vuetify.goTo(input_tel, {duration:200, offset:50, easing:"easeInOutCubic"});
          input_tel.focus();
          return;
        }
      }else{
        this.INPUT_TEL = "";
      }

      if(this.useInfo.USEMAIL == 1){
        var emailVal = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        var input_email = document.getElementById("INPUT_EMAIL");
        if(this.INPUT_EMAIL  == undefined || this.INPUT_EMAIL.length < 1 || this.INPUT_EMAIL.length > 30){
          let infomsg = this.$t('join_label16');
          alert(infomsg);
          this.$vuetify.goTo(input_email, {duration:200, offset:50, easing:"easeInOutCubic"});
          input_email.focus();
          return;
        } 
        if(!emailVal.test(this.INPUT_EMAIL)){
          let infomsg = this.$t('join_label17');
          alert(infomsg);
          this.$vuetify.goTo(input_email, {duration:200, offset:50, easing:"easeInOutCubic"});
          input_email.focus();
          return;
        } 
      }else{
        this.INPUT_EMAIL = "";
      }

      if(this.useInfo.USEPW == 1){
        var input_pw = document.getElementById("INPUT_PW");
        if(this.INPUT_PW.length != 4){
          let infomsg = this.$t('join_label18');
          alert(infomsg);
          this.$vuetify.goTo(input_pw, {duration:200, offset:50, easing:"easeInOutCubic"});
          input_pw.focus();
          return;
        }
        if(numberVal.test(this.INPUT_PW)){
          let infomsg = this.$t('join_label19');
          alert(infomsg);
          this.$vuetify.goTo(input_pw, {duration:200, offset:50, easing:"easeInOutCubic"});
          input_pw.focus();
          return;
        }
      }else{
        this.INPUT_PW = "0000";
      }

      if(this.useInfo.USEYEAR == 1){
        var input_year = document.getElementById("INPUT_YEAR");
        if(this.INPUT_YEAR.length != 4){
          let infomsg = this.$t('join_label20');
          alert(infomsg);
          this.$vuetify.goTo(input_year, {duration:200, offset:50, easing:"easeInOutCubic"});
          input_year.focus();
          return;
        }
      }else{
        this.INPUT_YEAR = "1985";
      }
        
      //신청정보 입력 체크
      var checkflag = true;
      for(var j=0;j<this.TITLELIST.length;j++){
        
        var element2 = this.TITLELIST[j];
        var format_temp = document.getElementById(element2.IDX+'INPUT');
        var format_title = document.getElementById(element2.IDX+'TITLE');

        if(element2.TYPE == 0){

          if(element2.REQTYPE == 1){
            //필수입력
            if(format_temp.value == undefined || format_temp.value == null || format_temp.value.length < 1){
              let infomsg = element2.TITLE + this.$t('regedit_label17');
              alert(infomsg);
              this.$vuetify.goTo(format_title, {duration:200, offset:50, easing:"easeInOutCubic"});
              format_temp.focus();
              checkflag = false;
              break;
            }
          }

          //주관식 값 만들어주기
          element2.INPUT_TEXT = format_temp.value == undefined || format_temp.value == null || format_temp.len < 1 ? "" : format_temp.value;

        }else if(element2.TYPE == 1){

          if(element2.REQTYPE == 1){
            if(element2.SELECT_SINGLE_CHOICE == undefined || element2.SELECT_SINGLE_CHOICE == null){
              let infomsg = element2.TITLE + this.$t('regedit_label13');
              alert(infomsg);
              this.$vuetify.goTo(format_title, {duration:200, offset:50, easing:"easeInOutCubic"});
              format_temp.focus();
              checkflag = false;
              break;
            }

            
          }

        }else if(element2.TYPE == 2){

          //다중선택
          if(element2.REQTYPE == 1){
            
            //필수입력
            var checkflag2  = true;
            const checklist = this.getChildCheckWidgets_check(element2.FORMAT_IDX);
            checklist.forEach(check=>{
              if(check.CHECK){
                //체크된 값이 있으면
                checkflag2 = false;
              }
            })

            if(checkflag2){
              let infomsg = element2.TITLE + this.$t('regedit_label13');
              alert(infomsg);
              this.$vuetify.goTo(format_title, {duration:200, offset:50, easing:"easeInOutCubic"});
              format_temp.focus();
              checkflag = false;
              break;
            }

          }


        }

      }


      if(checkflag){
        this.sendRegInfo();
      }


    },

    sendRegInfo(){

      const PARAMLIST = [];
      //서버로 전송할 데이터만 추출
      this.LIST.forEach(item=>{
        if(item.TYPE == 0){
          PARAMLIST.push(item);
        }else if(item.TYPE == 1){
          if(item.SELECT_SINGLE_CHOICE != null && item.SELECT_SINGLE_CHOICE != undefined){
            PARAMLIST.push(item);
          }
        }else if(item.TYPE == 2){
          if(item.CHECK){
            PARAMLIST.push(item);
          }
        }
      });
      //console.log(JSON.stringify(PARAMLIST));

      this.loading = true;
      
      var INPUT = ["","","","","","","","","",""];
      this.INPUTLIST.forEach(element =>{
        var temp = document.getElementById(element.NAME);
        if(element.USETYPE == 1){
          if(element.LANTYPE == 4 || element.LANTYPE == 5){
            if(element.INPUT_CODE == 1){
              INPUT[0] = element.INPUT_TEXT;
            }else if(element.INPUT_CODE == 2){
              INPUT[1] = element.INPUT_TEXT;
            }else if(element.INPUT_CODE == 3){
              INPUT[2] = element.INPUT_TEXT;
            }else if(element.INPUT_CODE == 4){
              INPUT[3] = element.INPUT_TEXT;
            }else if(element.INPUT_CODE == 5){
              INPUT[4] = element.INPUT_TEXT;
            }else if(element.INPUT_CODE == 6){
              INPUT[5] = element.INPUT_TEXT;
            }else if(element.INPUT_CODE == 7){
              INPUT[6] = element.INPUT_TEXT;
            }else if(element.INPUT_CODE == 8){
              INPUT[7] = element.INPUT_TEXT;
            }else if(element.INPUT_CODE == 9){
              INPUT[8] = element.INPUT_TEXT;
            }else if(element.INPUT_CODE == 10){
              INPUT[9] = element.INPUT_TEXT;
            }
          }else{
            if(element.INPUT_CODE == 1){
              INPUT[0] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }else if(element.INPUT_CODE == 2){
              INPUT[1] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }else if(element.INPUT_CODE == 3){
              INPUT[2] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }else if(element.INPUT_CODE == 4){
              INPUT[3] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }else if(element.INPUT_CODE == 5){
              INPUT[4] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }else if(element.INPUT_CODE == 6){
              INPUT[5] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }else if(element.INPUT_CODE == 7){
              INPUT[6] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }else if(element.INPUT_CODE == 8){
              INPUT[7] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }else if(element.INPUT_CODE == 9){
              INPUT[8] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }else if(element.INPUT_CODE == 10){
              INPUT[9] = temp.value == undefined || temp.value == null || temp.value.length < 1 ? "" : temp.value;
            }
          }
        }
      });

      //비회원 행사 신청
      this.$http.post(this.$host+'/web/RegEventJoinUpdate',{
          EVENT_IDX:this.EVENTCODE
        , VISITOR_IDX:this.VISITORCODE
        , LAN_CODE:this.$i18n.locale
        , ADMIN_IDX:1
        , INFO1:escape(INPUT[0])
        , INFO2:escape(INPUT[1])
        , INFO3:escape(INPUT[2])
        , INFO4:escape(INPUT[3])
        , INFO5:escape(INPUT[4])
        , INFO6:escape(INPUT[5])
        , INFO7:escape(INPUT[6])
        , INFO8:escape(INPUT[7])
        , INFO9:escape(INPUT[8])
        , INFO10:escape(INPUT[9])
        , TEL:escape(this.INPUT_TEL)
        , EMAIL:escape(this.INPUT_EMAIL)
        , SEX:this.INPUT_SEX
        , YEAR:this.INPUT_YEAR
        , NATION:this.INPUT_NATION
        , PRINT_NUMBER:this.INPUT_PW
        , PARAMS:escape(JSON.stringify(PARAMLIST))
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{
        this.loading = false;
        if(result.data.resultCode == 0){

          //토큰 저장
          if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
            this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
          }
          if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
            this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
          }

          this.send_flag = true;
          this.$alert(this.$t('regedit_label14')).then(() => {
            this.$router.push({path:"RunEventInfo", query:{eventcode:this.EVENTCODE, visitorcode:this.VISITORCODE, lan_code:this.lan_code}});
          });      

        }else if(result.data.resultCode == 2){
          this.$alert(this.$t('regedit_label9')).then(()=>{
            this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
          });

        }else{
          this.$alert(this.$t('regedit_label15')).then(()=>{
            this.$router.push({path:"RunEventInfo", query:{eventcode:this.EVENTCODE, visitorcode:this.VISITORCODE, lan_code:this.lan_code}});
          });
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert(this.$t('common_msg1'));
        }else{
            this.$alert(error.message).then(()=>{
              this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
            });
        }
      });      
      

    },



    clearFile(item){
      item.INPUT_TEXT = null;
      this.$forceUpdate();
    },

    linkadd(item){
      this.LINKADDR = "";
      this.link_item = item;
      this.link_dialog = true;
    },

    fileSelect(item){
      const changefunction = this.changefile;
      var input = document.createElement("input");
      input.style = "display:none";
      input.name = "imageinputcamera";
      input.type = "file";
      input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        //아이폰, 아이패드, 맥
        //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
        document.body.appendChild(input);
        input.onchange = function (event) {
          //추가된 input 제거
          document.body.removeChild(input);
          changefunction(event.target.files[0], item);
        };
      }else{
        input.onchange = function (event) {
          changefunction(event.target.files[0], item);
        };
      }
      input.click();
    },


    fileSelect_all(item){
        const changefunction = this.changefile_all;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "inputfile";
        input.type = "file";
        input.accept = "*"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], item);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], item);
          };
        }
        input.click();
      },     


      //일반 모든 파일
      async changefile_all(file, item){
        
        const fname = file.name;

        if(file.size/1024/1024 > 10){
          this.$alert(this.$t('join_label25'));
          return;
        }

        //s3에 파일 업로드 경로
        var albumPhotosKey = encodeURIComponent("visitor") + "/" + encodeURIComponent(this.EVENTCODE) + "/";

        //이미지 업로드
        var filename = this.EVENTCODE+"_"+Date.now().toString()+"."+fname.split('.').pop();
        var photoKey = albumPhotosKey + filename;   
        const stored1 = await this.s3.upload({
                                                Bucket: process.env.VUE_APP_UPLOADB,
                                                Key: photoKey,
                                                Body: file,
                                                ACL: "public-read"
                                              }).promise();

        item.INPUT_TEXT = stored1.Location;
        

        this.$forceUpdate();

      },    

    //링크 저장
    save_link(){
      const urlVal = /https?:\/\//;
      const checklink = cmvalidation.checkValReg(this.LINKADDR, urlVal, -1, 255, false);
      if(checklink == 1){
        let infomsg = this.$t('join_label22');
        this.$alert(infomsg);
        return false;
      }else if(checklink == 2){
        let infomsg = this.$t('join_label23');
        this.$alert(infomsg);
        return false;
      }else if(checklink == 4){
        let infomsg = this.$t('join_label24');
        this.$alert(infomsg);
        return false;
      }              
      this.link_item.INPUT_TEXT = this.LINKADDR;
      this.link_dialog = false;
    },


    //이미지 선택시 크롭창 띄우기
    changefile(file, item){
      if(file.size/1024/1024 > 10){
        let infomsg = this.$t('join_label25');
        this.$alert(infomsg);
        return;
      }
      //이미지 크롭 팝업 띄우기
      const reader = new FileReader();
      reader.onload = (event)=>{
        this.photo_item            = item;
        this.option.autoCropWidth  = item.WIDTH;
        this.option.autoCropHeight = item.HEIGHT;
        this.option.fixedNumber    = [item.WIDTH, item.HEIGHT];
        this.option.fixed = true;
        this.crop_dialog = true;
        this.imgSrc = event.target.result;  //이게 blob데이터
      }
      reader.readAsDataURL(file);  //비동기로 파일을 읽어서
    },


    //이미지 크롭시
    cropimg(){
      //이미지 크롭 
      this.$refs.cropper_land.getCropBlob(blob=>{
        this.crop_dialog = false;
        this.uploadimage(blob);
      });
    },


    //url의 마지막 /이후의 값을 가져온다.
    async getLastPath(path_url){
      var array = path_url.split('/');
      return array[array.length-1];
    },

    async uploadimage(blob){

      //s3에 파일 업로드 경로
      var albumPhotosKey = encodeURIComponent(this.EVENTCODE) + "/";

      //이미지 업로드
      var filename = Date.now().toString()+".jpg";
      var photoKey = albumPhotosKey + filename;   
      const stored1 = await this.s3.upload({
                                              Bucket: process.env.VUE_APP_UPLOADB,
                                              Key: photoKey,
                                              Body: blob,
                                              ACL: "public-read"
                                            }).promise();
      
      this.photo_item.INPUT_TEXT = stored1.Location;
      this.$forceUpdate();

    },



  },

}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.gray_border{
  border: 1px solid #DADADA;
}

.bottombtn_pc1{
  width:100%; height:58px;
  font-size:16px; 
  font-family: 'NotoSansKR-Regular' !important;
  text-align: center !important;
  color: #fff !important;
  border-radius: 0px !important;
  background-color: #627BFF !important;
}

.btncursor{
  cursor: pointer;
}

.event_title{
  font-size: 16px;
  font-family: 'NotoSansKR-Medium' !important;
  color:#515151
}

.blue_text{
  font-size: 14px;
  font-family: 'NotoSansKR-Medium' !important;
  color:#627BFF
}

.gray_text{
  font-size: 14px;
  font-family: 'NotoSansKR-Regular' !important;
  color:#515151
}

.info_text{
  font-size: 12px;
  font-family: 'NotoSansKR-Regular' !important;
  color:#C5C5C5
}


/* outlinned border 설정 */
.v-text-field--outlined >>> fieldset {
  border-radius: 0px;
  border-color: #DADADA;
}

</style>