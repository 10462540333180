<template>
<!-- 기업링크 페이지 (기업로그인, 참기기업등록) -->
<v-container fluid class="pa-0">

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="purple"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">{{$t('common_msg11')}}</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>


  <!-- 공지사항 상세내용 -->
  <v-dialog persistent scrollable overlay-color="#00000040" 
      v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown" width="700">

    <v-card style="overflow:hidden">
      <table style="width:100%">
        <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
          <td class="pl-2 pr-2 pl-md-5 pr-md-5" style="border-right-width:0px;color:#4364FF"
              :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc2'">
            {{ `${broad.INDEX}. ${broad.TITLE}` }}
          </td>
          <td class="pl-2 pr-2 pl-md-5 pr-md-5" style="border-left-width:0px;width:200px;color:#4364FF"
              :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_date' : 'tabledesc_date'">
            {{ broad.REG_DATE }}
          </td>
        </tr>
      </table>
    <v-card-text class="ma-0 pl-10 pr-10 pt-5">
      <div v-html="htmlcontent" class="tabledesc"/>
    </v-card-text>

    <v-card-actions class="ma-0 pa-0">
      <button class="bottombtn_pc1" @click="dialog = false">{{$t('common_msg4')}}</button>
    </v-card-actions>
    </v-card>
  </v-dialog>  

  <!-- 행사정보 상세 -->
  <v-row justify="end" dense no-gutters class="mt-11 mb-1 ma-0 pa-0 hidden-md-and-up align-center">
    <div class="btncursor mr-3" style="color:#627BFF;font-size:11px" @click="goinfo">
      <homeicon color="#627BFF" :width="10" class="mr-1"/>{{$t('invitation_label34')}}</div>
    <div class="mr-2">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">        
          <div class="btncursor" v-bind="attrs" v-on="on" style="color:#627BFF;font-size:11px">
          <lanicon color="#627BFF" :width="10" class="mr-0"/>
          Language
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in $lanlist"
            :key="index" @click="lanchange(item)"
            >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-row>

  <div class="d-flex justify-center" :style="$vuetify.breakpoint.smAndDown ? 'margin-top:0px':'margin-top:118px'">

    <div :style="$vuetify.breakpoint.smAndDown ? 'width:100%':'width:700px'" >

      <v-img
        :aspect-ratio="16/9"
        :src="info.IMAGE"
        style="border-radius: 0px;"
        >
      </v-img>

      <div class="hidden-md-and-down" style="width:100%;height:15px;background:#627bff"/>

      <!-- 패딩 -->
      <div :style="$vuetify.breakpoint.smAndDown ? 'padding:0px 10px 0px 10px':'padding:0px'">

        <!-- 행사 타입 -->      
        <div class="text-center" 
            style="background:#FCB346;color:#ffffff;vertical-align;margin-top:17px;"
            :style="$vuetify.breakpoint.smAndDown ? 'width:120px;height:30px;font-size:12px;middle;line-height:30px':'width:212px;height:30px;font-size:14px;middle;line-height:30px'"
          >{{ info.TYPENAME }}</div>

        <v-tabs
          v-model="tab"
          background-color="white" color="#4364FF" 
          @change="tabchange" show-arrows
          >
          <v-tabs-slider color="#ffffff"></v-tabs-slider>
          <v-tab :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'">
            {{$t('invitation_label4')}}
          </v-tab>
          <v-tab v-if="info.USE_NOTICE != undefined && info.USE_NOTICE != null && info.USE_NOTICE == 1" 
                :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'" >
            {{$t('invitation_label5')}}
          </v-tab>
          <v-tab v-if="info.USE_SCHEDULE != undefined && info.USE_SCHEDULE != null &&info.USE_SCHEDULE == 1" 
                :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'" >
            {{$t('invitation_label6')}}
          </v-tab>
          <v-tab v-if="info.USE_GALLARY != undefined && info.USE_GALLARY != null &&info.USE_GALLARY == 1" 
                :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'" >
            {{$t('invitation_label7')}}
          </v-tab>
          <v-tab v-if="info.USE_MAP != undefined && info.USE_MAP != null &&info.USE_MAP == 1" 
                :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'" >
            {{$t('invitation_label8')}}
          </v-tab>

        <!-- 행사정보 -->    
        <v-tab-item>

          <v-row no-gutters dense class="mt-md-3" align="center" justify="center">
            <v-col cols="12" align="start">
              <label 
                :class="$vuetify.breakpoint.smAndDown ? 'event_title_mb' : 'event_title'"
                >{{info.NAME}}</label>
            </v-col>
            <v-col cols="12" align="start" v-if="info.DESC1 != undefined && info.DESC1 != null && info.DESC1.length > 0" 
                :style="$vuetify.breakpoint.smAndDown ? 'margin-top:10px;' : 'margin-top:25px;'">
              <div
                :class="$vuetify.breakpoint.smAndDown ? 'event_desc1_mb' : 'event_desc1'"
                >{{ info.DESC1 }}</div>
            </v-col>
          </v-row>
          
          <!-- 포스터 -->
          <template v-if="info.USE_POSTER == 1 && info.POSTER != null && info.POSTER != undefined && info.POSTER.length > 0 ">
            <v-row no-gutters dense align="center" justify="center" class="mt-5 mt-md-5 mb-md-4">
              <v-img
                :src="info.POSTER"
                class="ma-0 ma-md-2 btncursor"
                @click="clickimg(info.POSTER)"
                >
              </v-img>
            </v-row>
          </template>

        <!-- 행사정보 테이블 -->
        <template v-if="info.USE_INFO == 1 ">

          <v-row v-if="info.DESC2 != undefined && info.DESC2 != null && info.DESC2.length > 0" dense align="center" justify="center" style="margin-top:15px;margin-right:15px">
              <v-col cols="12" align="start">
                <div
                :class="$vuetify.breakpoint.smAndDown ? 'event_desc2_mb' : 'event_desc2'"
                >{{ info.DESC2 }}</div>
              </v-col>
          </v-row>
          
          <v-row no-gutters dense align="center" justify="center" style="margin-top:25px">
              <v-col cols="12" align="center">
          
                <table style="width:100%;">

                  <tr  style="border-top-left-radius:0px;" 
                      :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'"
                    >
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                        :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px' : 'border-top-width:1px'"
                        style="border-top-left-radius:0px;">{{$t('invitation_label9')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'"
                        :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px' : 'border-top-width:1px'"
                        style="border-top-right-radius:0px;background:#ffffff;">{{info.NAME}}</td>
                  </tr>
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      >{{$t('invitation_label10')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'"
                      >{{info.EVENT_START+' ~ ' + info.EVENT_END}}</td>
                  </tr>
                  <template v-if="info.AREANAME != undefined && info.AREANAME != null && info.AREANAME.length > 0">
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'">
                      {{$t('invitation_label11')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" >{{info.AREANAME}}{{info.ADDR2 != null ? info.ADDR2  : ''}}</td>
                  </tr>
                  </template>
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                    >{{$t('invitation_label12')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" >{{info.ADDR1 != null ? info.ADDR1 : ''}}</td>
                  </tr>
                  <template v-if="info.HOST != undefined && info.HOST != null && info.HOST.length > 0">
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                    >{{$t('invitation_label13')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" style="white-space:pre-wrap" >{{info.HOST}}</td>
                  </tr>
                  </template>
                  <template v-if="info.ORGANIZER != undefined && info.ORGANIZER != null && info.ORGANIZER.length > 0">
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      >{{$t('invitation_label14')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" style="white-space:pre-wrap" >{{info.ORGANIZER}}</td>
                  </tr>
                  </template>
                  <template v-if="info.INFO != undefined && info.INFO != null && info.INFO.length > 0">
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="text-center tabletitle" style="border-bottom-left-radius:0px;"
                      :style="$vuetify.breakpoint.smAndDown ? 'border-bottom-width:1px' : 'border-bottom-width:1px'"
                      :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      >{{$t('invitation_label15')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" 
                      :style="$vuetify.breakpoint.smAndDown ? 'border-bottom-width:1px' : 'border-bottom-width:1px'"
                      style="border-bottom-right-radius:0px;white-space:pre-wrap;">
                      {{ info.INFO == undefined || info.INFO == null ? '' : info.INFO }}
                      </td>
                  </tr>
                  </template>


                </table>

          
              </v-col>
          </v-row>
        </template>


        <!-- 등록기간 안내 -->
        <template v-if="info.USE_REGDATE == 1 && optionlist != undefined && optionlist.length > 0">

          <v-row no-gutters dense align="center" justify="center" 
            :style="$vuetify.breakpoint.smAndDown ? 'margin-top:25px' : 'margin-top:50px'">
            <v-col cols="12" align="center">
        
              <table style="width:100%;border-collapse: collapse;">

                <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                  <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px;font-weight:bold' : 'border-top-width:1px;font-weight:bold'"
                      >{{$t('invitation_label16')}}</td>
                </tr>

                <tr v-for="option in optionlist" :key="option.IDX" 
                    :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" 
                        :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md2' : 'tabledesc2'"
                        >
                      {{ option.NAME }}
                      <span style="color:#627BFF;margin-left:5px">{{ option.COST_TYPE == 0 ? $t('invitation_label35') : $t('invitation_label36') }}</span><br>
                      {{ $t('invitation_label17') + option.REG_START + "~" + option.REG_END }}
                    </td>
                </tr>
              </table>
        
            </v-col>
          </v-row>
        </template>

        <!-- 환불정보 -->
        <template v-if="info.USE_REFUND ==1 && refundlist != undefined && refundlist.length > 0">

          <v-row no-gutters dense align="center" justify="center" 
            :style="$vuetify.breakpoint.smAndDown ? 'margin-top:25px' : 'margin-top:50px'">
            <v-col cols="12" align="center">
        
              <table style="width:100%;border-collapse: collapse;">

                <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                  <td colspan="3" class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px;font-weight:bold' : 'border-top-width:1px;font-weight:bold'"
                      >{{$t('invitation_label18')}}</td>
                </tr>

                <tr v-for="refund in refundlist" :key="refund.IDX" 
                    :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tablerefund_md' : 'tabledesc2'">
                        {{ refund.DAY }}
                    </td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tablerefund_md' : 'tabledesc2'">
                      {{ refund.RATE }}
                    </td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tablerefund_md' : 'tabledesc2'">
                      {{ refund.REFUND }}
                    </td>
                </tr>
              </table>
        
            </v-col>
          </v-row>
        </template>

        <!-- 하단 지도 및 공유 버튼 -->
          <v-row no-gutters dense align="center" justify="center" class="d-flex justify-space-around" 
            :style="$vuetify.breakpoint.smAndDown ? 'margin-top:25px' : 'margin-top:50px'">
            <template v-if="info.LINK_URL != null && info.LINK_URL.length > 0">
              <div class="d-flex justify-center align-center btncursor" @click="showlink(info.LINK_URL)"
                :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                <homeblackicon class="mr-2" />
                {{$t('invitation_label19')}}
              </div>
            </template>
            <template v-if="info.YOUTUBE != null && info.YOUTUBE.length > 0" >
              <div class="d-flex justify-center align-center btncursor" @click="showlink(info.YOUTUBE)"
                :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                <youtubeicon class="mr-2" />
                YOUTUBE
              </div>
            </template>
            <template v-if="info.LATITUTE != null && info.LATITUTE.length > 0 && info.LONGITUTE != null && info.LONGITUTE.length > 0" >
              <div class="d-flex justify-center align-center btncursor" @click="showmap"
                :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                <mapicon class="mr-2" />
                {{$t('invitation_label20')}}
              </div>
            </template>
            <template align="center">
              <div class="d-flex justify-center align-center btncursor" @click="sharekakao()"
                :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                <shareicon class="mr-2" />
                {{$t('invitation_label37')}}
              </div>
            </template>
          </v-row>

          <v-row style="height:300px">
          </v-row>


          <!-- 모바일에서 옵션 선택 영역 -->
          <v-bottom-navigation
            class="pa-0 ma-0 hidden-md-and-up"
            app
            color="indigo" 
            style="display:block;background:#f6f6f6;overflow-y: auto;"
            height="auto" 
            >
            <v-row class="ma-0 pa-0 mt-2 mb-2 pl-2 pr-2" justify="center">
              <v-col cols="12" align="center" class="ma-0 pa-0 mt-1">
                <div style="color:#4d67df;font-size:14px">{{$t('peinfo_label1')}}</div>
              </v-col>
              <v-col cols="12" align="center" class="ma-0 pa-0 mt-1">
                <v-text-field 
                  outlined v-model="user_id" 
                  name="login" :placeholder="$t('peinfo_label2')" background-color="#ffffff"
                  color="#4e6ef7" hide-details
                  type="text" dense></v-text-field>
                <v-text-field 
                  class="mt-2"
                  outlined @keyup.enter="login" v-model="user_pw" 
                  name="password" :placeholder="$t('peinfo_label3')" id="password" background-color="#ffffff"
                  type="password" dense color="#4e6ef7" hide-details></v-text-field>
              </v-col>
              <v-col cols="6" align="center" class="mt-2 pa-0 ma-0">
                <button class="bottombtn2" style="height:45px" @click="login">{{$t('peinfo_label4')}}</button>
              </v-col>
              <v-col cols="6" align="center" class="mt-2 pa-0 ma-0">
                <button class="bottombtn" style="height:45px" @click="goregist">{{$t('peinfo_label5')}}</button>
              </v-col>
            </v-row>
            
          </v-bottom-navigation>      

        </v-tab-item>


        <!-- 공지사항 탭 -->
        <v-tab-item v-if="info.USE_NOTICE != undefined && info.USE_NOTICE != null &&info.USE_NOTICE == 1">

          <v-row no-gutters dense align="center" justify="center" class="mt-1 mt-md-5 mb-10">
            <template v-if="boardlist.length > 0">
      
              <v-col cols="12" align="center">
                <table style="width:100%;border-collapse: collapse;">
                  <tr v-for="(item, index) in boardlist" :key="item.IDX" class="btncursor"
                      :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                      <td class="pl-2 pr-2 pl-md-5 pr-md-5" @click="shownotice(index, item)"
                          :style="$vuetify.breakpoint.smAndDown ? 'border-right-width:1px' : 'border-right-width:0px'"
                          :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc2'">
                        {{ `${index+1}. ${item.TITLE}` }}
                      </td>
                      <td class="pl-2 pr-2 pl-md-5 pr-md-5 hidden-md-and-down" @click="shownotice(index, item)"
                          style="border-left-width:0px;width:200px"
                          :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_date' : 'tabledesc_date'">
                        {{ item.REG_DATE }}
                      </td>
                  </tr>
                </table>
              </v-col>

            </template>
            <template v-else>
              <v-row align="center" justify="center" >
                <v-col  cols="12" align="center">
                  <label style="color:#808080">{{$t('invitation_label24')}}</label>
                </v-col>
              </v-row>
            </template>
          </v-row>
        </v-tab-item>

        <!-- 부대행사 -->
        <v-tab-item v-if="info.USE_SCHEDULE != undefined && info.USE_SCHEDULE != null &&info.USE_SCHEDULE == 1">
          <v-row no-gutters dense align="center" justify="center" class="mt-1 mt-md-5 mb-10">
          <template v-if="schedulelist.length > 0">

            <v-col cols="12" align="center">
              <table style="width:100%;border-collapse: collapse;">
                <tr v-for="item in schedulelist" :key="item.NUMBER">

                  <template v-if="item.SORTTYPE == 1">

                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" 
                        :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc2'" style="color:#3d3d3d !important;background:#F6F6F6">
                        {{ item.SDATE + " " + daycodeToString(item.DAYCODE) }}
                    </td>

                  </template>
                  <template v-else>
                    <td class="pa-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_date' : 'tabledesc_date'">

                      <div class="mt-1 mb-5" style="font-size:16px;color:#5f67ec;">{{ item.NAME }}</div>
                      <div class="mt-3" :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc_area'">{{ $t('invitation_label25') + item.START_TIME.substring(0,5) + " ~ " + item.END_TIME.substring(0,5) }}</div>
                      <div class="mb-1" :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc_area'">{{ $t('invitation_label26') + item.LOCATION }}</div>
                      <div class="mt-5 mb-1" :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc_area'">{{ item.DESC }}</div>
                    </td>
                  </template>

                </tr>
              </table>
            </v-col>

          </template>
          <template v-else>
            <v-row align="center" justify="center" >
              <v-col  cols="12" align="center">
                <label style="color:#808080">{{$t('invitation_label24')}}</label>
              </v-col>
            </v-row>
          </template>
          </v-row>
        </v-tab-item>

        <!-- 갤러리 -->
        <v-tab-item v-if="info.USE_GALLARY != undefined && info.USE_GALLARY != null &&info.USE_GALLARY == 1">
          <v-row no-gutters dense align="center" justify="center" class="mt-1 mt-md-5 mb-10">
          <template v-if="gallist.length > 0">

            <v-col cols="12" align="center" class="pa-0 ma-0 mb-10">
              <table style="width:100%;border-collapse: collapse;">
                <tr v-for="item in gallist" :key="item.IDX">
                    <td class="pa-5 pa-md-10 text-center">

                      <img @click="clickimg(item.IMAGE)" width="100%" style="width:100%;max-width:392px" :src="item.IMAGE" />
                      

                    </td>
                </tr>
              </table>
            </v-col>

          </template>
          <template v-else>
            <v-row align="center" justify="center" >
              <v-col  cols="12" align="center">
                <label style="color:#808080">{{$t('invitation_label24')}}</label>
              </v-col>
            </v-row>
          </template>
          </v-row>
        </v-tab-item>

        <!-- 오시는길 -->
        <v-tab-item v-if="info.USE_MAP != undefined && info.USE_MAP != null &&info.USE_MAP == 1">
          <v-row no-gutters dense align="center" justify="center" class="mt-1 mt-md-5 mb-10">
          <template v-if="maplist.length > 0">

            <v-col cols="12" align="center" class="pa-0 ma-0 mb-10">
              <table style="width:100%;border-collapse: collapse;">
                <tr v-for="item in maplist" :key="item.IDX">
                    <td class="pa-5" >

                      <div class="mt-0 mb-3"
                        :class="$vuetify.breakpoint.smAndDown ? 'event_map_title' : 'event_map_title'">
                        {{ item.TITLE }}
                      </div>
                      
                      <img @click="clickimg(item.IMAGE)" width="100%" style="width:100%" :src="item.IMAGE" />
                      

                      <div class="mt-3 d-flex align-center" style="background:#f6f6f6;padding: 10px 10px 10px 10px">
                        <div :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'event_desc2'" class="me-auto">{{ item.DESC }}</div>
                        <button
                          @click="showlink(item.LINK)" class="mt-3"
                          style="background:#627BFF; color:white; border: 0px solid white;font-size:14px;width:100px;height:30px"
                          v-if="item.LINK != null && item.LINK.length > 0" outlined>
                          {{$t('invitation_label28')}}
                        </button>
                      </div> 

                    </td>
                </tr>
              </table>
            </v-col>

          </template>
          <template v-else>
            <v-row align="center" justify="center" >
              <v-col  cols="12" align="center">
                <label style="color:#808080">{{$t('invitation_label24')}}</label>
              </v-col>
            </v-row>
          </template>
          </v-row>
        </v-tab-item>

        </v-tabs>

      </div>
    </div>

    <!-- pc 옵션선택 영역 -->
    <div style="width:455px;margin-left:30px" class="hidden-sm-and-down" >

        <v-card
          ref="info_card"
          color="#ffffff"
          class="pb-15 fixed_info_150"
          :style="'top:'+topscroll+'px !important'"
          elevation="0"
          >
        
          <v-row justify="center" dense no-gutters class="ma-0 pa-0">
            <v-col cols="6">
              <div class="top_blue_btn d-flex justify-center align-center btncursor" @click="goinfo"><homeicon class="mr-3"/>{{$t('invitation_label34')}}</div>
            </v-col>
            <v-col cols="6">
              <div class="top_blue_btn d-flex justify-center align-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">        
                    <lanicon class="mr-3"/>
                    <a v-bind="attrs" v-on="on" style="color:#ffffff">Language</a>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in $lanlist"
                      :key="index" @click="lanchange(item)"
                      >
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>

          <v-row style="height:54px"></v-row>
          <v-row dense no-gutters>
            <v-col cols="12" style="background:#F6F6F6" class="pt-8">

              <v-row justify="center">
                <v-col cols="12">
                  <div class="text-center option_title">{{ info.NAME }}</div>
                </v-col>
              </v-row>

              <v-row class="mt-3" justify="center">

                <v-col cols="11" align="end" class="mt-0 pa-0">
                  <div style="width:100%;color:#627BFF !important" class="text_normal_pc text-center">{{$t('peinfo_label1')}}</div>
                </v-col>

                <v-col cols="11" align="center" class="ma-0 pa-0 mt-3 mb-3">
                  <v-text-field 
                    outlined v-model="user_id" 
                    name="login" :placeholder="$t('peinfo_label2')"
                    color="#4e6ef7" hide-details background-color="#ffffff"
                    type="text" dense></v-text-field>
                  <v-text-field 
                    class="mt-3"
                    outlined @keyup.enter="login" v-model="user_pw" 
                    name="password" placeholder="비밀번호" id="password" background-color="#ffffff"
                    type="password" dense color="#4e6ef7" hide-details></v-text-field>
                </v-col>
              </v-row>

              <v-row dense no-gutters class="mt-3">
                <v-col cols="6" class="pt-0 ma-0">
                  <button class="bottombtn_pc2" @click="login">{{$t('peinfo_label4')}}</button>
                </v-col>
                <v-col cols="6" class="pt-0 ma-0">
                  <button class="bottombtn_pc1" @click="goregist">{{$t('peinfo_label5')}}</button>
                </v-col>
              </v-row>
            
            </v-col>
          </v-row>
        </v-card>

    </div>

  </div>






</v-container>
</template>

<script>
export default {

  data: () => ({
    tab: null,
    dialog: false,
    value: 1,
    bottomstate:true,
    broad:{},
    htmlcontent:"",
    
    visitor:null,
    info:{},
    optionlist:[],
    refundlist:{},

    boardlist:{},
    schedulelist:{},
    gallist:{},
    maplist:{},
    
    topscroll: 0,

    EVENTCODE:"",
    ADMIN_IDX:"",
    COM_IDX:"",

    user_id : "",
    user_pw : "",
    loading:false,

    tablist:[],
    lan_code:0,
    first_flag:true,
  }),


  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    window.scrollTo(0,0);
    this.EVENTCODE    = this.$route.query.eventcode;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    window.addEventListener('scroll', this.handleScroll);
    this.loadpage();
    
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  

  methods: {

    goinfo(){
      this.$router.push({path:"PartEventInfo", query:{eventcode:this.EVENTCODE, lan_code:this.lan_code}});
      location.reload();
    },

    lanchange(item){
      if(item.value == "ko"){
        this.lan_code = 0;
        this.$i18n.locale = "ko";
      }else{
        this.lan_code = 1;
        this.$i18n.locale = "en";
      }
      this.loadpage();
    },    

    handleScroll() {
      this.topscroll = 0 - window.scrollY;
      if(this.topscroll < 23){
        this.topscroll = 0;
      }
    },


    sharekakao(){
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: this.info.NAME, // 보여질 제목
          description: this.info.DESC1, // 보여질 설명
          imageUrl: this.info.IMAGE,
          link: {
              mobileWebUrl: 'https://fairpass.co.kr/RunEventInfo?eventcode='+this.EVENTCODE,
              webUrl: 'https://fairpass.co.kr/RunEventInfo?eventcode='+this.EVENTCODE
          }
        },
      });
    },
    

    //등록하기
    goregist(){
      this.$router.push({path:"/PartEventJoin", query:{eventcode:this.EVENTCODE, admin_idx:this.ADMIN_IDX, com_idx:this.COM_IDX, lan_code:this.lan_code}});
    },    
    
    login(){
      
      if(this.user_id.length < 1){
        this.$alert(this.$t('peinfo_label2'));
        return;
      }
      if(this.user_pw.length < 1){
        this.$alert(this.$t('peinfo_label8'));
        return;
      }

      this.loading = true;
      this.$http.post(this.$host+'/ime/PartLogin',{
          USER_ID: this.user_id
        , USER_PW: Buffer.from(this.user_pw).toString('base64')
        , EVENT_IDX: this.EVENTCODE
      })
      .then((result)=>{

        this.loading = false;
        if(result.data.resultCode == 0){
            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            this.$router.push({path:"/PartEventComout", query:{lan_code:this.lan_code}});


        }else{
          this.$alert(this.$t('peinfo_label7'));
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        this.$alert(this.$t('peinfo_label7'));
      });


    },


    daycodeToString(daycode){
      var dayname = "";
      if(daycode == "0"){
        dayname = this.$t('common_msg12');
      }else if(daycode == "1"){
        dayname = this.$t('common_msg13');
      }else if(daycode == "2"){
        dayname = this.$t('common_msg14');
      }else if(daycode == "3"){
        dayname = this.$t('common_msg15');
      }else if(daycode == "4"){
        dayname = this.$t('common_msg16');
      }else if(daycode == "5"){
        dayname = this.$t('common_msg17');
      }else if(daycode == "6"){
        dayname = this.$t('common_msg18');
      }
      return dayname;
    },

    tabchange(tabindex){
  
      //console.log("tabindex : " + tabindex);
      if(tabindex == 1){
        if(this.boardlist.length == undefined){
          this.loadbroad();
        }
      }else if(tabindex == 2){
        if(this.schedulelist.length == undefined){
          this.loadschedule();
        }
      }else if(tabindex == 3){
        if(this.gallist.length == undefined){
          this.loadgal();
        }
      }else if(tabindex == 4){
        if(this.maplist.length == undefined){
          this.loadmap();
        }
      }

    },
    
    shownotice(index, item){
      this.broad = item;
      this.broad.INDEX = index+1;
      var html = "";
      html += this.broad.DESC;
      if(item.FILE1 != null && item.FILE1.length > 0){
        
        const last = item.FILE1.split("/")
        item.FILENAME =  decodeURI(last[last.length-1]);

        html += "<a href="+item.FILE1+" download><p>"+this.$t('common_msg19')+item.FILENAME+"</p></a>"
      }
      this.htmlcontent = "<!DOCTYPE HTML><html></html><head><meta name='viewport' content='width=device-width, initial-scale=1'/><style>img{ width:100% }</style></style></head><body>"+html+"</body></html>";
      this.dialog = true;
    },

    clickimg(imgsrc){
      let infomsg = this.$t('common_msg20');
      var w = window.open("", infomsg);
      w.document.write(`<!DOCTYPE html>
      <html><head><meta name='viewport' content='width=device-width, initial-scale=1'/><img src=`+imgsrc+`></img></head><body></body>
      </html>`);        
      w.document.close(); // necessary for IE >= 10
      w.focus();          // necessary for IE >= 10
    },

    showlink(link){
      window.open(link);
    },
    showmap(){
      window.open("https://www.google.com/maps/search/?api=1&query="+this.info.LATITUTE+","+this.info.LONGITUTE);
    },

    loadpage(){

      this.$http.post(this.$host+'/ime/EventDetailAll',{
            EVENT_IDX:this.EVENTCODE
          , ADMIN_IDX:1
          , LAN_CODE: this.first_flag == true ? null : this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.info = result.data.resultData[0];

            //최초 조회는 행사의 언어 설정에 따른다.
            if(this.first_flag){
              this.lan_code = this.info.USE_LAN;
              if(this.lan_code == 0){
                this.$i18n.locale = 'ko';
              }else{
                this.$i18n.locale = 'en';
              }
              this.first_flag = false;
            }

            this.ADMIN_IDX  = this.info.ADMIN_IDX;
            this.COM_IDX    = this.info.COM_IDX;
            this.optionlist = result.data.optionData;
            this.refundlist = result.data.refundData;

            //탭정보 지정
            this.tablist.push("info");  //기본정보는 무조건 추가
            if(this.info.USE_NOTICE != undefined && this.info.USE_NOTICE != null && this.info.USE_NOTICE == 1){
              this.tablist.push("notice");
            }
            if(this.info.USE_SCHEDULE != undefined && this.info.USE_SCHEDULE != null && this.info.USE_SCHEDULE == 1){
              this.tablist.push("schedule");
            }
            if(this.info.USE_GALLARY != undefined && this.info.USE_GALLARY != null && this.info.USE_GALLARY == 1){
              this.tablist.push("gallery");
            }
            if(this.info.USE_MAP != undefined && this.info.USE_MAP != null && this.info.USE_MAP == 1){
              this.tablist.push("map");
            }

          }else{
            this.$alert(this.$t('peinfo_label9'));
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
              this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },


    loadbroad(){

      this.$http.post(this.$host+'/ime/EventNoticeList',{
          EVENT_IDX:this.EVENTCODE
        , LAN_CODE:this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.boardlist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
              this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },

    loadschedule(){

      this.$http.post(this.$host+'/ime/EventScheduleList',{
        EVENT_IDX:this.EVENTCODE
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.schedulelist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
              this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },

    
    loadgal(){

      this.$http.post(this.$host+'/ime/EventGalleryList',{
          EVENT_IDX:this.EVENTCODE
        , pagerow: 100
        , page: 1
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.gallist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
              this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },
    
    loadmap(){

      this.$http.post(this.$host+'/ime/EventMapList',{
          EVENT_IDX:this.EVENTCODE
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.maplist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
              this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    }


  },

}
</script>

<style scoped>

/* 옵션 선택창 */
.fixed_info_150{
  position: fixed;
  z-index:10;
  width:455px;
  min-width: 455px;
}

.fixed_info_50{
  position: fixed;
  top: 50px;
  right: 90px;
  z-index:9999;
  width: 630px;
}

.bottombtn_pc1{
  width:100%; 
  height:58px;
  font-size:16px; 
  font-family: 'NotoSansKR-Medium' !important;
  text-align: center !important;
  color: #fff !important;
  border-radius: 0px !important;
  background-color: #627BFF !important;
}
.bottombtn_pc2{
  width:100%; height:58px;
  font-size:16px;
  font-family: 'NotoSansKR-Medium' !important;
  text-align: center !important;
  color: #525252 !important;
  border-radius: 0px !important;
  background-color: #E8E8E8 !important;
}

.bottombtn{
  width:100%; 
  height:44px;
  font-size:14px; 
  font-family: 'NotoSansKR-Medium' !important;
  text-align: center !important;
  color: #fff !important;
  border-radius: 0px !important;
  background-color: #627BFF !important;
}

.bottombtn2{
  width:100%; 
  height:44px;
  font-size:14px; 
  font-family: 'NotoSansKR-Medium' !important;
  text-align: center !important;
  color: #525252 !important;
  border-radius: 0px !important;
  background-color: #E8E8E8 !important;
}

.bottombtn:hover{
  background-color: #2d2f77 !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #E5E5E5;
}

.tabletitle{
  width:106px !important;
  min-width: 106px;
  font-size:14px;
  color:#3D3D3D;
  background:#F6F6F6;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
}
.tabletitle_mb{
  width:20%;
  min-width: 70px;
  font-size:14px;
  color:#3D3D3D;
  background:#F6F6F6;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
  padding-top: 5px;
  padding-bottom: 5px;
}

.tabledesc{
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
  padding: 5px 0px 5px 0px;
}
.tabledesc_md{
  width:70%;
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.tabledesc_md2{
  width:70%;
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.table100_md{
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 1px;
  white-space: pre-wrap;
  padding-top: 7px;
  padding-bottom: 7px;
}


.tablerefund_md{
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
}


.tabledesc2{
  font-size:15px;
  color:#3D3D3D;
  white-space: pre-wrap;
  padding: 10px 0px 10px 0px;
}

.tabledesc_date{
  font-size:14px;
  color:#929292;
  white-space: pre-wrap;
  padding: 10px 0px 10px 0px;
}

.tabledesc_area{
  font-size:15px;
  color:#3D3D3D;
  white-space: pre-wrap;
}


table{
  border-radius: 20px;
  border-collapse: separate; 
  border-spacing:0;
  border: 0px solid #DADADA;
}

td {
  border: 1px solid #DADADA;
}


.text_normal_pc{
  font-size:14px !important;
  color:#3D3D3D !important;
  font-family: 'NotoSansKR-Medium' !important;
}

.text_normal_mb{
  font-size:14px;
  color:#3D3D3D;
}

.top_blue_btn{
  width: 100%;
  background-color: #627bff;
  color:#ffffff;
  font-size: 13px;
  font-family: 'NotoSansKR-Regular';
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.vtab_text{
  font-size: 14px;
  font-family: 'NotoSansKR-Medium';
  color: #3D3D3D !important;
  text-transform: none;
}

.vtab_text_mb{
  font-size: 14px;
  font-family: 'NotoSansKR-Medium';
  color: #3D3D3D !important;
  text-transform: none;
}

.v-tab--active{
  color: #4364FF !important;
}


.btncursor{
  cursor: pointer;
}

.option_title{
  font-size: 20px;
  color: #515151;
  font-family: 'NotoSansKR-Medium' !important;
}

.event_title{
  font-size:18px;
  color:#3D3D3D;
  font-weight:bold;
  margin-left:15px;
  margin-right:15px;
  font-family: 'NotoSansKR-Medium' !important;
}

.event_title_mb{
  font-size:16px;
  color:#3D3D3D;
  font-family: 'NotoSansKR-Medium' !important;
  font-weight:bold;
}

.event_desc1{
  font-size:14px;
  color:#3D3D3D;
  white-space:pre-wrap;
  margin-left:15px;
  margin-right:15px;
  font-family: 'NotoSansKR-Medium' !important;
}

.event_desc1_mb{
  font-size:13px;
  color:#3D3D3D;
  font-family: 'NotoSansKR-Medium' !important;
  white-space:pre-wrap;
}

.event_desc2{
  font-size:14px;
  color:#3D3D3D;
  white-space:pre-wrap;
  margin-left:15px;
  margin-right:15px;
  font-family: 'NotoSansKR-Regular' !important;
}

.event_desc2_mb{
  font-size:13px;
  color:#3D3D3D;
  font-family: 'NotoSansKR-Regular' !important;
  white-space:pre-wrap;
}

.event_map_title{
  font-size:16px;
  color:#627BFF;
  font-family: 'NotoSansKR-Medium' !important;
  white-space:pre-wrap;
}

.info_blue_text{
  font-size:14px !important;
  color:#627BFF !important;
  font-family: 'NotoSansKR-Regular' !important;
  white-space:pre-wrap;
}

  /* v-select outlinned border 설정 */
  .v-text-field--outlined >>> fieldset {
    border-radius: 0px;
    border: 1px;
    border-color: #DADADA;
  }
  /* v-select 팝업 선택한 아이템 텍스트 컬러 */
  .v-list .v-list-item--active .v-list-item__title {
    color: #627BFF !important;
  }
  /* v-select 팝업 텍스트 컬러 */
  .v-list .v-list-item__title {
    color: #6a6a6a !important;
  }

  .select_text{
    font-size:14px; 
    font-family: 'NotoSansKR-Regular' !important;
    font-weight: 100;
    color: #4364FF !important;
  }
  
</style>