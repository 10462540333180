<template>
  <!-- 404에러 페이지 -->
  <v-container fluid>

    <!-- 
    <v-row align="center" justify="center">
      <v-col cols="10" md="4" sm="10" xs="10">
        <v-img  src="../assets/logo.png" class="mt-15"></v-img>
      </v-col>
    </v-row>
    
    <v-row align="center" justify="center">
      <p style="color:black;display:inline;font-size:22px;font-weight:bold">전시, 박람회, 컨퍼런스 입장권 발급은&nbsp;</p><p style="color:#4e6ef7;display:inline;font-size:22px;font-weight:bold"><strong>페어패스</strong></p><p class="text-center" style="color:black;display:inline;font-size:22px;font-weight:bold">로 PASS!</p>
    </v-row>
    -->

    <v-row class="mt-15" style="background:#4e6ef7;height:100px" align="center" justify="center">
    </v-row>   
    
    <v-row style="background:#4e6ef7;" align="center" justify="center">
        <v-col cols="12" md="12" sm="12" xs="12">
          <p id="login_title" style="color:white;font-size:20px" class="text-center">{{$t('common_msg29')}}</p>
        </v-col>
    </v-row>
    <v-row style="background:#4e6ef7;height:1000px" align="center" justify="center">
    </v-row>   

  </v-container>
</template>

<script>
export default {

}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.roundbg{
  border-radius: 25px;
  background: #ffffff;
  padding: 20px;
  padding-bottom: 30px;
}


</style>