<template>
<!-- 초청장 등록 페이지 (초청장 문자링크 누르면 다이나믹 링크로 웹인 경우 현재 페이지 표시됨.) -->
<v-col cols="12" align-self="center" class="mt-5">

  <!-- 상세내용 -->
  <v-row>
    <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog" fullscreen>
      <v-card>
        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb': 'text_normal_pc'">{{ broad.TITLE }}</span>
          </v-col>
        </v-row>

      <v-card-text class="ma-0 pl-5 pr-5 pt-3">
        <v-container>
            <v-row>
              <v-col md="12" sm="12" xs="12">
                <div v-html="htmlcontent">
                </div>
              </v-col>
            </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center" class="mb-5">
          <v-col cols="4" align="center">
            <v-btn width="90%" outlined @click="dialog = false" large>{{$t('common_msg4')}}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      </v-card>
    </v-dialog>  
  </v-row>    

  <!-- pc 제목 -->
  <v-row>
    <v-col cols="12" style="background:#F1F1F1">
      <div class="text-center" 
          style="color:black;font-weight:bold;white-space:pre-wrap;text-center"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;padding-top:5px;padding-bottom:1px' : 'font-size:25px;padding-top:25px;padding-bottom:10px'"
          @click="goinfo"
          >{{info.NAME}}</div>
      <div style="font-size:10px;text-align:right;"
        :style="$vuetify.breakpoint.smAndDown ? 'height:5px;':'height:15px;'"
        >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">        
            <a v-bind="attrs" v-on="on" style="color:#808080" >{{ $i18n.locale == "ko" ? 'Korean' : 'English'}}</a>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in $lanlist"
              :key="index" @click="lanchange(item)"
              >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-col>
  </v-row>

  <!-- pc 이미지 -->
  <v-row class="">

    <v-col cols="12" md="7" align="center" >
            <v-img
                :aspect-ratio="16/9"
                :src="info.IMAGE"
                style="border-radius: 10px;"
                class="ma-0 ma-md-2"
                >
            </v-img>
    </v-col>

    <!-- pc 옵션선택 영역 -->
    <v-col md="5" align="center" class="hidden-sm-and-down" v-if="dialog == false">

      <v-row align="center" justify="center" dense>

        <v-card
          ref="info_card"
          color="#F9F8F8"
          class="pl-10 pr-10 pt-10 pb-15 mt-2 fixed_info_150"
          :style="'top:'+topscroll+'px !important'"
          elevation="2"
          >

          <v-row justify="center">
            <v-col cols="12">
              <div class="text-h5 text-center" 
                  style="color:black;font-weight:bold;">{{ info.NAME }}</div>
            </v-col>
            <v-col cols="12">
              <div style="width:100%;height:1px;background:black;padding-left:10px;padding-right:10px"/>
            </v-col>
            <v-col cols="12" align="center" class="ma-0 pa-0 mt-5">
              <div style="color:#4D67DF" class="text-h6">{{$t('invitation_label1')}}</div>
            </v-col>
            <v-col md="8" align="center" class="mt-10 pa-0">
              <button class="bottombtn_pc" @click="runinvit">{{$t('invitation_label2')}}</button>
            </v-col>
            <v-col md="8" align="center" class="mt-5 pa-0">
              <button class="bottombtn_pc" @click="runinfo">{{$t('invitation_label3')}}</button>
            </v-col>
          </v-row>

        </v-card>

        
      </v-row>

    </v-col>

  </v-row>


  <v-row class="mt-0 mt-md-5" dense>
    <v-col cols="12" md="7">

    <v-tabs
      v-model="tab"
      fixed-tabs
      background-color="white" color="black"
      center-active show-arrows
      @change="tabchange"
      :class="$vuetify.breakpoint.smAndDown ? 'pl-0 pr-0' : 'pl-10 pr-10'"
      >
      <v-tabs-slider color="#4E6EF7"></v-tabs-slider>
      <v-tab :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'" style="text-transform: none;">
        {{$t('invitation_label4')}}
      </v-tab>
      <v-tab v-if="info.USE_NOTICE != undefined && info.USE_NOTICE != null && info.USE_NOTICE == 1" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"  style="text-transform: none;">
        {{$t('invitation_label5')}}
      </v-tab>
      <v-tab v-if="info.USE_SCHEDULE != undefined && info.USE_SCHEDULE != null &&info.USE_SCHEDULE == 1" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"  style="text-transform: none;">
        {{$t('invitation_label6')}}
      </v-tab>
      <v-tab v-if="info.USE_GALLARY != undefined && info.USE_GALLARY != null &&info.USE_GALLARY == 1" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"  style="text-transform: none;">
        {{$t('invitation_label7')}}
      </v-tab>
      <v-tab v-if="info.USE_MAP != undefined && info.USE_MAP != null &&info.USE_MAP == 1" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"  style="text-transform: none;">
        {{$t('invitation_label8')}}
      </v-tab>
      <!-- 행사정보 -->    
      <v-tab-item>

        <v-row no-gutters dense class="mt-4 mt-md-9 pb-1" align="center">
          <v-col cols="12" align="start" class="pl-5 pr-5 pl-md-0">
          <div class="text-center" 
              style="background:#FCB346;color:#ffffff;font-weight:bold;vertical-align;"
              :style="$vuetify.breakpoint.smAndDown ? 'width:120px;height:30px;font-size:12px;middle;line-height:30px':'width:250px;height:60px;font-size:26px;middle;line-height:60px'"
            >{{ info.TYPENAME }}</div>
          </v-col>
        </v-row>

        <v-row no-gutters dense class="pt-1 pb-1 mt-1 mt-md-2 mt-md-5" align="center" justify="center">
            <v-col cols="12" align="start" class="pl-5 pr-5">
                <label 
                  :style="$vuetify.breakpoint.smAndDown ? 'font-size:17px;color:#000000;font-weight:bold' : 'font-size:25px;color:black;font-weight:bold;' "
                  >{{info.NAME}}</label>
            </v-col>
        </v-row>


        <v-row v-if="info.DESC1 != undefined && info.DESC1 != null && info.DESC1.length > 0" no-gutters dense align="center" justify="center" class="mt-3">
            <v-col cols="12" align="start" class="pl-3 pr-3">
              <div
                :style="$vuetify.breakpoint.smAndDown ? 'font-size:15px;color:#000000;white-space:pre-wrap' : 'font-size:23px;color:#000000;white-space:pre-wrap' "
                >{{ info.DESC1 }}</div>
            </v-col>
        </v-row>

        <v-row no-gutters dense align="center" justify="center" class="mt-md-4 mb-md-4">
            <v-col cols="12" align="center" class="pl-3 pr-3">
              <v-divider style="background:black"></v-divider>
            </v-col>
        </v-row>

        <v-row v-if="info.DESC2 != undefined && info.DESC2 != null && info.DESC2.length > 0" dense align="center" justify="center">
            <v-col cols="12" align="start" class="pl-4 pr-4">
              <p 
              :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;color:#000000;white-space:pre-wrap' : 'font-size:20px;color:#000000;white-space:pre-wrap' "
              >{{ info.DESC2 }}</p>
            </v-col>
        </v-row>

        <v-row no-gutters dense align="center" justify="center">
          <v-col cols="12" align="center" class="pl-2 pr-2">

            <table style="width:100%;">
              <tr  style="border-top-left-radius:20px;" 
                  :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:50px;'"
                >
                <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                    :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px' : 'border-top-width:2px'"
                    style="border-top-left-radius:20px;background:#E5E5E5;font-weight:bold">{{$t('invitation_label9')}}</td>
                <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'"
                    :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px' : 'border-top-width:2px'"
                    style="border-top-right-radius:20px;background:#E5E5E5;font-weight:bold">{{info.NAME}}</td>
              </tr>
              <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:50px;'">
                <td class="text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                  >{{$t('invitation_label10')}}</td>
                <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'"
                  >{{info.EVENT_START+' ~ ' + info.EVENT_END}}</td>
              </tr>
              <template v-if="info.AREANAME != undefined && info.AREANAME != null && info.AREANAME.length > 0">
              <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:50px;'">
                <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'">
                  {{$t('invitation_label11')}}</td>
                <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" >{{info.AREANAME}}{{info.ADDR2 != null ? info.ADDR2  : ''}}</td>
              </tr>
              </template>
              <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:50px;'">
                <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                >{{$t('invitation_label12')}}</td>
                <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" >{{info.ADDR1 != null ? info.ADDR1 : ''}}</td>
              </tr>
              <template v-if="info.HOST != undefined && info.HOST != null && info.HOST.length > 0">
              <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:50px;'">
                <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                >{{$t('invitation_label13')}}</td>
                <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" style="white-space:pre-wrap" >{{info.HOST}}</td>
              </tr>
              </template>
              <template v-if="info.ORGANIZER != undefined && info.ORGANIZER != null && info.ORGANIZER.length > 0">
              <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:50px;'">
                <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                  >{{$t('invitation_label14')}}</td>
                <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" style="white-space:pre-wrap" >{{info.ORGANIZER}}</td>
              </tr>
              </template>
              <template v-if="info.INFO != undefined && info.INFO != null && info.INFO.length > 0">
              <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:50px;'">
                <td class="text-center tabletitle" style="border-bottom-left-radius:20px;"
                  :style="$vuetify.breakpoint.smAndDown ? 'border-bottom-width:1px' : 'border-bottom-width:2px'"
                  :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                  >{{$t('invitation_label15')}}</td>
                <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" 
                  :style="$vuetify.breakpoint.smAndDown ? 'border-bottom-width:1px' : 'border-bottom-width:2px'"
                   style="border-bottom-right-radius:20px;white-space:pre-wrap;">
                  {{ info.INFO == undefined || info.INFO == null ? '' : info.INFO }}
                  </td>
              </tr>
              </template>

            </table>

          </v-col>
      </v-row>


      <template v-if="info.USE_REGDATE == 1 && optionlist != undefined && optionlist.length > 0">
        <v-row no-gutters dense align="center" justify="center" class="mt-10">
            <v-col cols="12" align="center" class="pl-2 pr-2">
              <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12"  align="center" class="pl-2 pr-2">
              <div class="pt-1 pb-1 d-flex align-center" style="background:#e5e5e5;width:100%;height:100%;"
                :style=" $vuetify.breakpoint.smAndDown ? 'border: solid 0px #c9c1c1':'border: solid 2px #c9c1c1'"
                >
                <div style="width:100%;font-weight:bold" 
                  :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                  >{{$t('invitation_label16')}}</div>
              </div>
            </v-col>
        </v-row>
        <v-row no-gutters dense align="center" justify="center" class="mb-2">
            <v-col cols="12"  align="center" class="pl-2 pr-2">
              <v-divider></v-divider>
            </v-col>
        </v-row>

        <v-row v-for="option in optionlist" :key="option.IDX" no-gutters dense align="center" justify="center" >
            <v-col cols="12"  align="start" class="pl-5 pr-5 pl-1 pb-1 mt-2">
              <div style="font-weight:bold" :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                {{ option.NAME }}
              </div>
              
              <div :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                {{ $t('invitation_label17') + option.REG_START + "~" + option.REG_END }}
              </div>
            </v-col>
            <v-col cols="12"  align="center" class="pl-2 pr-2 mt-2">
              <v-divider></v-divider>
            </v-col>
        </v-row>

      </template>

      <template v-if="info.USE_REFUND ==1 && refundlist != undefined && refundlist.length > 0">
        <v-row no-gutters align="center" justify="center" class="mt-7">
            <v-col cols="12"  align="center" class="pl-2 pr-2">
              <div class="pt-1 pb-1 d-flex align-center" style="background:#e5e5e5;width:100%;height:100%;"
                :style=" $vuetify.breakpoint.smAndDown ? 'border: solid 0px #c9c1c1':'border: solid 2px #c9c1c1'"
                >
                <div style="width:100%;font-weight:bold" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'">{{$t('invitation_label18')}}</div>
              </div>
            </v-col>
        </v-row>
        <v-row no-gutters dense align="center" justify="center" class="mb-2">
            <v-col cols="12"   align="center" class="pl-2 pr-2">
              <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row v-for="refund in refundlist" :key="refund.IDX" no-gutters dense align="center" justify="center" >
            <v-col cols="12"  align="start" class="pl-5 pr-5 pl-1 pb-1 mt-1">
              <div style="color:rgb(113,113,250);"
                  :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'"
              >{{ refund.REFUND == "환불불가" ? refund.DAY + ' ' + refund.REFUND : refund.DAY + ' ' + refund.RATE + ' ' + refund.REFUND }}</div>
            </v-col>
        </v-row>
      </template>

        <v-row no-gutters dense align="center" justify="center" class="mt-5 d-flex justify-space-around">
          <template v-if="info.LINK_URL != null && info.LINK_URL.length > 0" align="center">
            <v-btn outlined color="gray" @click="showlink(info.LINK_URL)"
              :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'"
              :style="$vuetify.breakpoint.smAndDown ? 'width:60px;height:34px;font-size:10px':'width:190px;height:54px;font-size:24px'"
              ><span style="color:black;font-weight:bold;">{{$t('invitation_label19')}}</span></v-btn>
          </template>
          <template v-if="info.YOUTUBE != null && info.YOUTUBE.length > 0" align="center">
            <v-btn width="90%" outlined color="gray" @click="showlink(info.YOUTUBE)"
              :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'"
              :style="$vuetify.breakpoint.smAndDown ? 'width:60px;height:34px;font-size:10px':'width:190px;height:54px;font-size:24px'"
              ><span style="color:black;font-weight:bold;">YOUTUBE</span></v-btn>
          </template>
          <template v-if="info.LATITUTE != null && info.LATITUTE.length > 0 && info.LONGITUTE != null && info.LONGITUTE.length > 0" 
            align="center">
            <v-btn width="90%" outlined color="gray" @click="showmap"
              :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'"
              :style="$vuetify.breakpoint.smAndDown ? 'width:60px;height:34px;font-size:10px':'width:190px;height:54px;font-size:24px'"
              ><span style="color:black;font-weight:bold;">{{$t('invitation_label20')}}</span></v-btn>
          </template>
          <template align="center">
            <v-btn color="#e4d83b" style="background:#e4d83b" @click="sharekakao()" outlined
              :style="$vuetify.breakpoint.smAndDown ? 'width:60px;height:34px;font-size:12px':'width:96px;height:50px;font-size:24px'"
              >
              <v-icon color="white">mdi-share-variant-outline</v-icon>
            </v-btn>
          </template>
        </v-row>

        <v-row style="height:200px">
        </v-row>

        <v-bottom-navigation
          class="pa-0 ma-0 hidden-md-and-up"
          app
          color="indigo" 
          style="display:block;background:#f9f8f8;overflow-y: auto;"
          height="210px" 
          >
          <v-row class="ma-0 pa-0 mt-3" justify="center">
            <v-col cols="10" align="center" class="ma-0 pa-0 mt-1">
              <div style="color:black;white-space:pre-wrap;text-center;font-size:14px;">{{info.NAME}}</div>
            </v-col>
            <v-col cols="12" align="center" class="ma-0 pa-0 pl-10 pr-10 mt-1">
              <v-divider style="background:black"></v-divider>
            </v-col>
            <v-col cols="12" align="center" class="ma-0 pa-0 mt-2">
              <div style="color:#4d67df;font-size:12px">{{$t('invitation_label21')}}</div>
            </v-col>
            <v-col cols="10" md="7" align="center" class="mt-2 pa-0">
              <button class="bottombtn" style="height:30px" @click="runinvit">{{$t('invitation_label22')}}</button>
            </v-col>
            <v-col cols="10" md="7" align="center" class="mt-2 pa-0">
              <button class="bottombtn" style="height:30px" @click="runinfo">{{$t('invitation_label23')}}</button>
            </v-col>
          </v-row>

        </v-bottom-navigation>      

      </v-tab-item>


      <!-- 공지사항 탭 -->
      <v-tab-item>
        <v-row class="mt-5"/>
        <template v-if="boardlist.length > 0">
          <v-row v-for="item in boardlist" :key="item.IDX" no-gutters dense align="center" justify="center" >
              <v-col @click="shownotice(item)" cols="12" align="start" class="pl-2 pr-2 pl-1 pb-1 mt-2">
                <div style="color:black;font-weight:bold"
                     :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'"
                  >{{ item.TITLE }}</div>
                <div style="color:rgb(113,113,113);"
                     :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'"
                  >{{ item.REG_DATE }}</div>
              </v-col>
              <v-col cols="12"  align="center" class="pl-2 pr-2 mt-1">
                <v-divider></v-divider>
              </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row align="center" justify="center" >
            <v-col  cols="12" align="center">
              <label style="color:#808080">{{$t('invitation_label24')}}</label>
            </v-col>
          </v-row>
        </template>

      </v-tab-item>

      <v-tab-item >
        <v-row class="mt-5"/>
        <template v-if="schedulelist.length > 0">
          <v-row v-for="item in schedulelist" :key="item.NUMBER" no-gutters dense align="center" justify="center" >
            <template v-if="item.SORTTYPE == 1">
              <v-col  cols="12" align="start" class="pl-2 pr-2 pl-1 pb-1 mt-5">
                <div style="font-size:20px;color:rgb(62,62,62);">{{ item.SDATE + " " + daycodeToString(item.DAYCODE) }}</div>
              </v-col>
            </template>
            <template v-else>
              <v-col style="background:white" cols="12" align="start" class="pl-2 pr-2 pl-1 pb-1">
                <div class="mt-3 mb-1" style="font-size:16px;color:#5f67ec;">{{ item.NAME }}</div>
                <v-divider/>
                <div class="mt-1 mb-1" style="font-size:15px;color:rgb(92,92,92);">{{ $t('invitation_label25') + item.START_TIME.substring(0,5) + " ~ " + item.END_TIME.substring(0,5) }}</div>
                <div class="mt-1 mb-1" style="font-size:15px;color:rgb(92,92,92);">{{ $t('invitation_label26') + item.LOCATION }}</div>
                <div class="mt-5 mb-1" style="font-size:15px;color:#000000;white-space:pre-wrap;">{{ item.DESC }}</div>
              </v-col>
            </template>
          </v-row>
        </template>
        <template v-else>
          <v-row align="center" justify="center" >
            <v-col  cols="12" align="center">
              <label style="color:#808080">{{$t('invitation_label24')}}</label>
            </v-col>
          </v-row>
        </template>
      </v-tab-item>

      <v-tab-item>
        <v-row class="mt-5"/>
        <template v-if="gallist.length > 0">
          <v-row v-for="item in gallist" :key="item.IDX" no-gutters dense align="center" justify="center" >
            <v-col  cols="8" align="center" class="pl-2 pr-2 pl-1 pb-1">
              <v-img @click="clickimg(item.IMAGE)" style="border-radius: 10px;margin-top:20px" :src="item.IMAGE"></v-img>        
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row align="center" justify="center" >
            <v-col  cols="12" align="center">
              <label style="color:#808080">{{$t('invitation_label24')}}</label>
            </v-col>
          </v-row>
        </template>
      </v-tab-item>

      <v-tab-item>
        <v-row class="mt-5"/>
        <template v-if="maplist.length > 0">
          <v-row v-for="item in maplist" :key="item.IDX" no-gutters dense align="center" justify="center" >
            <v-col cols="12" align="start" class="pl-2 pr-2 pl-1 pb-1 mt-5">
              <div style="font-size:20px;color:black">{{ item.TITLE }}</div>
              <v-divider/>
              <v-img @click="clickimg(item.IMAGE)" class="mt-3" style="border-radius: 10px;" :src="item.IMAGE"></v-img>     
              <div class="mt-3" style="font-size:14px;color:rgb(91,91,91);white-space:pre-wrap;">{{ item.DESC }}</div>
              <v-btn @click="showlink(item.LINK)" class="mt-3" color="#5f67ec" v-if="item.LINK != null && item.LINK.length > 0" outlined>{{$t('invitation_label28')}}</v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row align="center" justify="center" >
            <v-col  cols="12" align="center">
              <label style="color:#808080">{{$t('invitation_label24')}}</label>
            </v-col>
          </v-row>
        </template>

      </v-tab-item>

    </v-tabs>

    </v-col>
  </v-row>

</v-col>
</template>

<script>
export default {

  data: () => ({
    tab: null,
    dialog: false,
    value: 1,
    bottomstate:true,
    broad:{},
    htmlcontent:"",
    
    MSG:"",
    CODEVAL:0,  //0이면 없는 초청장
    INVITCODE:"",
    
    info:{},
    invitinfo:{},
    optionlist:[],
    refundlist:{},

    boardlist:{},
    schedulelist:{},
    gallist:{},
    maplist:{},
    
    topscroll: 150,
    EVENTCODE:"",

    tablist:[],
    lan_code:0,
    first_flag:true,

    
  }),

  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    window.scrollTo(0,0);
    
    window.addEventListener('scroll', this.handleScroll);

    //path를 통해서 들어올 경우 ex) /경로/값
    //console.log("get code " + JSON.stringify(this.$route.query.code));
    this.INVITCODE = this.$route.query.code;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    this.loadinvitcode();
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    goinfo(){
      this.$router.push({path:"RunEventInfo", query:{eventcode:this.EVENTCODE, lan_code:this.lan_code}});
      location.reload();
    },

    lanchange(item){
      if(item.value == "ko"){
        this.lan_code = 0;
        this.$i18n.locale = "ko";
      }else{
        this.lan_code = 1;
        this.$i18n.locale = "en";
      }
      this.loadpage();
    },

    handleScroll() {
      this.topscroll = 150 - window.scrollY;
      if(this.topscroll < 23){
        this.topscroll = 23;
      }
    },
    
    runinvit(){
      //this.$router.replace({name:"agree", params:{code:this.INVITCODE, name:this.info.NAME}});
      if(this.info.USE_PRICY == 1){
        this.$router.push({path:"/Agree", query:{code:this.INVITCODE, name:this.info.NAME, lan_code:this.lan_code, use_mkt:this.info.USE_MKT}});
      }else{
        this.$router.push({path:"/Join", query:{code:this.INVITCODE, name:this.info.NAME, lan_code:this.lan_code}});
      }
    },

    //등록정보 확인하기
    runinfo(){
      this.$router.push({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE, optioncode:this.invitinfo.OPTION_IDX, lan_code:this.lan_code}});
    },

    daycodeToString(daycode){
      var dayname = "";
      if(daycode == "0"){
        dayname = this.$t('common_msg12');
      }else if(daycode == "1"){
        dayname = this.$t('common_msg13');
      }else if(daycode == "2"){
        dayname = this.$t('common_msg14');
      }else if(daycode == "3"){
        dayname = this.$t('common_msg15');
      }else if(daycode == "4"){
        dayname = this.$t('common_msg16');
      }else if(daycode == "5"){
        dayname = this.$t('common_msg17');
      }else if(daycode == "6"){
        dayname = this.$t('common_msg18');
      }
      return dayname;
    },

    tabchange(tabindex){
  
      let tabname = this.tablist[tabindex];

      if(tabname == "notice"){
        if(this.boardlist.length == undefined){
          this.loadbroad();
        }
      }else if(tabname == "schedule"){
        if(this.schedulelist.length == undefined){
          this.loadschedule();
        }
      }else if(tabname == "gallery"){
        if(this.gallist.length == undefined){
          this.loadgal();
        }
      }else if(tabname == "map"){
        if(this.maplist.length == undefined){
          this.loadmap();
        }
      }

    },
    
    shownotice(item){
      this.broad = item;
      var html = "";
      html += this.broad.DESC;
      if(item.FILE1 != null && item.FILE1.length > 0){
        const last = item.FILE1.split("/")
        item.FILENAME =  decodeURI(last[last.length-1]);
        html += "<a href="+item.FILE1+" download><p>"+this.$t('common_msg19')+item.FILENAME+"</p></a>"
      }
      this.htmlcontent = "<!DOCTYPE HTML><html></html><head><meta name='viewport' content='width=device-width, initial-scale=1'/><style>img{ width:100% }</style></style></head><body>"+html+"</body></html>";
      this.dialog = true;
    },

    clickimg(imgsrc){
      let infomsg = this.$t('common_msg20');
      var w = window.open("", infomsg);
      w.document.write(`<!DOCTYPE html>
      <html><head><meta name='viewport' content='width=device-width, initial-scale=1'/><img src=`+imgsrc+`></img></head><body></body>
      </html>`);        
      w.document.close(); // necessary for IE >= 10
      w.focus();          // necessary for IE >= 10
    },

    showlink(link){
      window.open(link);
    },
    showmap(){
      window.open("https://www.google.com/maps/search/?api=1&query="+this.info.LATITUTE+","+this.info.LONGITUTE);
    },

    loadinvitcode(){
      //초청장 정보 가져오기
      this.$http.post(this.$host+'/ime/InvitCodeInfo',{
            INVITCODE:this.INVITCODE
          , ADMIN_IDX:1
          , LAN_CODE:this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
              this.CODEVAL = 1;   //일단 초청장은 유효한 상태
              this.invitinfo = result.data.resultData[0];
              this.EVENTCODE = this.invitinfo.EVENT_IDX;
              this.loadpage();
              
              //console.log("info : " + JSON.stringify(result.data.resultData[0]));
              if(this.invitinfo.USESTATE == 0){
                //미사용
                this.MSG = this.$t('invitation_label21');
                
                if(this.invitinfo.REG_DATE_VAL == 0){
                  //입장권 옵션 등록기간 지남.
                  this.CODEVAL = 0;
                  this.MSG = this.$t('invitation_label29');
                  return;
                }
              }else{
                //사용됨
                this.MSG = this.$t('invitation_label30');
              }

              if(this.invitinfo.EVENT_DATE_VAL == 0){
                //행사기간 지남.
                this.CODEVAL = 0;
                this.MSG = this.$t('invitation_label31');
                return;
              }

              

          }else{
              //없는 초청장인 경우
              this.CODEVAL = 0;
              this.MSG = this.$t('invitation_label32');
              this.$alert(result.data.resultMsg).then(()=>{
                this.$router.push({path:"/"});
              });
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
            this.$alert(error.message);
          }
      });      
    },


    loadpage(){

      this.$http.post(this.$host+'/ime/EventDetailAll',{
            EVENT_IDX:this.EVENTCODE
          , ADMIN_IDX:1
          , LAN_CODE: this.first_flag == true ? null : this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.info = result.data.resultData[0];

            //최초 조회는 행사의 언어 설정에 따른다.
            if(this.first_flag){
              this.lan_code = this.info.USE_LAN;
              this.first_flag = false;
            }            

            this.optionlist = result.data.optionData;
            this.refundlist = result.data.refundData;

            //탭정보 지정
            this.tablist.push("info");  //기본정보는 무조건 추가
            if(this.info.USE_NOTICE != undefined && this.info.USE_NOTICE != null && this.info.USE_NOTICE == 1){
              this.tablist.push("notice");
            }
            if(this.info.USE_SCHEDULE != undefined && this.info.USE_SCHEDULE != null && this.info.USE_SCHEDULE == 1){
              this.tablist.push("schedule");
            }
            if(this.info.USE_GALLARY != undefined && this.info.USE_GALLARY != null && this.info.USE_GALLARY == 1){
              this.tablist.push("gallery");
            }
            if(this.info.USE_MAP != undefined && this.info.USE_MAP != null && this.info.USE_MAP == 1){
              this.tablist.push("map");
            }

          }else{
            this.$alert(this.$t('invitation_label33'));
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },


    loadbroad(){

      this.$http.post(this.$host+'/ime/EventNoticeList',{
          EVENT_IDX:this.EVENTCODE
        , LAN_CODE:this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.boardlist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },

    loadschedule(){

      this.$http.post(this.$host+'/ime/EventScheduleList',{
        EVENT_IDX:this.EVENTCODE
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.schedulelist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },

    
    loadgal(){

      this.$http.post(this.$host+'/ime/EventGalleryList',{
          EVENT_IDX:this.EVENTCODE
        , pagerow: 100
        , page: 1
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.gallist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },
    
    loadmap(){

      this.$http.post(this.$host+'/ime/EventMapList',{
          EVENT_IDX:this.EVENTCODE
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.maplist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    }


  },

}
</script>

<style scoped>

/*
  top: 160px;
  right: 90px;
  width: 630px;
*/
.fixed_info_150{
  position: fixed;
  z-index:10;
  width:30%;
  min-width: 390px;
}

.fixed_info_50{
  position: fixed;
  top: 50px;
  right: 90px;
  z-index:9999;
  width: 630px;
}

.bottombtn_pc{
  width:100%; height:50px;
  font-size:20px; font-weight: bold;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}

.bottombtn{
  width:90%; height:40px;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}

.bottombtn:hover{
  background-color: #2d2f77 !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #E5E5E5;
}

.tabletitle{
  width:30%;
  font-size:20px;
  color:black;
  border-left-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-top-width: 0px;
}
.tabletitle_mb{
  width:30%;
  font-size:14px;
  color:black;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
}

.tabledesc{
  width:70%;
  font-size:20px;
  color:black;
  border-right-width: 2px;
  border-left-width: 0px;
  border-bottom-width: 2px;
  border-top-width: 0px;
}
.tabledesc_md{
  width:70%;
  font-size:14px;
  color:black;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-top-width: 0px;
}


table{
  border-radius: 20px;
  border-collapse: separate; 
  border-spacing:0;
  border: 0px solid #C9C1C1;
}

td {
  border: 1px solid #C9C1C1;
}


.text_normal_pc{
  font-size:18px !important;
  color:#817b7b !important;
}

.text_normal_mb{
  font-size:14px;
  color:#817b7b;
}



</style>