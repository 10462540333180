<template>
  <!-- 이메일 수신거부 등록 페이지 -->
  <v-container fluid>
    <v-row class="mt-15" justify="center">
        <v-col cols="11" align="center">
            <label style="font-size:20px;color:#ff0000">{{$t('emailblock_label1')}}</label>
        </v-col>
    </v-row>
    <v-row class="mt-5" justify="center">
        <v-col cols="11">
            <label>{{$t('emailblock_label2')}}</label>
        </v-col>
    </v-row>
    <v-row class="mt-15" justify="center">
      <v-btn @click="close" color="blue" style="color:white">{{$t('emailblock_label3')}}</v-btn>
      <v-btn class="ml-3" @click="sendEmailblock" color="red" style="color:white">{{$t('emailblock_label4')}}</v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {

    data: () => ({
      mail:'',
      lan_code:0, //한국어
    }),

    mounted(){
      
      this.mail = this.$route.query.mail;
      
      if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
        this.lan_code = Number(this.$route.query.lan_code);
      }else{
        this.lan_code = 0;
      }
      if(this.lan_code == 0){
        this.$i18n.locale = 'ko';
      }else{
        this.$i18n.locale = 'en';
      }

    },

    create(){

    },

    methods: {

      close(){
        top.window.open('https://fairpass.world/','_self').close();
        top.window.opener=self;
        top.self.close();
        //this.$router.push("/");
      },

      //이메일 발송내역 상세 확인
      sendEmailblock(){
        this.$http.post(this.$host+'/ime/EmailUnsubscriber',{
          EMAIL: this.mail
        })
        // eslint-disable-next-line no-unused-vars
        .then((result)=>{
            this.$alert(this.$t("emailblock_label5")).then(() => {
                this.close();
            });      
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
            this.$alert(error.message);
          }
        });        
      },            


    },
  
}
</script>

<style lang="css">
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
</style>