<!-- 이름/전화번호를 입력하고 입장권 확인시 -->
<template>

    <table style="width:100%;border-collapse: collapse;">
        <tr>
          <td class="infotitle text-center" style="border-top-width: 1px;">{{$t('runeventinfo_label4')}}</td>
          <td class="infodesc" style="border-top-width: 1px;">{{ visitor.INFO1 }}</td>
        </tr>
        <tr v-if="visitor.TEL.length > 0">
          <td class="infotitle text-center">{{$t('runeventinfo_label5')}}</td>
          <td class="infodesc">{{ visitor.TEL }}</td>
        </tr>
        <tr>
          <td class="infotitle text-center" style="padding-bottom:16px">{{$t('runeventinfo_label6')}}</td>
          <td class="infodesc" style="padding-bottom:16px">{{ visitor.OPTION_NAME }}</td>
        </tr>
      </table>

</template>
  
  <script>

  export default {
    
    props:{
        visitor: Object,
    },

    data: () => ({
  
  
    }),
  
    mounted(){  
  
  
    },
  
    methods: {
      
    }
  
  }
  </script>
    
  <style scoped>
  
  
  table{
    border-radius: 0px;
    border-collapse: separate; 
    border-spacing:0;
    border: 0px solid #DADADA;
  }
  
  td {
    border: 1px solid #DADADA;
  }

.infotitle{
    width:111px !important;
    padding-top: 16px;
    min-width: 111px;
    font-size:14px;
    color:#3D3D3D;
    border-left-width: 0px;
    border-right-width: 1px;
    border-bottom-width: 0px;
    border-top-width: 0px;
    white-space: pre-wrap;
    font-family: 'NotoSansKR-Regular' !important;
  }
  
  .infodesc{
    font-size:14px;
    padding-top: 16px;
    padding-left: 30px;
    padding-right: 30px;
    color:#3D3D3D;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-top-width: 0px;
    white-space: pre-wrap;
    font-family: 'NotoSansKR-Medium' !important;
  }
  
  </style>