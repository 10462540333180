<template>
<!-- QR앱 업체 로그인 페이지 -->
<v-col cols="12" align-self="center">

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="purple"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">{{$t('common_msg11')}}</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>


  <!-- pc 제목 -->
  <v-row style="background:#F1F1F1">
    <v-col cols="1" @click="back"><v-icon>mdi-chevron-left</v-icon></v-col>
    <v-col cols="10">
      <div class="text-center" 
           style="color:black;font-weight:bold;white-space:pre-wrap;text-center"
           :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;padding-top:5px;padding-bottom:5px' : 'font-size:25px;padding-top:25px;padding-bottom:25px'"
           >{{info.NAME}}</div>
    </v-col>
    <v-col cols="1"></v-col>
  </v-row>

  <!-- pc 이미지 -->
  <v-row class="mt-10">
    <v-col cols="12" md="7" align="center" >
            <v-img
                :aspect-ratio="16/9"
                :src="info.IMAGE"
                style="border-radius: 10px;"
                class="ma-0 ma-md-2"
                >
            </v-img>
    </v-col>
  </v-row>


  <v-row class="ma-0 pa-0 mt-10" justify="center">
    <v-col cols="9" md="6" align="center" class="ma-0 pa-0 mt-1">
      <p id="login_title" style="color:#4e6ef7;font-size:20px;font-weight:bold" class="text-center">{{$t('applogin_label1')}}</p>
    </v-col>
    <v-col cols="10" md="6" align="center" class="ma-0 pa-0 mt-1">
      <v-text-field 
        outlined v-model="user_id"  autocapitalize="off"
        name="login" :placeholder="$t('applogin_label2')" background-color="#ffffff"
        color="#4e6ef7" hide-details prepend-inner-icon="mdi-account-circle-outline" 
        type="text" dense></v-text-field>
      <v-text-field 
        class="mt-2" autocapitalize="off"
        outlined @keyup.enter="login" v-model="user_pw" prepend-inner-icon="mdi-lock" 
        name="password" :placeholder="$t('applogin_label3')" id="password" background-color="#ffffff"
        type="password" dense color="#4e6ef7" hide-details></v-text-field>
      <v-checkbox
            v-model="checkbox"
            :label="$t('applogin_label4')" dense @change="checkchange"
          ></v-checkbox>            
    </v-col>

    <v-col cols="8" md="7" align="center" class="mt-2 pa-0">
      <button class="bottombtn" style="height:40px" @click="login">{{$t('applogin_label1')}}</button>
    </v-col>
  </v-row>

</v-col>
</template>

<script>
export default {

  data: () => ({
    
    
    value: 1,
    bottomstate:true,
    broad:{},
    htmlcontent:"",
    
    visitor:null,
    info:{},
    
    topscroll: 150,

    EVENTCODE:"",
    ADMIN_IDX:"",
    COM_IDX:"",

    user_id : "",
    user_pw : "",
    loading:false,
    checkbox:false,

    lan_code:0, //한국어
  }),


  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    window.scrollTo(0,0);
    this.EVENTCODE    = this.$route.query.eventcode;

    this.checkbox = localStorage.getItem("SAVE_PLACE_ID");
    if(this.checkbox){
      this.user_id = localStorage.getItem("LOGIN_PLACE_ID");
    }

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    this.loadpage();
    
  },

  beforeDestroy() {
    
  },

  

  methods: {

    checkchange(value){
      //console.log(value);
      if(value){
        localStorage.setItem("SAVE_PLACE_ID", true);
      }else{
        localStorage.setItem("SAVE_PLACE_ID", false);
      }
    },

    back(){
      this.$router.back();
    },
    
    login(){
      
      if(this.user_id.length < 1){
        this.$alert(this.$t('applogin_label5'));
        return;
      }
      if(this.user_pw.length < 1){
        this.$alert(this.$t('applogin_label6'));
        return;
      }
      
      localStorage.setItem("LOGIN_PLACE_ID", this.user_id);

      this.loading = true;
      this.$http.post(this.$host+'/ime/PartLogin',{
          USER_ID: this.user_id
        , USER_PW: Buffer.from(this.user_pw).toString('base64')
        , EVENT_IDX: this.EVENTCODE
      })
      .then((result)=>{

        this.loading = false;
        if(result.data.resultCode == 0){
            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            this.$router.push({path:"/AppSelect", query:{adminidx:result.data.resultData[0].ADMIN_IDX, comidx:result.data.resultData[0].COM_IDX, eventcode:this.EVENTCODE, placeidx:result.data.resultData[0].IDX, lan_code:this.lan_code}});

        }else{
          this.$alert(this.$t('applogin_label7'));
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        this.$alert(this.$t('applogin_label7'));
      });


    },


    loadpage(){

      this.$http.post(this.$host+'/ime/EventDetailAll',{
            EVENT_IDX:this.EVENTCODE
          , ADMIN_IDX:1
          , LAN_CODE: null
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.info = result.data.resultData[0];
            this.ADMIN_IDX  = this.info.ADMIN_IDX;
            this.COM_IDX    = this.info.COM_IDX;
            this.optionlist = result.data.optionData;
            this.refundlist = result.data.refundData;
          }else{
            this.$alert(this.$t('applogin_label8'));
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
              this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },



  },

}
</script>

<style scoped>

/*
  top: 160px;
  right: 90px;
  width: 630px;
*/
.fixed_info_150{
  position: fixed;
  z-index:10;
  width:30%;
  min-width: 390px;
}

.fixed_info_50{
  position: fixed;
  top: 50px;
  right: 90px;
  z-index:9999;
  width: 630px;
}

.bottombtn_pc{
  width:100%; height:50px;
  font-size:20px; font-weight: bold;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}

.bottombtn{
  width:90%; height:40px;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}

.bottombtn:hover{
  background-color: #2d2f77 !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #E5E5E5;
}

.tabletitle{
  width:30%;
  font-size:20px;
  color:black;
  border-left-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-top-width: 0px;
}
.tabletitle_mb{
  width:30%;
  font-size:14px;
  color:black;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
}

.tabledesc{
  width:70%;
  font-size:20px;
  color:black;
  border-right-width: 2px;
  border-left-width: 0px;
  border-bottom-width: 2px;
  border-top-width: 0px;
}
.tabledesc_md{
  width:70%;
  font-size:14px;
  color:black;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-top-width: 0px;
}


table{
  border-radius: 20px;
  border-collapse: separate; 
  border-spacing:0;
  border: 0px solid #C9C1C1;
}

td {
  border: 1px solid #C9C1C1;
}


.text_normal_pc{
  font-size:18px !important;
  color:#817b7b !important;
}

.text_normal_mb{
  font-size:14px;
  color:#817b7b;
}


</style>