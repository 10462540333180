<template>
  <!-- QR앱 업체 로그인을 위해 진행중인 행사리스트 선택하는 페이지 -->
  <v-container fluid>

  <v-row style="background:#F1F1F1">
    <v-col cols="1"></v-col>
    <v-col cols="10">
      <div class="text-center" 
           style="color:black;font-weight:bold;white-space:pre-wrap;text-center"
           :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;padding-top:5px;padding-bottom:5px' : 'font-size:25px;padding-top:25px;padding-bottom:25px'"
           >{{$t('apparealist_label1')}}</div>
    </v-col>
    <v-col cols="1"></v-col>
  </v-row>


  <v-row align="start" >

    <v-row dense justify="center" v-for="item in itemList" :key="item.IDX" @click="gologin(item)">
      <v-col cols="3"  class="ma-0">
        <v-img :src="item.IMAGE" height="60" width="200" contain/>
      </v-col>
      <v-col cols="8" align-self="center" class="ma-0">
        <label style="font-size:15px">{{ item.NAME }}</label>
        <td style="font-size:12px">{{ item.START_DATE+' ~ '+item.END_DATE }}</td>
      </v-col>
      <v-col cols="11" align-self="center" class="ma-0 pa-0">
        <div style="background:gray;height:1px"/>
      </v-col>
    </v-row>

  </v-row>



  </v-container>
</template>
<script>
export default {

  beforeDestroy(){

  },

  mounted(){

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    this.initpage();
  },

  data: () => ({
    loading : false,    //로딩 표시
    //테이블 정의
    headers: [
      {text: '위치', value: 'PLACENAME', sortable: false, align:'center' },
      {text: '이름', value: 'NAME', sortable: false, align:'center'},
      {text: '상태', value: 'STATE_NAME', sortable: false, align:'center'},
      {text: '회원', value: 'USER_TYPENAME', sortable: false, align:'center'},
      {text: '전화번호', value: 'TEL', sortable: false, align:'center' },
      {text: '관람일', value: 'SHOWDATE', sortable: false, align:'center' },
      {text: '입장시간', value: 'INTIME', sortable: false, align:'center' },
      {text: '퇴장시간', value: 'OUTTIME', sortable: false, align:'center' },
      {text: '관람시간(분)', value: 'SHOWMIN', sortable: false, align:'center' },
    ],
    itemList: [],
    list:[],
    lan_code:0, //한국어
  }),

  methods: {


    initpage(){

      this.loadpage(1);
    },

    loadpage(p){

      this.itemList.splice(0, this.itemList.length);   //데이터 초기화
      this.loading = true;
      this.page = p;

      this.$http.post(this.$host+'/ime/EventList',{
          ADMIN_IDX: 1
        , LAN_CODE: this.$i18n.locale
      })
      .then((result)=>{
        if(result.data.resultCode == 0){
            const list = result.data.resultData;
            list.forEach(element => {
                this.itemList.push(element); 
            });
            this.loading = false;
        }else if(result.data.resultCode == 2){
          //로그인 필요
          this.loading = false;
          this.$alert(this.$t('apparealist_label2'));
        }else{
          this.loading = false;
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert(this.$t('common_msg1'));
        }else{
          this.$alert(error.message);
        }
      });

    },    

    search_run(){

      this.loadpage(1);

    },

    gologin(item){
      this.$router.push({path:"/AppLogin", query:{eventcode:item.IDX, lan_code:this.lan_code}});
    },


  }
    
}

</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

  .search_btn_freesize {
    color: #2f2f2f !important;
    background-color: #e5e5e5 !important;
    border: thin solid #d1d1d1 !important;
    font-size: 16px !important;
    height: 43px !important;
  }

  .dialog_close_btn {
    color: #e5e5e5 !important;
    background-color: #ffffff !important;
    border-color:#e5e5e5 !important; 
    border-style:solid !important; 
    border-width:3px 3px 3px 3px !important;
  }


</style>