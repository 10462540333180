<template>
<!-- QR앱 메인화면 -->
  <v-col cols="12" align-self="center">

  <!-- pc 제목 -->
  <v-row style="background:#F1F1F1">
    <v-col cols="1" @click="back"><v-icon>mdi-chevron-left</v-icon></v-col>
    <v-col cols="10">
      <div class="text-center" 
           style="color:black;font-weight:bold;white-space:pre-wrap;text-center"
           :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;padding-top:5px;padding-bottom:5px' : 'font-size:25px;padding-top:25px;padding-bottom:25px'"
           >{{$t('appselect_label1')}}</div>
    </v-col>
    <v-col cols="1"></v-col>
  </v-row>

  <v-row class="ma-0 pa-0 mt-15" justify="center">
    <v-btn width="200" height="200" @click="qrscan">
      <div>
        <v-icon style="display:block;" size="100">mdi-qrcode</v-icon>
        <label>{{$t('appselect_label2')}}</label>
      </div>
    </v-btn>
  </v-row>

  <v-row class="ma-0 pa-0 mt-10" justify="center">
    <v-btn width="200" height="200" @click="movepage">
      <div>
        <v-icon style="display:block;" size="100">mdi-account</v-icon>
        <label>{{$t('appselect_label3')}}</label>
      </div>
    </v-btn>
  </v-row>

  <v-row class="ma-0 pa-0 mt-10" justify="center">
    <v-btn width="200" height="200" @click="setting">
      <div>
        <v-icon style="display:block;" size="100">mdi-cog</v-icon>
        <label>{{$t('appselect_label4')}}</label>
      </div>
    </v-btn>
  </v-row>

</v-col>
</template>

<script>
export default {

  data: () => ({
    dialog: false,
    topscroll: 150,
    
    EVENTCODE:"",
    ADMIN_IDX:"",
    COM_IDX:"",
    PLACE_IDX:"",

    loading:false,
    lan_code:0, //한국어
  }),


  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    window.scrollTo(0,0);
    this.ADMIN_IDX    = this.$route.query.adminidx;
    this.COM_IDX      = this.$route.query.comidx;
    this.EVENTCODE    = this.$route.query.eventcode;
    this.PLACE_IDX    = this.$route.query.placeidx;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    if(this.$i18n.locale == 'ko'){        
      this.$alert_option.confirmButtonText = "확인";
      this.$alert_option.cancelButtonText  = "취소";        
    }else{        
      this.$alert_option.confirmButtonText = "OK";
      this.$alert_option.cancelButtonText  = "Cancel";
    }

  },

  beforeDestroy() {
  },

  

  methods: {


    back(){
      this.$router.back();
    },
    
    //스캔하기
    async qrscan(){
      //자바스크립트 인터페이스로 플러터 호출
      try{
        //플러터 웹뷰에 등록된 자바스크립트 함수 호출 웹=>플러터 호출
        // eslint-disable-next-line no-unused-vars
        window.flutter_inappwebview.callHandler('Qrscan', this.COM_IDX, this.EVENTCODE, this.PLACE_IDX).then(function(result) {
          //플러터에서 값을 리턴하는 경우 받아서 사용 가능
          //console.log(result);
        });
      }catch(error){
        //플러터 환경이 아닌 경우
      }
    },

    //조회하기
    movepage(){
      this.$router.push({path:"/AppEventComout", query:{adminidx:this.ADMIN_IDX, comidx:this.COM_IDX, eventcode:this.EVENTCODE, placeidx:this.PLACE_IDX, lan_code:this.lan_code}});
    },    
    
    //셋팅이동
    async setting(){
      //자바스크립트 인터페이스로 플러터 호출
      try{
        //플러터 웹뷰에 등록된 자바스크립트 함수 호출 웹=>플러터 호출
        // eslint-disable-next-line no-unused-vars
        window.flutter_inappwebview.callHandler('Setting').then(function(result) {
          //플러터에서 값을 리턴하는 경우 받아서 사용 가능
          //console.log(result);
        });
      }catch(error){
        //플러터 환경이 아닌 경우
      }
    }

  },

}
</script>

<style scoped>

/*
  top: 160px;
  right: 90px;
  width: 630px;
*/
.fixed_info_150{
  position: fixed;
  z-index:10;
  width:30%;
  min-width: 390px;
}

.fixed_info_50{
  position: fixed;
  top: 50px;
  right: 90px;
  z-index:9999;
  width: 630px;
}

.bottombtn_pc{
  width:100%; height:50px;
  font-size:20px; font-weight: bold;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}

.bottombtn{
  width:90%; height:40px;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}

.bottombtn:hover{
  background-color: #2d2f77 !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #E5E5E5;
}

.tabletitle{
  width:30%;
  font-size:20px;
  color:black;
  border-left-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-top-width: 0px;
}
.tabletitle_mb{
  width:30%;
  font-size:14px;
  color:black;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
}

.tabledesc{
  width:70%;
  font-size:20px;
  color:black;
  border-right-width: 2px;
  border-left-width: 0px;
  border-bottom-width: 2px;
  border-top-width: 0px;
}
.tabledesc_md{
  width:70%;
  font-size:14px;
  color:black;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-top-width: 0px;
}


table{
  border-radius: 20px;
  border-collapse: separate; 
  border-spacing:0;
  border: 0px solid #C9C1C1;
}

td {
  border: 1px solid #C9C1C1;
}


.text_normal_pc{
  font-size:18px !important;
  color:#817b7b !important;
}

.text_normal_mb{
  font-size:14px;
  color:#817b7b;
}


</style>