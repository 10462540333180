<template>
  <v-container fluid>

    <!-- 초청장 등록 페이지 -->

    <!-- 
    <v-row align="center" justify="center">
      <v-col cols="8" md="4">
        <v-img  src="../assets/logo.png" class="mt-5"></v-img>
      </v-col>
    </v-row>
    
    <v-row align="center" justify="center">
      <p style="color:black;display:inline;font-size:15px;font-weight:bold">전시, 박람회, 컨퍼런스 입장권 발급은&nbsp;</p><p style="color:#4e6ef7;display:inline;font-size:15px;font-weight:bold"><strong>페어패스</strong></p><p class="text-center" style="color:black;display:inline;font-size:15px;font-weight:bold">로 PASS!</p>
    </v-row>
    -->

    <v-row class="mt-5"></v-row>   
    <v-row justify="center">
        <v-img
            :aspect-ratio="16/9"
            :src="info.IMAGE"
            max-width="800"
            >
        </v-img>
    </v-row>   
    <template v-if="info.NAME != null && info.NAME.length > 0">
      <v-row class="pt-1 pb-1 mt-md-1" style="background:#4e6ef7;" align="center" justify="center">
          <v-col cols="12" align="center">
              <label style="font-size:18px;color:#ffffff;font-weight:bold">{{info.NAME}}</label>
          </v-col>
      </v-row>
      <v-row style="background:#4e6ef7;" align="center" justify="center">
          <label style="font-size:15px;color:#ffffff">{{info.EVENT_DATE}}</label>
      </v-row>
      <v-row style="background:#4e6ef7;" align="center" justify="center">
          <label style="font-size:15px;color:#ffffff">{{info.ADDR2}}</label>
      </v-row>
      <v-row style="background:#4e6ef7;" align="center" justify="center">        
          <label style="font-size:15px;color:#ffffff">{{info.OPTION_NAME}}</label>
      </v-row>
    </template>

    <v-row style="background:#4e6ef7;" align="center" justify="center">        
        <v-col cols="8">
          <v-text-field filled rounded dense v-model="NAME"
            small :placeholder="$t('comdialog_label1')" hide-details background-color="white"
            />
        </v-col>
    </v-row>
    <v-row style="background:#4e6ef7;" align="center" justify="center">        
        <v-col cols="8">
          <v-text-field filled rounded dense v-model="TEL" type="number"
            small :placeholder="$t('checkinvit_label11')" hide-details background-color="white"
            />
        </v-col>
    </v-row>

    <v-row style="background:#4e6ef7" align="center" justify="center">
      <v-col cols="12" md="12" sm="12" xs="12" align="center">
        <v-btn width="50%" large rounded color="white" @click="checkinvit">{{$t('checkinvit_label1')}}</v-btn>
      </v-col>
    </v-row>   

    <v-row style="background:#4e6ef7;height:500px" align="center" justify="center">
    </v-row>   

</v-container>
</template>

<script>

export default {

  data: () => ({
        INVITCODE:"",
        info:{
            NAME:"",
            USESTATE:0,
        },
        NAME:"",
        TEL:"",
        
        lan_code:0, //한국어

        checkdata:{},

  }),

  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    //console.log("get code " + JSON.stringify(this.$route.query.code));
    this.INVITCODE = this.$route.query.code;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    this.loadpage();
  },

  methods: {

    checkinvit(){

      if(this.NAME.length < 1){
        this.$alert(this.$t('checkinvit_label3'));
        return;
      }
      const numberVal = /[^0-9]/g;
      if(this.TEL.length < 1){
        this.$alert(this.$t('checkinvit_label4'));
        return;
      }
      if(this.TEL.length > 20){
        this.$alert(this.$t('checkinvit_label5'));
        return;
      }
      if(numberVal.test(this.TEL)){
        this.$alert(this.$t('checkinvit_label6'));
        return;
      }

      this.$http.post(this.$host+'/ime/CheckInvitNameTel',{
            INVITCODE:this.INVITCODE
          , ADMIN_IDX:1
          , NAME:this.NAME
          , TEL:this.TEL
          , LAN_CODE:this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            //이동
            this.checkdata = result.data.resultData[0];
            //console.log("checkdata : " + JSON.stringify(this.checkdata));
            if(this.checkdata.PRINT_QR == null || this.checkdata.PRINT_NUMBER == null || this.checkdata.EVENT_NAME == null || this.checkdata.TEL == null){
              this.$alert(this.$t('checkinvit_label7'));
              return;
            }

            if(this.checkdata.EVENT_DATE_VAL == 0){
              this.$alert(this.$t('checkinvit_label8'));
              return;
            }

            this.$router.push({path:"QrTicket", query:{printqr:this.checkdata.PRINT_QR, printnumber:this.checkdata.PRINT_NUMBER, tel:this.checkdata.TEL, eventname:this.checkdata.EVENT_NAME, lan_code:this.lan_code}});

          }else{
            this.$alert(result.data.resultMsg);
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });            


      //this.$router.replace({name:"agree", params:{code:this.INVITCODE, name:this.info.NAME}});

    },

    loadpage(){
      //초청장 정보 가져오기
        this.$http.post(this.$host+'/ime/InvitCodeInfo',{
              INVITCODE:this.INVITCODE
            , ADMIN_IDX:1
            , LAN_CODE:this.$i18n.locale
        })
        .then((result)=>{

            if(result.data.resultCode == 0){
                this.info = result.data.resultData[0];
                if(this.info.USESTATE == 0){
                  //미사용
                  this.$alert(this.$t('checkinvit_label9'));
                  return;
                }

                if(this.info.EVENT_DATE_VAL == 0){
                  //행사기간 지남.
                  this.$alert(this.$t('checkinvit_label10'));
                  return;
                }

            }else if(result.data.resultCode == 8){
              this.$alert(this.$t('invitresult_label5'));
            }else if(result.data.resultCode == 9){
              this.$alert(this.$t('invitresult_label6'));
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert(this.$t('common_msg1'));
            }else{
                this.$alert(error.message);
            }
        });      

    },

  },

}
</script>

<style scoped>


</style>