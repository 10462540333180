<!-- 개인 체류시간 확인-->
<template>
  <v-container fluid>

  <v-row justify="center">
    <v-col cols="12" md="7" align="center" class="pa-0" >
            <v-img
                :aspect-ratio="16/9"
                :src="EVENT_IMAGE"
                style="border-radius: 0px;"
                class="ma-0 ma-md-2 pa-0"
                >
            </v-img>
    </v-col>
  </v-row>

  <v-row  align="center" justify="center" class="mt-3">        
    <v-col cols="12" align="center">
      <label style="sans-serif;font-size:16px;color:#000000">{{lan_code == 0 ? NAME+'님의 점수현황':'The time that '+NAME+' stayed.' }}</label>
    </v-col>
  </v-row>

  <v-row  align="center" justify="center" class="mt-0">        
    <v-col cols="12" align="center">
      <template v-if="lan_code == 0">
        <div class="roundbox">{{ '총 '+TOTAL_POINT + ' 점 (일반 '+TOTAL_NORMAL+', 필수 '+TOTAL_ESSEN+')' }}</div>
      </template>
      <template v-else>
        <div class="roundbox">{{ 'Total '+TOTAL_POINT + ' Point (General '+TOTAL_NORMAL+', Mandatory '+TOTAL_ESSEN+')' }}</div>
      </template>
    </v-col>
  </v-row>

  <v-row align="center" justify="center">
    <v-col cols="12"  md="7" align="center">
      <!-- 테이블 -->
      <table>
        <template v-for="item in itemList">
          <tr :key="item.IN_DATE1" style="margin-bottom:10px">
            <td class="text-center" style="width:40%;background:#1f4279;color:#ffffff" rowspan="3">{{ lan_code == 0 ? item.IN_DATE1 : item.IN_DATE2 }}<br>{{ item.PLACENAME }}</td>
            <td class="text-center toptd" style="width:15%">{{$t('showabg_label1')}}</td>
            <td class="text-center" style="width:15%">{{ item.IN_TIME }}</td>
            <template v-if="lan_code == 0">
              <td class="text-center" style="width:15%" >체류<br>(분)</td>
            </template>
            <template v-else>
              <td class="text-center" style="width:15%" >Stay<br>(minutes)</td>
            </template>
            <td class="text-center" style="width:15%" >{{ item.SHOWMIN }}</td>
          </tr>
          <tr :key="item.IN_DATE1+1">
            <td class="text-center" rowspan="2">{{$t('showabg_label2')}}</td>
            <td class="text-center" rowspan="2">{{ item.OUT_TIME }}</td>
            <td class="text-center">{{$t('showabg_label3')}}</td>
            <td class="text-center multiline">{{ item.DAY_POINT-item.ESSEN_POINT }}</td>
          </tr>
          <tr :key="item.IN_DATE1+2">
            <td class="text-center">{{$t('showabg_label4')}}</td>
            <td class="text-center multiline">{{ item.ESSEN_POINT}}</td>
          </tr>
          <tr :key="item.IN_DATE1+3">
            <td style="height:20px" class="spacetd" colspan="5"></td>
          </tr>
        </template>
      </table>
    </v-col>
  </v-row>

  <!-- 
    <v-row  align="center" justify="center" class="mt-10">        
      <v-col cols="12" align="center">
        <label style="font-size:20px;color:#4e6ef7;font-weight:bold">{{EVENT_NAME}}</label>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="pa-0 ma-0 mt-5">        
      <v-col cols="11" style="text-align:center;">
        <label style="font-size:15px;color:#808080">{{ lan_code == 0 ? '입장권 발급 키오스크에 휴대폰번호를 입력하거나 QR코드를 이용해서 입장권을 출력하세요' : 'Enter your mobile phone number in the ticket issuance kiosk or print your ticket using the QR code'}}</label>
      </v-col>        
    </v-row>
    <v-row v-if="TEL.length > 0" class="pa-0 ma-0" align="center" justify="center">        
      <v-col cols="12" align="center">
        <label style="font-size:18px;color:#101010;font-weight:bold">{{ lan_code == 0 ? '휴대폰번호 : ' : 'Mobile : ' }}{{TEL}}</label>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0" align="center" justify="center">        
      <v-col cols="12" align="center">
        <label style="font-size:20px;color:#101010">{{ lan_code == 0 ? 'QR코드를 읽혀주세요' : 'Please read the QR code' }}</label>
      </v-col>
    </v-row>
  -->


  </v-container>
</template>

<script>
import QRCode from 'qrcode'
export default {

  data: () => ({

    EVENT_NAME:"",
    OPTION_NAME:"",
    EVENT_IMAGE:"",
    NAME:"",
    
    TOKEN:"",
    lan_code:0, //한국어

    TOTAL_POINT:0,
    TOTAL_NORMAL:0,
    TOTAL_ESSEN:0,

    itemList: [],
  }),

  mounted(){  
    window.scrollTo(0,0);
    this.TOKEN      = this.$route.query.token;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    this.checkinvit();
  },

  methods: {
    
    back(){
      this.$router.back();
    },

    checkinvit(){
      
      this.itemList.splice(0, this.itemList.length);   //데이터 초기화
      this.$http.post(this.$host+'/ime/CheckTime',{
          TOKEN:this.TOKEN
      })
      .then((result)=>{
        if(result.data.resultCode == 0){

          //출력정보
          this.checkdata = result.data.resultData[0];
          this.lan_code = Number(this.checkdata.USE_LAN);

          const list = result.data.timeData;
          list.forEach(element => {

              this.TOTAL_NORMAL += Number(element.DAY_POINT)-Number(element.ESSEN_POINT);
              this.TOTAL_ESSEN  += Number(element.ESSEN_POINT);
              this.TOTAL_POINT  += Number(element.DAY_POINT);
              this.itemList.push(element); 

          });

          if(this.checkdata.PRINT_QR == null  || this.checkdata.EVENT_NAME == null || this.checkdata.TEL == null){
            this.$alert(this.$t('showabg_label5'));
            return;
          }
          
          if(this.checkdata.COST_TYPE == 1){
            if(this.checkdata.STATE == 1){
              this.$alert(this.$t('showabg_label6')).then(() => {
                this.$router.back();
              });
              return;
            }
          }

          if(this.checkdata.STATE > 4){
            //5,6은 환불 취소
            this.$alert(this.$t('showabg_label7'));
            return;
          }

          this.EVENT_NAME   = this.checkdata.EVENT_NAME;
          this.EVENT_IMAGE  = this.checkdata.IMAGE;
          this.NAME         = this.checkdata.INFO1;
          this.OPTION_NAME  = this.checkdata.OPTION_NAME;

          if(this.checkdata.EVENT_DATE_VAL == 0){
            //행사기간 지남
            this.$alert(this.$t('showabg_label8'));
          }else{
            var canvas = document.getElementById('canvas');
            QRCode.toCanvas(canvas, String(this.PRINT_QR), {width:250},function (error) {
            if (error) console.error(error)
              console.log('success!');
            })
          }
        }else{
          this.$alert(result.data.resultMsg);
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });            

    },

  },

}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

  table{
    width: 100%;
    border-radius: 20px;
    border-collapse: collapse; 
    border-spacing:0;
    border: 0px solid #C9C1C1;
  }

  .spacetd {
    border: 0px solid #c7c7c7;
  }
  
  .toptd{
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-top-width: 1px;
  }

  td {
      border: 1px solid #c7c7c7;
      padding: 5px;
      font-size:14px;
  }
  th {
      border: 1px solid #c7c7c7;
  }
  .multiline {
    white-space: pre-line;
  }

  .roundbox{
    border: 1px solid #7f7f7f;
    border-radius: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: initial;
  }

</style>