<template>
  <v-container fluid>

    <v-row  align="center" justify="center" class="mt-10">        
      <v-col cols="12" align="center">
        <label style="font-size:20px;color:#4e6ef7;font-weight:bold">{{EVENT_NAME}}</label>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="pa-0 ma-0 mt-5">        
      <v-col cols="11" style="text-align:center;">
        <label style="font-size:15px;color:#808080">{{$t('qrticket_label1')}}</label>
      </v-col>        
    </v-row>

    <v-row v-if="TEL.length > 0" class="pa-0 ma-0" align="center" justify="center">        
      <v-col cols="12" align="center">
        <label style="font-size:18px;color:#101010;font-weight:bold">{{$t('qrticket_label2')}}{{TEL}}</label>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0" align="center" justify="center">        
      <v-col cols="12" align="center">
        <label style="font-size:20px;color:#101010">{{$t('qrticket_label3')}}</label>
      </v-col>
    </v-row>

    <v-row dense no-gutters class="pa-0 ma-0" style="text-align:center" justify="center">
      <v-col cols="10" class="qr_round">
        <canvas id="canvas"></canvas>
        <label class="pw_round" style="display:block">{{$t('qrticket_label4')}}{{PRINT_NUMBER}}</label>
      </v-col>
    </v-row>   

    <v-row style="height:300px" align="center" justify="center">
    </v-row>   

  </v-container>
</template>

<script>
import QRCode from 'qrcode'
export default {

  data: () => ({
    PRINT_QR:"",
    PRINT_NUMBER:"",
    TEL:"",
    EVENT_NAME:"",
    lan_code:0, //한국어
  }),

  mounted(){  

    window.scrollTo(0,0);

    //path를 통해서 들어올 경우 ex) /경로/값
    //console.log("get code222 " + JSON.stringify(this.$route.query));
    this.PRINT_QR     = this.$route.query.printqr;
    this.PRINT_NUMBER = this.$route.query.printnumber;
    this.TEL          = this.$route.query.tel;
    this.EVENT_NAME   = this.$route.query.eventname;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }

    var canvas = document.getElementById('canvas');
    QRCode.toCanvas(canvas, this.PRINT_QR, {width:250},function (error) {
    if (error) console.error(error)
      console.log('success!');
    })

  },

  methods: {


  },

}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
  .pw_round{
    background-color: #4e6ef7 !important;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 50px;
    color:white;
    text-align: center;
    padding-top: 10px;
    font-size:14px;
    box-shadow:0px 0px 2px #c0c0c0;
  }
  .qr_round{
    background-color: white !important;
    border-radius: 20px !important; 
    border-width:1px 1px 1px 1px !important;
    box-shadow:0px 0px 2px #c0c0c0;
  }

</style>